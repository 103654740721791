import {useEffect, useRef, useState, forwardRef, useImperativeHandle} from 'react';
import './RightContextMenu.scss'
const RightContextMenu = (props, ref) => {
  const [pageX, setPageX] = useState(0)
  const [pageY, setPageY] = useState(0)
  const [showMenu, setShowMenu] = useState(false)
  const dropdownElement = useRef(null)

  useEffect(() => {
    focusDropdown();
  }, [showMenu]);

  useImperativeHandle(ref, () => ({
    handleRightClick
  }))
  const focusDropdown = () => {
    if (dropdownElement.current) {
      dropdownElement.current?.focus();
    }
  };
  const handleRightClick = (e) => {
    setPageX(e.pageX);
    setPageY(e.pageY);
    setShowMenu(true);
  };
  const handleMenuClick = (type) => {
    if(type === 'up'){
      if (props.layerIndex === 0) {
        return
      }
    } else if(type === 'down'){
      if (props.layerIndex === props.layerList.length - 1) {
        return
      }
    }
    props.handleRightMenu(type)
    setShowMenu(false)
  };


  return (
      <div
          tabIndex={-1}
          className="right-menu-list"
          style={{
            display: showMenu ? 'block' : 'none',
            position: 'fixed',
            left: pageX,
            top: pageY,
          }}
          ref={dropdownElement}
          onBlur={(e) => {
            e.stopPropagation();
            setShowMenu(false);
          }}
      >
        <div className={`menu-item`} onClick={ () => {handleMenuClick('copy')}}>复制</div>
        <div className={`menu-item`} onClick={ () => {handleMenuClick('delete')}}>删除</div>
        <div className={`menu-item ${ props.layerIndex === 0 ? 'disabled':''}`} onClick={ () => {handleMenuClick('up')}}>上移一层</div>
        <div className={`menu-item ${ props.layerList.length - 1 === props.layerIndex ? 'disabled':''}`} onClick={ () => {handleMenuClick('down')}}>下移一层</div>
        <div className={`menu-item ${ props.layerIndex === 0 ? 'disabled':''}`} onClick={ () => {handleMenuClick('top')}}>置于顶层</div>
        <div className={`menu-item ${ props.layerList.length - 1 === props.layerIndex ? 'disabled':''}`} onClick={ () => {handleMenuClick('bottom')}}>置于底层</div>
      </div>
  )
}
export default forwardRef(RightContextMenu)
