import React, {useEffect, useState} from 'react';
import '../../App.css'
import Tail from '../../components/tail/tail';
import NavigateBar from '../../components/navigateBar/navigateBar';
import Search from 'antd/lib/input/Search';
import styles from './community.module.css';
import logo from '../../assets/icon/logo_2.png'
import {Tabs} from 'antd';
import PicCard from '../../components/picCard/picCard';
import UserInfo from '../../components/userInfo/userInfo';
import {getHomeModelList, getHomeModelType, getHomeType} from "../../api";

const Community = (props) => {
  const [searchValue, setSearchValue] = useState('')
  const [type, setType] = useState('')
  const [tabList, setTabList] = useState([])
  const [picList, setPicList] = useState([])
  const onSearch = () => {
    let params = {
      type: type || '',
      name: searchValue
    }
    getHomeModelList(params).then(res => {
      setPicList(res?res.data.data:[])
    })
  }
  const onChange = (e) => {
    setSearchValue(e.target.value)
    onSearch()
  }
  const onChangeType = (e) => {
    setType(e)
  }
  useEffect(() => {
    onSearch();
  }, [type]);
  useEffect(() => {
    getHomeType().then(res => {
      setTabList([
        {
          code: '',
          name: '全部'
        },
        ...(res ? res.data.data : [])
      ])
    })
  }, []);
  return (
    <div>
      <NavigateBar title='社区' state='loggedIn'></NavigateBar>
      <div className={styles.postContainer}>
        <img alt='' src={logo} style={{width: 72}}></img>
        <Search
          value={searchValue}
          placeholder="在所有案例库中搜索"
          allowClear
          enterButton="搜索"
          size="large"
          onChange={onChange}
          onSearch={onSearch}
          style={{width: 464}}
        />
        <Tabs
          activeKey={type}
          centered
          onChange={onChangeType}
          items={tabList.map((type, i) => {
            return {
              label: type.name,
              key: type.code,
            };
          })}
          style={{marginBottom: -16, paddingTop: 20,}}
        />
      </div>

      <div className={styles.cardContainer}>
        {picList.map((item, index) =>
          <div key={index}>
            <PicCard src={item.picture} name={item.modelName} modelId={item.modelId}></PicCard>
          </div>
        )}
      </div>
      <Tail></Tail>
    </div>
  );
}

export default Community;
