/**
 * 金属度贴图层级混合方法
 * 从底图开始，把效果处理工艺的黑白图图根据层级往上叠加
 * @param basicImagesList<basicData> 效果处理工艺图层
 * basicData.effectData 效果处理工艺粗糙度和金属度
 * basicData.imageData 效果处理的目标区域黑白图
 * @param metalnessBg 金属度底图
 * @param width 画布宽高
 * @param height
 */
export async function getMetalnessPic(width,height,basicImagesList, metalnessBg){
  return new Promise(async (resolve, reject) => {

    // 画布
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    // 第一步，叠加底图
    if(metalnessBg){
      let metalnessImage = new Image()
      metalnessImage.src = metalnessBg
      // 保证图片加载完成
      await new Promise(resolve => {
        metalnessImage.onload = function(){
          resolve(true)
        }
      })
      ctx.drawImage(metalnessImage, 0, 0, width, height)
    } else {
      // 没有则填充黑色
      ctx.fillStyle = 'black';
      // 填充整个 Canvas
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    // 图层金属度贴图
    let layerMetalnessList = []
    let promiseArr = basicImagesList.map(item => {
      return getLayerMetalnessResult(width, height, item.imageData, item.effectData['糙度'])
    })

    layerMetalnessList = await Promise.all(promiseArr)
    let length = width * height * 4
    let baseData = ctx.getImageData(0 ,0 ,width,height)
    for (let i = 0; i < length; i+=4){
      let r = baseData.data[i],
          g = baseData.data[i+1],
          b = baseData.data[i+2],
          a = baseData.data[i+3]
      let k = 0
      let newR = r, newG = g, newB = b
      while (k<layerMetalnessList.length  && (newR === r && newG === g && newB === b)){
        let r2 = layerMetalnessList[k].data[i],
            g2 = layerMetalnessList[k].data[i+1],
            b2 = layerMetalnessList[k].data[i+2],
            a2 = layerMetalnessList[k].data[i+3]
        if(!(r2 === 0 && g2 === 0 && b2 === 0)){
          // 不为黑色，则为效果处理工艺覆盖区域
          newR = r2
          newG = g2
          newB = b2
        }
        k++
      }
      baseData.data[i] = newR
      baseData.data[i+1] = newG
      baseData.data[i+2] = newB
    }

    ctx.putImageData(baseData, 0, 0)
    let image = canvas.toDataURL()
    resolve(image)
  })
}

/**
 * 获得图层金属度贴图混合后的内容
 * @param imageData 黑白贴图
 * @param metalnessImage 该效果处理工艺金属度贴图
 * @returns {Promise<unknown>}
 */
function getLayerMetalnessResult(width, height, imageData, metalnessImage){
  return new Promise(resolve => {

    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx.putImageData(imageData, 0, 0)
    if(metalnessImage){
      let image = new Image()
      image.src = metalnessImage;
      image.onload = function(){
        // 创建临时 Canvas 用于生成图像遮罩
        let tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        let tempCtx = tempCanvas.getContext('2d');

        // 将纹理图像绘制到临时 Canvas
        tempCtx.drawImage(image, 0, 0);

        // 设置混合模式为 source-in
        ctx.globalCompositeOperation = 'source-in';

        // 使用临时 Canvas 作为图像遮罩，绘制到原始 Canvas
        ctx.drawImage(tempCanvas, 0, 0);

        // 清除混合模式
        ctx.globalCompositeOperation = 'source-over';
        let imageData = ctx.getImageData(0, 0, width, height)
        resolve(imageData)
        // 清除临时 Canvas
        tempCanvas = null;
      }
    } else {
      // 无金属度贴图说明是烫金，直接返回黑白图
      let imageData = ctx.getImageData(0, 0, width, height)
      resolve(imageData)
    }
  })
}
/**
 * 粗糙度贴图层级混合方法
 * 从底图开始，把效果处理工艺的黑白图图根据层级往上叠加
 * @param basicImagesList 效果处理工艺图层
 * basicData.effectData 效果处理工艺粗糙度和金属度
 * basicData.imageData 效果处理的目标区域黑白图
 * @param roughnessBg 粗糙度底图
 * @param width 画布宽高
 * @param height
 */
export async function getRoughnessPic(width,height,basicImagesList, roughnessBg){
  return new Promise(async (resolve, reject) => {

    // 画布
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    // 第一步，叠加底图
    if(roughnessBg){
      let roughnessImage = new Image()
      roughnessImage.src = roughnessBg
      // 保证图片加载完成
      await new Promise(resolve => {
        roughnessImage.onload = function(){
          resolve(true)
        }
      })
      ctx.drawImage(roughnessImage, 0, 0, width, height)
    } else {
      // 没有则填充黑色
      ctx.fillStyle = 'black';
      // 填充整个 Canvas
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    // 图层粗糙度贴图
    let layerMetalnessList = []
    let promiseArr = basicImagesList.map(item => {
      return getLayerRoughnessResult(width, height, item.imageData, item.effectData['糙度'])
    })

    layerMetalnessList = await Promise.all(promiseArr)
    let length = width * height * 4
    let baseData = ctx.getImageData(0 ,0 ,width,height)
    for (let i = 0; i < length; i+=4){
      let r = baseData.data[i],
          g = baseData.data[i+1],
          b = baseData.data[i+2],
          a = baseData.data[i+3]
      let k = 0
      let newR = r, newG = g, newB = b
      while (k<layerMetalnessList.length  && (newR === r && newG === g && newB === b)){
        let r2 = layerMetalnessList[k].data[i],
            g2 = layerMetalnessList[k].data[i+1],
            b2 = layerMetalnessList[k].data[i+2],
            a2 = layerMetalnessList[k].data[i+3]
        if(!(r2 === 0 && g2 === 0 && b2 === 0)){
          // 不为黑色，则为效果处理工艺覆盖区域
          newR = r2
          newG = g2
          newB = b2
        }
        k++
      }
      baseData.data[i] = newR
      baseData.data[i+1] = newG
      baseData.data[i+2] = newB
    }

    ctx.putImageData(baseData, 0, 0)
    let image = canvas.toDataURL()
    resolve(image)
  })
}

/**
 * 获得图层金属度贴图混合后的内容
 * @param imageData 黑白贴图
 * @param roughnessImage 该效果处理工艺粗糙度贴图
 * @returns {Promise<unknown>}
 */
function getLayerRoughnessResult(width, height, imageData, roughnessImage){
  return new Promise(resolve => {

    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx.putImageData(imageData, 0, 0)
    if(roughnessImage){
      let image = new Image()
      image.src = roughnessImage;
      image.onload = function(){
        // 创建临时 Canvas 用于生成图像遮罩
        let tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        let tempCtx = tempCanvas.getContext('2d');

        // 将纹理图像绘制到临时 Canvas
        tempCtx.drawImage(image, 0, 0);

        // 设置混合模式为 source-in
        ctx.globalCompositeOperation = 'source-in';

        // 使用临时 Canvas 作为图像遮罩，绘制到原始 Canvas
        ctx.drawImage(tempCanvas, 0, 0);

        // 清除混合模式
        ctx.globalCompositeOperation = 'source-over';
        let imageData = ctx.getImageData(0, 0, width, height)

        resolve(imageData)
        // 清除临时 Canvas
        tempCanvas = null;
      }
    } else {
      // 烫金粗糙度为黑色
      // 重置为黑色
      ctx.fillStyle = 'black';
      // 填充整个 Canvas
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      let imageData = ctx.getImageData(0, 0, width, height)
      resolve(imageData)
    }
  })
}
