import React from 'react';
import styles from './picCard.module.css'
import '../../App.css'
import { Button } from "antd";
import PubSub from "pubsub-js";
import { useNavigate } from 'react-router-dom';


const PicCard = ({ src, name, modelId }) => {
  const navigate = useNavigate(); // 使用 useNavigate 钩子

  const gotoEdit = () => {
    let token = sessionStorage.getItem('__config_token')
    if (!token) {
      PubSub.publish('openLoginModal')
      return
    }
    navigate(`/design?modelId=${modelId}`); // 使用 navigate 进行导航
  };

  return (
    <div className={styles.item}>
      <img src={src} alt='' className={styles.pic} />
      <div className={styles.infoContainer}>
        <div className={styles.title}>{name}</div>
        <div className={styles.postInfoContainer}>
          <Button onClick={gotoEdit}>开始设计</Button>
        </div>
      </div>
    </div>
  );
};

export default PicCard;
