import React from 'react';
import './userInfo.css'
import '../../App.css'
import avatar from '../../assets/user/avatar.png'

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    let userinfo = sessionStorage.getItem('__user_info')
    this.state = {
      userInfo: userinfo ? JSON.parse(userinfo) : {}
    }
  }
    render() {
        return (
            <div className='Container'>
                <img className='avatar' alt='' src={avatar}></img>
                <div className={this.props.textColor==='black'?'name black':'name white'}>{this.state.userInfo.nickname || this.state.userInfo.username}</div>
            </div>
        );
    }
}

export default UserInfo;
