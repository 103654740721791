import {useEffect, useState} from "react";

export default function CameraMask () {
  const [borderStyle, setBorderStyle] = useState({})
  useEffect(() => {
    getBorderSize()
    window.addEventListener('resize', getBorderSize);
    return () => window.removeEventListener('resize', getBorderSize);
  }, []); // 空数组[]意味着仅在组件挂载时添加一次监听器

  function getBorderSize(){
    let windowWidth = document.body.clientWidth
    let windowHeight = document.body.clientHeight
    const leftPanel = 208
    const rightPanel = 208
    const headerTop = 60
    const bottomArea = 80
    const maskPadding = 20 // 留白空隙距离

    let aWidth = windowWidth - leftPanel - rightPanel - maskPadding * 2
    let aHeight = windowHeight - headerTop - bottomArea - maskPadding * 2
    let lengthOfSide = aWidth > aHeight ? aHeight : aWidth
    let left = (windowWidth - lengthOfSide) / 2
    let right =  (windowWidth - lengthOfSide) / 2
    let gapUD = (windowHeight - headerTop - bottomArea - lengthOfSide) / 2
    let top =  headerTop + gapUD
    let bottom = bottomArea + gapUD

    setBorderStyle({
      borderBottom: `${bottom}px solid rgba(0,0,0,0.6)`,
      borderTop: `${top}px solid rgba(0,0,0,0.6)`,
      borderLeft: `${left}px solid rgba(0,0,0,0.6)`,
      borderRight: `${right}px solid rgba(0,0,0,0.6)`,
    })
  }
    return(
    <div className="camera-mask-wrapper" style={borderStyle}></div>
  )
}
