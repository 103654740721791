// 公共方法
/**
 * 像素转毫米
 * @param pxLength
 * @returns {number}
 */
export function pxToMm(pxLength){
  // 创建一个1mm宽的元素插入到页面，然后坐等出结果
  if(!document.getElementById("mm")){
    let div = document.createElement("div");
    div.id = "mm";
    div.style.width = "1mm";
    div.style.position = "fixed";
    document.querySelector("body").appendChild(div);
  }
  // 一毫米的宽度px
  let mm_1_px = document.getElementById("mm").getBoundingClientRect().width;
  return Number((pxLength/mm_1_px).toFixed(0));
}

/**
 * 毫米转像素
 * @returns {number}
 * @param mmLength
 * @param precision
 */
export function mmToPx(mmLength, precision = 9){
  // 创建一个1mm宽的元素插入到页面，然后坐等出结果
  if(!document.getElementById("mm")){
    let div = document.createElement("div");
    div.id = "mm";
    div.style.width = "1mm";
    div.style.position = "fixed";
    document.querySelector("body").appendChild(div);
  }
  // 一毫米的宽度px
  let mm_1_px = document.getElementById("mm").getBoundingClientRect().width;
  return Number((mmLength*mm_1_px).toFixed(precision));
}

/**
 * 获取图片宽高
 * @param url
 */
export function getImageWH(url){
  return new Promise((resolve) => {
    // 创建对象
    let img = new Image();
    // 改变图片的src
    img.src = url;
    // 定时执行获取宽高
    let check = function(){
      // 只要任何一方大于0, 表示已经服务器已经返回宽高
      if(img.width>0 || img.height>0){
        clearInterval(set);
        resolve({
          width_mm: pxToMm(img.width),
          height_mm: pxToMm(img.height),
          width: img.width,
          height: img.height,
        })
      }
    };
    let set = setInterval(check,40);
  })
}
export const getHashQuery = (key, func) => {
  let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)');
  let query = window.location.hash.replace(/.*\?/, '');
  let r = query.substr(0).match(reg);
  if (r !== null) {
    if (!func || func === 'decodeURI' || typeof func === 'undefined') {
      return decodeURI(r[2]);
    } else if (func === 'unescape') {
      return unescape(r[2]);
    } else if (func === 'decodeURIComponent') {
      return decodeURIComponent(r[2]);
    }
  } else {
    return null;
  }
};
