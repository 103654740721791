import React from "react";
import './EditableBox.css'
import {RedoOutlined} from '@ant-design/icons';
import {mmToPx, pxToMm} from '../../utils/common';
import SvgLayer from "../layers/SvgLayer";
import {ImgModel} from "../displayContainer/canvas2D";
import {getImage2Blob} from "../../api";
import html2canvas from "html2canvas";
import PubSub from "pubsub-js";
let timeout = null

class TextMixTech extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maskUrl: '',
      effectProcess: props.layerInfo.tech.effectProcess
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.effectProcess !== nextProps.layerInfo.tech.effectProcess) {
      if(this.props.layerInfo.tech.effectData&&this.props.layerInfo.tech.effectData['合并结果']){
        this.setState({
          maskUrl: '',
          effectProcess: nextProps.layerInfo.tech.effectProcess
        }, () => {
          this.renderMask()
        })
      } else {
        this.setState({
          maskUrl: '',
          effectProcess: nextProps.layerInfo.tech.effectProcess
        })
      }
    }
  }
  componentDidMount() {
    if(this.props.layerInfo.tech.effectData&&this.props.layerInfo.tech.effectData['合并结果']){
      this.renderMask()
    } else {
      this.setState({
        maskUrl: ''
      })
    }
  }


  renderMask = () => {
    let dom = document.getElementById(this.props.layerId)
    html2canvas(dom, {
      ignoreElements:(element) => {
        return element.className === 'text-mix-tech'
      }
    }).then(canvas => {
      let ctx = canvas.getContext('2d')

      let imageData  = ctx.getImageData(0,0, canvas.width, canvas.height)
      let length = canvas.width * canvas.height
      for (let i = 0; i < length * 4; i+=4) {
        if(imageData.data[1] === 255 && imageData.data[i+1]===255 && imageData.data[i+2] === 255){
          imageData.data[i+3] = 0 // 设为透明
        }
      }
      ctx.putImageData(imageData, 0, 0)
      getImage2Blob(this.props.layerInfo.tech.effectData['合并结果']).then(coverage => {
        let imageCover = new Image();
        imageCover.src = coverage;
        imageCover.onload = () => {
          // 设置混合模式为 source-in
          ctx.globalCompositeOperation = 'source-in';

          // 绘制第二张图像
          ctx.drawImage(imageCover, 0, 0);

          // 清除混合模式
          ctx.globalCompositeOperation = 'source-over';
          let result = canvas.toDataURL()
          this.setState({
            maskUrl: result
          }, () => {
            PubSub.publish('update3D')
          })
        }
      })
    })

  }
  render() {
    return (
        (this.props.layerInfo.tech.effectProcess && this.state.maskUrl)
            ? <img className="text-mix-tech" src={this.state.maskUrl} alt=""/> : ''
    )
  }
}

class LayerBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rotating: false,
      initialX: mmToPx(props.layerInfo.basic.x, 1),
      initialY: mmToPx(props.layerInfo.basic.y, 1),
      startX: null,
      startY: null,
      oldX: null,
      oldY: null,
      x: mmToPx(props.layerInfo.basic.x,1),
      y: mmToPx(props.layerInfo.basic.y, 1),
      moving: false
    }
  }

  handleOnBlur = (e) => {
    // this.props.handleUpdate();
    this.props.onChange(['modifyText', this.props.index, e.target.innerText]);
  }
  handleClick(e, data) {
    e.stopPropagation();
    if(e.button === 0){
      this.props.onChange(data);
    }
  }
  handleRotate(e, rotating){
    if(this.state.rotating){
      e.stopPropagation();
    }
    if (e.button === 0) {
      this.setState({
        rotating: rotating
      })
      // this.props.onChange(['changeSelectedLayer', this.props.layerInfo.id])
      if(rotating){
        let that = this
        document.body.addEventListener('mousemove', function(e){
          that.onRotating(e)
        })
        document.body.addEventListener('mouseup', function(e){
          that.handleRotate(e,false)
        })
      } else {
        let that = this
        document.body.removeEventListener('mousemove', function(e){
          that.onRotating(e)
        })
        document.body.removeEventListener('mouseup', function(e){
          that.handleRotate(e,false)
        })
      }
    }
  }

  /**
   * 旋转
   * @param e
   */
  onRotating(e){
    e.stopPropagation();
    if(this.state.rotating){
      let dom = document.getElementById(`rotateHandle_${this.props.layerInfo.id}`)
      let domStyle = dom.getBoundingClientRect()
      const x = domStyle.width / 2 + domStyle.left;
      const y = domStyle.height / 2 + domStyle.top;
      const x1 = e.clientX;
      const y1 = e.clientY;
      let deg = (Math.atan2((y1 - y), (x1 - x))) * (180 / Math.PI) - 90;
      deg = deg < -180 ? deg + 360 : deg
      deg = deg > 180 ? deg - 360 : deg
      dom.style.transform = `rotate(${deg}deg)`;
      timeout && clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.props.onChange(['changeBasic_rotate', this.props.layerInfo.type, deg])
      }, 500)
    }
  }

  /**
   * 开始移动
   * @param e
   */
  startDraggable(e){
    if(e.button === 0){
      this.props.onChange(['changeSelectedLayer', this.props.index])
      this.setState({
        startX: e.pageX,
        startY: e.pageY,
        oldX: mmToPx(this.props.layerInfo.basic.x, 1),
        oldY: mmToPx(this.props.layerInfo.basic.y, 1),
        moving: true
      }, () => {
        let moving = (e) => {
          this.moveDraggable(e)
        }
        let stopDraggable = (e) => {
          document.body.removeEventListener('mousemove', moving)
          document.body.removeEventListener('mouseup', stopDraggable)
          this.stopDraggable(e)
        }
        document.body.addEventListener('mousemove', moving)
        document.body.addEventListener('mouseup', stopDraggable)
      })
    }

  }
  moveDraggable(e){
    if(e.button === 0){
      if(this.state.moving){
        let moveX = (e.pageX - this.state.startX)/(this.props.scale/100)
        let moveY = (e.pageY - this.state.startY)/(this.props.scale/100)
        let newX, newY

        /**
         * 根据两点和角度计算第三点
         * @param x1
         * @param y1
         * @param x2
         * @param y2
         * @param angleInDegrees
         * @returns {{x: *, y: *}}
         */
        function calculateThirdPoint(x1, y1, x2, y2, angleInDegrees) {
          // 将角度转换为弧度
          let radio = angleInDegrees*Math.PI/180
          // 计算第三点的坐标
          let x3 = x2 * Math.cos(radio) - y2 * Math.sin(radio);
          let y3 = x2 * Math.sin(radio) + y2 * Math.cos(radio);

          return { x: x3, y: y3 };
        }


        if(this.props.canvasRotate  === 0){
          newX = this.state.oldX + moveX
          newY = this.state.oldY + moveY
        } else if(this.props.canvasRotate === 90){
          newX = this.state.oldX + moveY
          newY = this.state.oldY - moveX
        } else if(this.props.canvasRotate === 180){
          newX = this.state.oldX - moveX
          newY = this.state.oldY - moveY
        } else if(this.props.canvasRotate === 270){
          newX = this.state.oldX - moveY
          newY = this.state.oldY + moveX
        } else if(this.props.canvasRotate === 45){
          let {x, y} = calculateThirdPoint(0, 0, moveX, moveY, this.props.canvasRotate)
          newX = this.state.oldX + y
          newY = this.state.oldY - x
        } else if(this.props.canvasRotate === 135){
          let {x, y} = calculateThirdPoint(0, 0, moveX, moveY, this.props.canvasRotate)
          newX = this.state.oldX - y
          newY = this.state.oldY + x
        } else if(this.props.canvasRotate === 225){
          let {x, y} = calculateThirdPoint(0, 0, moveX, moveY, this.props.canvasRotate)
          newX = this.state.oldX + y
          newY = this.state.oldY - x
        } else if(this.props.canvasRotate === 315){
          let {x, y} = calculateThirdPoint(0, 0, moveX, moveY, this.props.canvasRotate)
          newX = this.state.oldX - y
          newY = this.state.oldY + x
        }
        this.setState({
          x: newX,
          y: newY
        }, () => {
          this.props.onChange(['changeBasic_x', this.props.layerInfo.type, pxToMm(newX)])
          this.props.onChange(['changeBasic_y', this.props.layerInfo.type, pxToMm(newY)])
        })

      }
    }
  }
  /**
   * 移动图层
   * @param e
   * @param source
   *
   */
  stopDraggable(e, source){
    if(e.button === 0){
      // 移动的时候更新，点击选择触发不更新
      this.setState({
        startX: null,
        startY: null,
        oldX: null,
        oldY: null,
        moving: false
      })

    }
  }

  onRightClick = (e) => {
    this.props.onRightMenu(e,this.props.index)
  }
  onClick = (e) => {
    if(e.button === 0){
      this.handleClick(e, ['changeSelectedLayer', this.props.index])
    }
  }

  render() {
    return (
      <div className={`layer-item-wrapper ${this.props.isSelected?'isSelected':''}`}
           data-node="layer-item"
           data-tech={this.props.layerInfo.tech.effectProcess}
           data-layer-id={this.props.layerInfo.id}
           style={{
             transform: `translate(${mmToPx(this.props.layerInfo.basic.x, 1)}px,${mmToPx(this.props.layerInfo.basic.y, 1)}px)`,
             zIndex: 1000 - this.props.index
           }}
           id={'layer_' + this.props.layerInfo.id}
           onClick={this.onClick}
           onContextMenu={this.onRightClick}>
        <div
            className="layer-item-content"
            id={`handle_${this.props.layerInfo.id}`}
            name="拖拽dom"
            onMouseDown={(e) => this.startDraggable(e)}
            // onMouseUp={(e) => this.stopDraggable(e)}
            // onMouseLeave={(e) => this.stopDraggable(e, 'leave')}
            // onMouseMove={(e) => this.moveDraggable(e)}
            // onMouseDown={this.props.onMouseDown}
            // onDoubleClick={this.handleOnDoubleClick}

        >
          <div id={`rotateHandle_${this.props.layerInfo.id}`}
               style={{ transform: 'rotate(' + this.props.layerInfo.basic.rotate + 'deg)' }}
               className="rotate-box"
               onMouseUp={(e) => this.handleRotate(e, false)}
          >
            {
              this.props.layerInfo.type === 'text' &&
                <div className={'edit-input editable'}>
                  <TextMixTech layerId={'layer_' + this.props.layerInfo.id} layerInfo={this.props.layerInfo}/>
                  <div
                      className={this.props.layerInfo.effectProcess ? `pattern-overlay effectProcess`:''}
                      contentEditable={this.props.layerInfo.isLocked?'false':'plaintext-only'}
                      suppressContentEditableWarning
                      onBlur={this.handleOnBlur}
                      style={{
                        opacity: this.props.layerInfo.color.alpha / 100,
                        fontSize: this.props.layerInfo.text.fontSize, zIndex: 100,
                        fontFamily: this.props.layerInfo.text.fontFamily,
                        color: this.props.layerInfo.color.color,
                        fontStyle: this.props.layerInfo.text.isItalic ? 'italic' : '',
                        textDecoration: this.props.layerInfo.text.isUnderlined ? 'underline' : '',
                        fontWeight: this.props.layerInfo.text.isBold ? 'bolder' : '',
                        overflow: 'visible',
                        display: this.props.layerInfo.isVisible ? '' : 'none', cursor: this.props.layerInfo.isLocked ? 'default' : 'move'
                      }}
                  >{this.props.layerInfo.name}</div>
                </div>
            }
            {
              this.props.layerInfo.type === 'pic' &&
              <img src={ this.props.layerInfo.url }
                   id={ this.props.layerInfo.id }
                   alt={ this.props.layerInfo.url }
                   draggable="false"
                   style={ {
                     zIndex: 3,
                     width: this.props.layerInfo.basic.width+'mm',
                     height: this.props.layerInfo.basic.length+'mm',
                     opacity:  this.props.layerInfo.color.alpha / 100,
                   } }
              />
            }
            {
              this.props.layerInfo.type === 'svg' &&
                (
                    this.props.layerInfo.tech.effectData&&this.props.layerInfo.tech.effectData['合并结果']
                        ?
                        <div
                            id={ this.props.layerInfo.id }
                            draggable="false"
                            style={ {
                              zIndex: 3,
                              width: this.props.layerInfo.basic.width+'mm',
                              height: this.props.layerInfo.basic.length+'mm',
                              opacity:  this.props.layerInfo.color.alpha / 100,
                            } }
                        >
                          <ImgModel
                              uv={ this.props.layerInfo.url }
                              coverage={ this.props.layerInfo.tech.effectData['合并结果'] }
                          />
                        </div>
                        :
                        <SvgLayer
                            svgUrl={this.props.layerInfo.url}
                            colorList={this.props.layerInfo.colorList}
                            id={ this.props.layerInfo.id }
                            alt={ this.props.layerInfo.url }
                            draggable="false"
                            style={ {
                              zIndex: 3,
                              width: this.props.layerInfo.basic.width+'mm',
                              height: this.props.layerInfo.basic.length+'mm',
                              opacity:  this.props.layerInfo.color.alpha / 100,
                            } }
                        ></SvgLayer>
                )
            }
            <div className="rotateHandle"
                 style={{display: 'none'}}
                 onMouseDown={(e) => this.handleRotate(e, true)}
            ><RedoOutlined /></div>
          </div>

              </div>
            </div>
    );
  }
}

export default LayerBox;
