import React from 'react';
import styles from './layerItem.module.css'
import '../../App.css'
import { LockOutlined, UnlockOutlined, EyeOutlined, EyeInvisibleOutlined, PictureOutlined, FontSizeOutlined, BorderOutlined } from '@ant-design/icons';
/* unloggedIn/loggedIn/loggedIn_2D/loggedIn_3D */



class LayerItem extends React.Component {
    handleClick(e) {
        this.props.func(e);
    }
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.card} style={this.props.isSelected ? { background: 'rgba(24, 144, 255, 0.1)' } : {}} onClick={this.handleClick.bind(this, ['select', this.props.id])}>
                    <div className={styles.left}>
                        {this.props.type === 'svg' && <BorderOutlined className={styles.typeIcon}></BorderOutlined>}
                        {this.props.type === 'text' && <FontSizeOutlined className={styles.typeIcon}></FontSizeOutlined>}
                        {this.props.type === 'pic' && <PictureOutlined className={styles.typeIcon}></PictureOutlined>}
                        <div className={styles.name}>
                            {this.props.name}
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className='lock'>{
                        this.props.isLocked
                            ?
                            <LockOutlined className={styles.icon} onClick={this.handleClick.bind(this, ['lock', this.props.id])}></LockOutlined>
                            :
                            <UnlockOutlined className={styles.icon} onClick={this.handleClick.bind(this, ['lock', this.props.id])}></UnlockOutlined>
                    }
                    </div>
                    <div className='visible'>
                        {
                            this.props.isVisible
                                ?
                                <EyeOutlined className={styles.icon} onClick={this.handleClick.bind(this, ['visible', this.props.id])}></EyeOutlined>
                                :
                                <EyeInvisibleOutlined className={styles.icon} onClick={this.handleClick.bind(this, ['visible', this.props.id])}></EyeInvisibleOutlined>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default LayerItem;




