import {useEffect, useState, forwardRef} from 'react';
import './svgLayer.scss'
import axios from 'axios'
import {isDev} from '../../utils/config';

const SvgLayer = (props, ref) => {
  let [id, setId]=useState(`svgBody${props.id}`)
  let [svgDomString, setSvgDomString]=useState('')
  useEffect(() => {
    handleFiles(props.svgUrl)
  }, [])

  useEffect(()=>{
    let isUnmount = false
    function update(){
      !isUnmount && getColorSvg(svgDomString)
    }
    update()
    return () => {
      isUnmount = true
    }
    }, [props, setSvgDomString])


  function handleFiles(url){
    if(isDev && url.indexOf('http') > -1){
      url = url.slice(26)
    }
    axios.get(url, {
      contentType: 'blob'
    }).then((data) => {
      setSvgDomString(data.data)
      getColorSvg(data.data)
    });
  }

  /**
   * 获取变色渲染后svg
   * @param svgData
   */
  function getColorSvg(svgData){
    if (!svgData) return
    let svgBody = document.getElementById(id)
    if (!svgBody) return
    const shadowRoot = svgBody.shadowRoot || svgBody.attachShadow({mode: 'open'});
    svgData = svgData.replace(/ns0:/g, '')
    svgData = svgData.replace(/:ns0/g, '')
    for(let key in props.colorList){
      if(key !== props.colorList[key]){
        let reg = new RegExp(`${key}`, 'g')
        svgData = svgData.replace(reg, props.colorList[key])
      }
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(svgData, "image/svg+xml");
    let svgDom = doc.getElementsByTagName('svg')[0] || doc.getElementsByTagName('ns0:svg')[0]
    svgDom.style.width = '100%'
    svgDom.style.height = '100%'
    shadowRoot.innerHTML = ''
    shadowRoot.appendChild(svgDom)
  }
  return (
      <div
          className="svg-layer-content"
          style={props.style}
          id={id}
          key={props.svgUrl}
      >
      </div>
  )
}
export default forwardRef(SvgLayer)
