import React, { useState, useEffect, useCallback } from 'react';
import './selectBox.css'
import '../../App.css'
import SelectBar from '../selectBar/selectBar';
import { Button } from 'antd';
import { getHomeModelList } from "../../api";
import PubSub from "pubsub-js";
import { useNavigate } from 'react-router-dom';


const SelectBox = (props) => {
  const [type, setType] = useState('');
  const [modelType, setModelType] = useState('');
  const [picList, setPicList] = useState([]);
  const navigate = useNavigate();

  // 获取模型数据
  const getPicList = useCallback(() => {
    const params = {
      type: type,
      modelType: modelType,
    };
    getHomeModelList(params).then((res) => {
      setPicList(res ? res.data.data : []);
    });
  }, [type, modelType]);

  useEffect(() => {
    getPicList();
  }, [getPicList]);

  const changeType = (newType) => {
    setType(newType);
  };

  const changeModelType = (newModelType) => {
    setModelType(newModelType);
  };

  const gotoDesign = (model) => {
    const token = sessionStorage.getItem('__config_token');
    if (!token) {
      PubSub.publish('openLoginModal');
    } else {
      navigate(`/design?modelId=${model.modelId}`);
    }
  };

  return (
    <div className='selectBox'>
      <SelectBar
        selectedItem={modelType}
        typeList={props.modelTypeList}
        changeType={changeModelType}
      />
      <SelectBar
        selectedItem={type}
        typeList={props.typeList}
        changeType={changeType}
      />
      <div className='picContainer'>
        {picList.map((item) => (
          <div key={item.modelId} className='picItemContainer'>
            <img src={item.picture} alt='' className='pic' />
            <div className='picHover'>
              <img src={item.uv} alt='' />
              <Button
                className='link-btn'
                type='primary'
                onClick={() => gotoDesign(item)}
              >
                开始设计
              </Button>
            </div>
            {item.modelName}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectBox;
