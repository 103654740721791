import React from 'react';
import './home.css'
import '../../App.css'
import Banner from '../../components/banner/banner';
import NavigateBar from '../../components/navigateBar/navigateBar';
import SelectBox from '../../components/selectBox/selectBox';
import Tail from '../../components/tail/tail';
import {getHomeModelType, getHomeType} from "../../api";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeList: [],
            modelTypeList: []
        }
    }
    componentDidMount() {
        getHomeType().then(res => {
            this.setState({
                typeList: [
                    {
                        code: '',
                        name: '行业类别'
                    },
                    ...(res?res.data.data:[])
                ]
            })
        })
        getHomeModelType().then(res => {
            this.setState({
                modelTypeList: [
                    {
                        code: '',
                        name: '材质结构工艺'
                    },
                    ...(res?res.data.data:[])
                ]
            })
        })
    }

    render() {
        return (
            <div>
                <NavigateBar title='首页' state='unloggedIn'></NavigateBar>
                <Banner buttonName='开始设计' title='3 分钟智助设计，3D 高真效果，即刻智能报价，智选优质工厂' description='创新盒形，创意设计，智能智慧包装'></Banner>
                <div className='listContainer'>
                    <div className='titleContainer'>
                        <div className='listSubtitle'></div>
                        <div className='listTitle'>30+类型的包装模板，即开即用</div>
                        <SelectBox typeList={this.state.typeList} modelTypeList={this.state.modelTypeList}></SelectBox>
                    </div>
                </div>
                <Tail></Tail>
            </div>
        );
    }
}

export default Home;
