import React from 'react';
import './leftPanel.css'
import '../../App.css'
import { Button, Radio, Modal, Upload,message } from 'antd';
import nullPic from '../../assets/icon/empty-img-gray.png'
import addPic from '../../assets/icon/addPic.png';
import addText from '../../assets/icon/addText.png'
import filterArrow from '../../assets/icon/filter_arrow.png'
import filterArrowGray from '../../assets/icon/filter_arrow_2.png'
import toResource from '../../assets/icon/toResource.png'
import toLayer from '../../assets/icon/toLayer.png'
import Search from 'antd/lib/input/Search';
import ModelCard from '../modelCard/modelCard';
import LayerItem from '../layerItem/layerItem';
import { Select } from 'antd';
import { UserOutlined, FireOutlined, InboxOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
import ImageBlock from '../imageblock/ImageBlock';
import PubSub from 'pubsub-js';
import {getMaterials, getPrototypes, getSvgs, myUpload, onPicUpload, onSvgUpload, onUpload} from "../../api";
const { Option } = Select;
const draggleRef = { current: null };
const { Dragger } = Upload;

const uploadProps = {
    name: 'file',
    multiple: true,
    action: '/api/upload',
    showUploadList: false,
    onChange(info) {
        const { status } = info.file;

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },

    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const panelState = {
    MODEL: 1,
    PACK: 2,
    PIC: 3,
    TEXT: 4,
    SVG: 5,
    INNER: 6
};

const picList = [
    { id: 1, src: 'https://yun.baoxiaohe.com/render/20220708/29818400001.jpg?imageView2/2/w/400/h/300/q/95', name: '中秋求是月饼大礼包设计' },
    { id: 2, src: 'https://yun.baoxiaohe.com/render/20220113/220010.jpg?imageView2/1/w/400/h/400/q/95', name: '水乳包装设计' },
    { id: 3, src: 'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95', name: '月饼包装设计' },
    { id: 4, src: 'https://yun.baoxiaohe.com/render/20220708/29818400001.jpg?imageView2/2/w/400/h/300/q/95', name: '卡片3' },
    { id: 5, src: 'https://yun.baoxiaohe.com/render/20220708/29818400001.jpg?imageView2/2/w/400/h/300/q/95', name: '卡片3' },
    { id: 6, src: 'https://yun.baoxiaohe.com/render/20220113/220010.jpg?imageView2/1/w/400/h/400/q/95', name: '水乳包装设计' },
    { id: 7, src: 'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95', name: '月饼包装设计' },
    { id: 8, src: 'https://yun.baoxiaohe.com/render/20220708/29818400001.jpg?imageView2/2/w/400/h/300/q/95', name: '卡片3' },
    { id: 9, src: 'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95', name: '月饼包装设计' },
    { id: 10, src: 'https://yun.baoxiaohe.com/render/20220113/220010.jpg?imageView2/1/w/400/h/400/q/95', name: '水乳包装设计' },
    { id: 11, src: 'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95', name: '月饼包装设计' },
    { id: 12, src: 'https://yun.baoxiaohe.com/render/20220708/29818400001.jpg?imageView2/2/w/400/h/300/q/95', name: '卡片3' },
    { id: 13, src: 'https://yun.baoxiaohe.com/render/20220113/220010.jpg?imageView2/1/w/400/h/400/q/95', name: '水乳包装设计' },
    { id: 14, src: 'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95', name: '月饼包装设计' },
    { id: 15, src: 'https://yun.baoxiaohe.com/render/20220113/220010.jpg?imageView2/1/w/400/h/400/q/95', name: '水乳包装设计' },
    { id: 16, src: 'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95', name: '月饼包装设计' },
]
const data=['https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95',
'https://yun.baoxiaohe.com/render/20220209/17408200001.jpg?imageView2/1/w/400/h/400/q/95',
]
const typeList = [
    {
        label: '美妆护肤',
        value: '1'
    }, {
        label: '精品礼盒',
        value: '2'
    }, {
        label: '生物保健',
        value: '3'
    }, {
        label: '数码科技',
        value: '4'
    }, {
        label: '服饰',
        value: '5'
    }, {
        label: '月饼',
        value: '6'
    }, {
        label: '食品特产',
        value: '7'
    }, {
        label: '画册笔记',
        value: '8'
    }
]

class UploadButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayUploadModal: false,
            draggable: false,
            bounds: { left: 0, top: 0, bottom: 0, right: 0 },
            selectedItem: '图片'
        };
        this.addText = this.addText.bind(this)
    }
    uploadConfirm() {
        console.log("上传" + this.state.selectedItem)
        this.setState({
            displayUploadModal: !this.state.displayUploadModal
        })
    }
    addText(){
        this.props.func(['addText'])
    }

    handleClick() {
        this.setState({
            displayUploadModal: !this.state.displayUploadModal
        })
    }
    uploadChange(e) {
        //console.log(e.target.value)
        this.setState({
            selectedItem: e.target.value,
        })
    }
    onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }
        this.setState({
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        })
    };
    onImgUpload = (file) => {
        if(this.state.selectedItem === '图片'){
            onPicUpload(file.file).then(res => {
                message.success('上传成功')
                this.setState({
                    displayUploadModal: false
                })
                this.props.uploadSuccess()
            })
        } else {
            onSvgUpload(file.file).then(res => {
                message.success('上传成功')
                this.setState({
                    displayUploadModal: false
                })
                this.props.uploadSuccess()
            })
        }
    }
    render() {
        return (
            <>
                <div className='buttonMask'></div>
                <Button type='primary' style={{ width: 188 }} icon={<img src={addText} alt='' className='icon' style={{ marginRight: 12 }}></img>} onClick={this.addText} ghost>添加自由文本</Button>
                <Button type='primary' style={{ width: 188 }} icon={<img src={addPic} alt='' className='icon' style={{ marginRight: 12 }}></img>} onClick={this.handleClick.bind(this)} ghost>上传本地素材</Button>
                <Modal title={
                    <div onFocus={() => { }}
                        onBlur={() => { }}
                        onMouseOver={() => {
                            this.setState({
                                draggable: true
                            })
                        }}
                        onMouseOut={() => {
                            this.setState({
                                draggable: false
                            })
                        }}
                    >
                        上传选项
                    </div>}
                    footer={null} open={this.state.displayUploadModal} maskClosable={false} width={500}
                    centered={true}
                    modalRender={(modal) => (
                        <Draggable
                            disabled={!this.state.draggable}
                            bounds={this.state.bounds}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                >
                    <div>
                        <Radio.Group name="selectUpload" defaultValue={'图片'} onChange={this.uploadChange.bind(this)}>
                            <Radio value={'图片'}>{'图片(.png, .jpg)'}</Radio>
                            <Radio value={'图标'}>{'图标(.svg)'}</Radio>
                        </Radio.Group>
                    </div>

                    <Dragger
                      {...uploadProps}
                      accept={this.state.selectedItem === '图标' ? '.svg' : '.png,.jpg'}
                      customRequest={this.onImgUpload}
                      style={{marginTop:12}}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖拽文件至此区域上传</p>
                        <p className="ant-upload-hint">
                            {this.state.selectedItem==='图标'?'支持.svg格式':'支持.png, .jpg格式'}
                        </p>
                    </Dragger>
                    <div className='buttonGroup'>
                        <Button onClick={this.handleClick.bind(this)} type='default'>取消</Button>
                        <Button onClick={this.uploadConfirm.bind(this)} type='primary'>确认</Button>
                    </div>
                </Modal >
            </>

        );
    }

}

class TypeBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFold: true,
            url:'',
        };
        this.addPic = this.addPic.bind(this)
    }
    handleFold() {
        this.setState({
            isFold: !this.state.isFold
        })
    }
    // handleOnMaterialsClick = (material) => {
    //     this.props.onAddNewImgMaterial(material);
    // }
    addPic =(e) => {
        if(this.props.isSvg){
            this.props.func(['addSvg',e])
        } else {
            this.props.func(['addPic',e])
        }
    }
    //发布
    handleOnClick = (url) => {
        this.setState(()=>{
            return{
                url:url,
            }
        },()=>{
            PubSub.publish('clearCount', url);
            console.log(url)
        });
    }
    render() {
        return (
            <div className='typeBox'>
                <div className='typeBoxHeader'>
                    <div className='typeBoxTitleBox'>
                        {this.props.icon}
                        <div className='typeBoxTitle'>{this.props.title}</div>
                    </div>
                    <div >
                        <img src={filterArrowGray} alt='' className={this.state.isFold ? 'filterIcon' : 'filterIconShow'} onClick={this.handleFold.bind(this)}></img>
                    </div>
                </div>
                <div className={`typeBoxBody ${this.state.isFold ? 'close':'open'}`}>
                    {
                        this.props.isNull === null ?
                            <div className='nullTipBox'>
                                <img src={nullPic} alt='' className='nullUpload'></img>
                                <div style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: 10 }}>您还没有上传图片哦</div>
                            </div>
                            :
                            (this.state.isFold ? this.props.list.slice(0,2): this.props.list).map((item,index)=>{
                              return <ImageBlock key={index} img={item.imgUrl} onClick={()=>this.addPic(item)}/>// <img alt=''key={index} src={item} className='typeBoxPic'></img>
                            })
                    }
                </div>
            </div>

        )
    };
}
class LeftPanel extends React.Component {
    constructor(props) {
        super(props);
        //this.handleClick=this.handleClick.bind(this);
        this.state = ({
            selectedModel: null,
            layerMode: false,
            selectedLayer: null,
            PicLibraryMode: true,
            modelType: '',
            modal: '',
            searchValue: undefined,
            modelTypeList: typeList,
            modelList: [],
            materialTypes: [],
            materialList: [],
            myImages: [],
            svgTypes: [],
            svgList: [],
            mySvg: [],
            modelName: '',
        })
        this.handleLayer = this.handleLayer.bind(this)
        this.changeModel = this.changeModel.bind(this)
    }
    componentDidMount() {
        this.onModalSearch()
        this.onSearch()
    }

    handleClick(e) {
        this.props.onClick(e);
    }
    changeModel(e) {
        //console.log(e);
        this.setState({
            selectedModel: e,
        })
    }
    swapMode() {
        this.setState({
            layerMode: !this.state.layerMode,
        })
    }
    changeLibraryMode() {
        this.setState({
            PicLibraryMode: !this.state.PicLibraryMode,
            searchValue: ''
        }, () => {
            this.onSearch('')
        })
    }
    handleLayer(e) {
        //console.log(e);
        switch (e[0]) {
            case 'select': {
                this.props.onClick(['changeSelectedLayer',e[1]]);
                break;
            }
            case 'lock': {
                let newLayerList = this.props.layerList.slice();
                newLayerList[e[1]].isLocked = !this.props.layerList[e[1]].isLocked;
                this.props.onClick(["changeLayer",newLayerList]);
                break;
            }
            case 'visible': {
                let newLayerList = this.props.layerList.slice();
                newLayerList[e[1]].isVisible = !this.props.layerList[e[1]].isVisible;
                this.props.onClick(["changeLayer",newLayerList]);
                break;
            }
            default: {}
        }
    }
    onModelTypeChange = (value) => {
        this.setState({
            modelType: value
        }, () => {
            this.onModalSearch(this.state.searchValue)
        })
    }
    changeSearchValue = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }
    onNext = () => {
        let modal = this.state.modelList.find(item => item.id === this.state.selectedModel)
        this.setState({
            searchValue: '',
            modelName: modal.name
        })
        this.handleClick(['next', modal])
    }
    onModalSearch = (value) => {
        getPrototypes({
            name: value,
            type: this.state.modelType
        }).then(res => {
            let modelList = res?res.data.data:[]
            let model = modelList.find(item => item.id == this.props.modelId)
            this.setState({
                modelName: model ? model.name : '',
                modelList: modelList
            })
        })
    }
    onSearch = (value) => {
        if(this.state.PicLibraryMode){
            getMaterials({
                name: value,
                typeCode: 1
            }).then(res => {
                this.setState({
                    materialList: res?res.data.data:[]
                })
            })
        } else {
            getMaterials({
                name: value,
                typeCode: 2
            }).then(res => {
                this.setState({
                    svgList: res?res.data.data:[]
                })
            })
        }
        this.onGetMyUpload()
    }
    /**
     * 获取我的上传图片或者svg
     */
    onGetMyUpload = () => {
        if(this.state.PicLibraryMode){
            myUpload({
                typeCode: 2
            }).then(res => {
                this.setState({
                    myImages: res?res.data.data.map(item =>{
                        item.imgUrl = item.img
                        return item
                    }):[]
                })
            })
        } else {
            myUpload({
                typeCode: 3
            }).then(res => {
                this.setState({
                    mySvg: res?res.data.data.map(item =>{
                        item.imgUrl = item.img
                        return item
                    }):[]
                })
            })
        }
    }

    render() {
        return (<>
            {this.props.panelState === panelState.MODEL ||this.props.panelState === panelState.INNER ?

                <div className='modelPanel'>
                    <div className='modelHead'>
                        <div className='panelTitle'>模板</div>
                        <Select
                            popupClassName='filterMenu'
                            className='filterTitle'
                            style={{ width: 100, marginRight: -10 }}
                            bordered={false}
                            placeholder={'全部'}
                            onChange={this.onModelTypeChange}
                            suffixIcon={<img src={filterArrow} alt='' className='filterArrow'></img>}
                            allowClear
                        >
                            {this.state.modelTypeList.map((item, index) => { return <Option value={item.value} className='filterChoice' key={index}>{item.label}</Option> })}
                        </Select>
                        { this.props.modelId && <div className="cancel-model"
                              onClick={this.handleClick.bind(this, ['cancelChangeModel'])}>取消</div>}
                    </div>
                    <div className='modelBody'>
                        <Search
                            value={this.state.searchValue}
                            onChange={this.changeSearchValue}
                            placeholder="在案例库中搜索"
                            allowClear
                            size="small"
                            onSearch={this.onModalSearch}
                            enterButton
                        />
                        <div className='modelListContainer'>
                            {this.state.modelList.map((item) =>
                              <ModelCard src={item.imgUrl} name={item.name} selectedModel={this.state.selectedModel} key={item.id} id={item.id} onClick={this.changeModel}></ModelCard>
                            )}
                            <div className='mask'></div>
                        </div>
                    </div>
                    <div className='modelFooter'>
                        <Button type='primary'
                            disabled={this.state.selectedModel === null}
                            style={{ width: 188 }}
                            onClick={this.onNext}>
                            下一步
                        </Button>
                    </div>
                </div>
                :
                <div className='contentPanel'>
                    <div className='contentBody'>
                        {!this.state.layerMode ?
                            <div className='libraryPage'>
                                <div className='modelStateBox'>
                                    <div className='panelTitle'>模板</div>
                                    <div className='modelTag'>{this.state.modelName}</div>
                                    <div className='filterTitle' onClick={this.handleClick.bind(this, ['changeModel'])}>更改</div>
                                </div>
                                <div className='modelStateBox'>
                                    <div className='panelTitle'>素材</div>
                                    <Radio.Group defaultValue="1" size="small" style={{ marginTop: 8 }} onChange={this.changeLibraryMode.bind(this)}>
                                        <Radio.Button value="1" style={this.state.PicLibraryMode ? { borderBottomColor: 'white', borderLeftColor: '#F0F0F0', borderRightColor: '#F0F0F0', borderTopColor: '#F0F0F0' } : { borderColor: '#F0F0F0' }}>图片</Radio.Button>
                                        <Radio.Button value="2" style={!this.state.PicLibraryMode ? { borderBottomColor: 'white', borderLeftColor: '#F0F0F0', borderRightColor: '#F0F0F0', borderTopColor: '#F0F0F0' } : { borderColor: '#F0F0F0' }}>图标</Radio.Button>
                                    </Radio.Group>
                                </div>
                                <div className='materialBox'>
                                    {this.state.PicLibraryMode ?
                                        <div className='typeBoxContainer'>
                                            <TypeBox
                                              title='我的资产'
                                              icon={<UserOutlined style={{ color: '#1890FF' }}></UserOutlined>}
                                              list={this.state.myImages}
                                              func={this.props.onClick}
                                            ></TypeBox>
                                            <div className='divideLine'></div>
                                            {
                                                this.state.materialList.map(item => {
                                                    return <TypeBox
                                                      key={item.typeCode}
                                                      title={item.type || item.typeTxt}
                                                      func={this.props.onClick}
                                                      list={item.lists}
                                                    />
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='typeBoxContainer'>
                                            <TypeBox title='我的资产'
                                                     isSvg={true}
                                                     func={this.props.onClick}
                                                     icon={<UserOutlined style={{ color: '#1890FF' }}></UserOutlined>}
                                                     list={this.state.mySvg}></TypeBox>
                                            <div className='divideLine'></div>
                                            {
                                                this.state.svgList.map(item => {
                                                    return <TypeBox
                                                      isSvg={true}
                                                      key={item.typeCode}
                                                      title={item.type || item.typeTxt}
                                                      func={this.props.onClick}
                                                      list={item.lists}
                                                    />
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div className='LayerPage'>{
                                this.props.layerList.map((item, index) => {
                                    return (
                                        <LayerItem key={item.id} id={index} name={item.name} type={item.type} isLocked={item.isLocked} isVisible={item.isVisible} isSelected={index === this.props.selectedLayer} func={this.handleLayer}></LayerItem>
                                    )
                                })
                            }</div>
                        }

                    </div>
                    <UploadButton func={this.props.onClick} uploadSuccess={this.onGetMyUpload}></UploadButton>
                    <div className='contentFooter' onClick={this.swapMode.bind(this)}>
                        {!this.state.layerMode ?
                            <>
                                <img src={toLayer} alt='' className='icon'></img>
                                <div className='contentFooterTitle'>切换至图层面板</div>
                            </>
                            :
                            <>
                                <img src={toResource} alt='' className='icon'></img>
                                <div className='contentFooterTitle'>切换至资源库面板</div>
                            </>
                        }
                    </div>
                </div >
            }
        </>)
    }
}

export default LeftPanel;
