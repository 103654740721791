
import {baseUrl} from '../../utils/config';
const texture = [
  {
    "map": baseUrl + "/advancedMaterialTextures/mg_color.png",
    "metalnessMap": baseUrl + "/advancedMaterialTextures/default_black.png",
    "roughnessMap": baseUrl + "/advancedMaterialTextures/default_black.png"
  },
  {
    "map": baseUrl + "/advancedMaterialTextures/MetalGoldPaint002_COL_4K_METALNESS.png",
    "displacementMap": baseUrl + "/advancedMaterialTextures/MetalGoldPaint002_DISP_2K_METALNESS.png",
    "normalMap": baseUrl + "/advancedMaterialTextures/MetalGoldPaint002_NRM_2K_METALNESS.png",
    "roughnessMap":baseUrl + "/advancedMaterialTextures/MetalGoldPaint002_ROUGHNESS_2K_METALNESS.png"
  },
  {
    "map": baseUrl + "/advancedMaterialTextures/FabricAnimalBlueVinyl001_COL_2K.jpg",
    "displacementMap": baseUrl + "/advancedMaterialTextures/FabricAnimalBlueVinyl001_DISP_2K.png",
    "normalMap": baseUrl + "/advancedMaterialTextures/FabricAnimalBlueVinyl001_NRM_2K.png"
  },
  {
    "map": baseUrl + "/advancedMaterialTextures/BricksPaintedWhite001_COL_2K.jpg",
    "displacementMap": baseUrl + "/advancedMaterialTextures/BricksPaintedWhite001_DISP_2K.jpg",
    "normalMap": baseUrl + "/advancedMaterialTextures/BricksPaintedWhite001_NRM_2K.jpg",
    "aoMap": baseUrl + "/advancedMaterialTextures/BricksPaintedWhite001_AO_2K.jpg"
  }
]
export default texture
