import React, {Component} from 'react';
import './ImageBlock.css'

class ImageBlock extends Component {

    render() {
        return (
            <div style={{display:'flex', flexDirection:'row'}}

                onClick={this.props.onClick}>
                <div className='img-block' style={{width:`${this.props.width}`,height:`${this.props.height}`}}>
                    <img src={this.props.img} alt=''/>
                </div>

            </div>
        );
    }
}


export default ImageBlock
