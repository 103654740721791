import React, {useEffect, useState} from 'react';
import '../../App.css'
import Tail from '../../components/tail/tail';
import NavigateBar from '../../components/navigateBar/navigateBar';
import styles from './myProject.module.css';
import PicCard from '../../components/picCard/picCard';
import {getMyProjectList} from "../../api";

const MyProject = (props) => {
    const [searchValue, setSearchValue] = useState('')
  const [type, setType] = useState(0)
  const [list, setList] = useState([])
    const onSearch = () =>{
      let params = {
        type: type || '',
        name: searchValue
      }
      console.log(params)
      getMyProjectList(params).then(res => {
        setList(res?res.data.data:[])
      })
    }
  useEffect(() => {
    onSearch();
  }, [type]);
    return (
        <div>
            <NavigateBar title='我的作品' state='loggedIn'></NavigateBar>

            <div className={styles.cardContainer}>
                <div className={styles.cardList}>
                  {list.map((item, index) =>
                    <PicCard id={item.id} src={item.projectPic} key={index} name={''} time={item.modifyDate}></PicCard>
                  )}
                </div>
            </div>
            <Tail></Tail>
        </div>
    );
}

export default MyProject;
