import React from 'react';

import './rightPanel.css'
import '../../App.css'
import nullPic from '../../assets/icon/empty-img-gray.png'
import PubSub from 'pubsub-js';
import { Button, Col, InputNumber, Modal, Popover, Radio, Row, Select, Slider } from 'antd';
import {
    BoldOutlined,
    DownloadOutlined,
    DownOutlined,
    InfoCircleOutlined,
    ItalicOutlined,
    LockOutlined,
    MinusOutlined,
    PlusOutlined,
    RotateRightOutlined,
    UnderlineOutlined
} from '@ant-design/icons';
import MyColorPicker from '../myColorPicker/myColorPicker';
import Draggable from 'react-draggable';
import {
    getDictEffect,
    getDictInnerMaterial,
    getDictMaterial,
    getDictPostPrinting,
    getFontList,
    getMaterialDetail,
    getMaterialTechnology,
    getMaterialWeight
} from "../../api";
import SizeModal from "./SizeModal";

const { Option } = Select;
const directionList = ['上方', '下方']

let fontFamilyList = []
const isDev = process?.env.NODE_ENV === 'development'
const Fragment = React.Fragment
/**
 * 加载字体
 * @param name
 * @param source
 */
function loadFont(name, source) {
    let url = `url(${source})`
    let font = new FontFace(name, url)
    font.load().then((loadFace) => {
        document.fonts.add(loadFace);
    })
}

/**
 * 获取字体
 */
getFontList().then(res => {
    fontFamilyList = res ? res.data.data.map(item => {
        if (!isDev) {
            loadFont(item.fontName, item.fontUrl)
        }
        return {
            value: item.fontName,
            label: item.fontName,
            id: item.fontId,
            url: item.fontUrl,
        }
    }) : []
})
const draggleRef = { current: null };
const transformList = ['translate', 'rotate', 'scale']


//印刷工艺文本
let advancedMaterial = []

getDictMaterial().then(res => {
    advancedMaterial = res?.data?.data.map(item => {
        return {
            name: item.showTxt,
            title: item.showTxt,
            label: item.showTxt,
            value: item.valueCode,
            description: item.description,
        }
    }) || []
    advancedMaterial = [{
        name: '无',
        title: '无',
        label: '无',
        value: 0
    }, ...advancedMaterial]
})

let innerMaterial = [{
    name: '无',
    title: '无',
    label: '无',
    value: 0
}]
getDictInnerMaterial().then(res => {
    innerMaterial = res?.data?.data.map(item => {
        return {
            name: item.showTxt,
            title: item.showTxt,
            label: item.showTxt,
            value: item.valueCode,
            description: item.description,
        }
    }) || []
    innerMaterial = [{
        name: '无',
        title: '无',
        label: '无',
        value: 0
    }, ...innerMaterial]
})
//厚度/克重
let thicknessOrWeights = [{
    name: '无',
    title: '无',
    label: '无',
    value: 0
}]


//印刷工艺文本
const printingProcess = [
    { name: '无', description: '' },
    { name: '专色印刷', description: '描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本' },
    { name: '四色印刷', description: '描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本' },
    { name: '丝网印', description: '描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本' },
    { name: 'UV印', description: '描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本' },
    { name: '逆向油', description: '描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本描述工艺的文本' },
]
//印刷后工艺文本
let postprintingProcess = [{
    name: '无',
    title: '无',
    label: '无',
    value: 0
}]


//效果处理工艺文本
let effectProcess = [{
    name: '无',
    title: '无',
    label: '无',
    value: 0
}]

getDictPostPrinting().then(res => {
    postprintingProcess = res?.data?.data.map(item => {
        return {
            name: item.showTxt,
            title: item.showTxt,
            label: item.showTxt,
            value: item.valueCode,
            url: item.url,
            materialRoughness: item.materialRoughness,
            description: item.description,
        }
    }) || []
    postprintingProcess = [{
        name: '无',
        title: '无',
        label: '无',
        value: 0
    }, ...postprintingProcess]
})
getDictEffect().then(res => {
    effectProcess = res?.data?.data.map(item => {
        return {
            name: item.showTxt,
            title: item.showTxt,
            label: item.showTxt,
            value: item.valueCode,
            url: item.url,
            materialMetalness: item.materialMetalness,
            description: item.description,
        }
    }) || []
    effectProcess = [{
        name: '无',
        title: '无',
        label: '无',
        value: 0
    }, ...effectProcess]
})
//内衬种类
const shapeList = ['矩形', '圆形']
const panelState = {
    MODEL: 1,
    PACK: 2,
    PIC: 3,
    TEXT: 4,
    SVG: 5,
    INNER: 6
};

class OptionFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayExport: false,
            draggable: false,
            bounds: { left: 0, top: 0, bottom: 0, right: 0 },
            selectedItem: '刀板图'
        };
    }
    exportConfirm() {
        this.props.func(['exportPic', this.state.selectedItem])
        this.setState({
            displayExport: !this.state.displayExport
        })
    }
    handleClick() {
        this.setState({
            displayExport: !this.state.displayExport
        })
    }
    exportChange(e) {
        this.setState({
            selectedItem: e.target.value,
        })
    }
    onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }
        this.setState({
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        })
    };
    render() {
        return (
            <div className='optionFooter'>
                <Button type='primary' style={{ width: 188 }} icon={<DownloadOutlined />} onClick={this.handleClick.bind(this)} ghost>导出</Button>
                <Modal title={
                    <div onFocus={() => { }}
                        onBlur={() => { }}
                        onMouseOver={() => {
                            this.setState({
                                draggable: true
                            })
                        }}
                        onMouseOut={() => {
                            this.setState({
                                draggable: false
                            })
                        }}
                    >
                        导出选项
                    </div>}
                    footer={null} open={this.state.displayExport} maskClosable={false} width={300}
                    centered={true}
                    modalRender={(modal) => (
                        <Draggable
                            disabled={!this.state.draggable}
                            bounds={this.state.bounds}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                >
                    <div>
                        <Radio.Group name="selectExport" defaultValue={'刀板图'} onChange={this.exportChange.bind(this)}>
                            <Radio value={'刀板图'}>刀板图</Radio>
                            <Radio value={'设计图'}>设计图</Radio>
                        </Radio.Group>
                    </div>
                    <div className='buttonGroup'>
                        <Button onClick={this.handleClick.bind(this)} type='default'>取消</Button>
                        <Button onClick={this.exportConfirm.bind(this)} type='primary'>确认</Button>
                    </div>
                </Modal >
            </div>
        );
    }
}

function CentiMeterInput(props) {
    return (
        <div className='propertyItem_half'>
            <div style={{ width: 16 }}>{props.name}</div>
            <div className="right">
                <InputNumber
                    min={props.min}
                    max={props.max}
                    size='small'
                    className="input-value"
                    disabled={props.disabled}
                    value={props.value}
                    onChange={props.func}
                    step={0.1}
                    precision={1}
                />
                <div className='centimeter'>mm</div>
            </div>
        </div>
    );
}

function DescriptionBox(props) {
    return (
        <div className='descriptionBox'>
            <InfoCircleOutlined style={{ fontSize: 24, color: '#1890FF', padding: 8 }}></InfoCircleOutlined>
            <div className='descriptionDetail' style={{ width: 142, padding: 8 }}>{props.value || '暂无介绍'}</div>
        </div>
    )
}

class BasicBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tech: null,
            transCtrlx: 0,
            transCtrly: 0,
            transCtrlz: 0,
            transCtrlrx: 0,
            transCtrlry: 0,
            transCtrlrz: 0,

            angleX: 0,
            angleY: 0,
            angleZ: 0,

            transCtrlsx: 1,
            transCtrlsy: 1,
            transCtrlsz: 1,
            width: props.layerInfo.size?.width || 100,
            height: props.layerInfo.size?.height || 100,
            length: props.layerInfo.size?.length || 100
        }
        this.handleChange_x = this.handleChange_x.bind(this)
        this.handleChange_y = this.handleChange_y.bind(this)
        this.handleChange_width = this.handleChange_width.bind(this)
        this.handleChange_length = this.handleChange_length.bind(this)
        this.handleChange_rotate = this.handleChange_rotate.bind(this)
        this.handleChange_lock = this.handleChange_lock.bind(this)
        this.handleChange3d = this.handleChange3d.bind(this)
        this.handleChangeScale = this.handleChangeScale.bind(this)
        this.state.angleX = this.radianToAngle(props.info3d.rotation.x)
        this.state.angleY = this.radianToAngle(props.info3d.rotation.y)
        this.state.angleZ = this.radianToAngle(props.info3d.rotation.z)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.layerInfo.type === 'pack') {
            if (nextProps.layerInfo.size.width !== this.state.width
                || nextProps.layerInfo.size.height !== this.state.height
                || nextProps.layerInfo.size.length !== this.state.length) {
                this.setState({
                    width: nextProps.layerInfo.size.width,
                    height: nextProps.layerInfo.size.height,
                    length: nextProps.layerInfo.size.length
                })
            }
        }
    }

    handleChange_x(e) {
        if (e === null) return;
        this.props.func(['changeBasic_x', this.props.layerInfo.type, e])
    }
    handleChange_y(e) {
        if (e === null) return;
        this.props.func(['changeBasic_y', this.props.layerInfo.type, e])
    }
    handleChange_width(e) {
        if (e === null) return;
        this.props.func(['changeBasic_width', this.props.layerInfo.type, e])
    }
    handleChange_length(e) {
        if (e === null) return;
        this.props.func(['changeBasic_length', this.props.layerInfo.type, e])
    }
    handleChange_rotate(e) {
        if (e === null) return;
        this.props.func(['changeBasic_rotate', this.props.layerInfo.type, e])
    }
    handleChange_lock(e) {
        this.props.func(['changeBasic_lock', this.props.layerInfo.type])
    }

    /**
     * 材质切换
     * 获取材质详情
     * @param valueCode
     */
    handleAdvancedMaterialChange = (valueCode) => {
        if (valueCode == 0) {
            this.props.func(['changeTech_adm', this.props.layerInfo.type, valueCode, { valueCode: '0' }])
            thicknessOrWeights = [{
                name: '无',
                title: '无',
                label: '无',
                value: 0
            }]
            getDictPostPrinting().then(res => {
                postprintingProcess = res?.data?.data.map(item => {
                    return {
                        name: item.showTxt,
                        title: item.showTxt,
                        label: item.showTxt,
                        value: item.valueCode,
                        url: item.url,
                        description: item.description,
                        materialRoughness: item.materialRoughness
                    }
                }) || []
                postprintingProcess = [{
                    name: '无',
                    title: '无',
                    label: '无',
                    value: 0
                }, ...postprintingProcess]
            })
            getDictEffect().then(res => {
                effectProcess = res?.data.data.map(item => {
                    return {
                        name: item.showTxt,
                        title: item.showTxt,
                        label: item.showTxt,
                        value: item.valueCode,
                        url: item.url,
                        description: item.description,
                        materialMetalness: item.materialMetalness
                    }
                })
                effectProcess = [{
                    name: '无',
                    title: '无',
                    label: '无',
                    value: 0
                }, ...effectProcess]
            })
        } else {
            Promise.all([getMaterialDetail({ valueCode }), getMaterialTechnology({ valueCode }), getMaterialWeight({ valueCode })])
                .then(([detail, technology, weights]) => {
                    postprintingProcess = technology.data.afterPrint.map(item => {
                        if (item.property) {
                            item.property = JSON.parse(item.property)
                            item.property['糙度'] = isDev ? item.property['糙度'] && item.property['糙度'].slice(26) : item.property['糙度']
                            item.property['金属度'] = isDev ? item.property['金属度'] && item.property['金属度'].slice(26) : item.property['金属度']
                        }
                        return {
                            name: item.showTxt,
                            title: item.showTxt,
                            label: item.showTxt,
                            value: item.valueCode,
                            url: item.url,
                            description: item.materialDesc,
                            materialRoughness: item.materialRoughness,
                            property: item.property ? item.property : {}
                        }
                    })
                    postprintingProcess = [{
                        name: '无',
                        title: '无',
                        label: '无',
                        value: 0
                    }, ...postprintingProcess]
                    effectProcess = technology.data.resultProcess.map(item => {
                        if (item.property) {
                            item.property = JSON.parse(item.property)
                            item.property['糙度'] = isDev ? item.property['糙度'] && item.property['糙度'].slice(26) : item.property['糙度']
                            item.property['金属度'] = isDev ? item.property['金属度'] && item.property['金属度'].slice(26) : item.property['金属度']
                        }
                        return {
                            name: item.showTxt,
                            title: item.showTxt,
                            label: item.showTxt,
                            value: item.valueCode,
                            url: item.url,
                            description: item.materialDesc,
                            materialMetalness: item.materialMetalness,
                            property: item.property ? item.property : {}
                        }
                    })
                    effectProcess = [{
                        name: '无',
                        title: '无',
                        label: '无',
                        value: 0
                    }, ...effectProcess]

                    let tech = detail.data.data[0]
                    tech.property = JSON.parse(tech.property)
                    if (process.env.NODE_ENV === 'development') {
                        tech.property['合并结果'] = tech.property['合并结果'].slice(26)
                        tech.property['金属度'] = tech.property['金属度'].slice(26)
                        tech.property['糙度'] = tech.property['糙度'].slice(26)
                    }
                    tech.lockUrl = technology.data.lockUrl
                    this.setState({
                        tech: tech
                    })

                    this.props.func(['changeTech_adm', this.props.layerInfo.type, valueCode, tech])

                    thicknessOrWeights = [
                        {
                            name: '无',
                            title: '无',
                            label: '无',
                            value: 0
                        },
                        ...weights.data.data.map(item => {
                            return {
                                name: item.厚度,
                                title: item.厚度,
                                label: item.厚度,
                                value: item.pageTHKId,
                                url: item.图片地址,
                            }
                        })
                    ]
                })

        }
        // this.props.func(['changeColor_color', this.props.layerInfo.type, advancedMaterial[e].color ])
    }
    handleChangeTechThickness = (e) => {
        this.props.func(['changeTech_adm_t_w', this.props.layerInfo.type, e])
    }
    changeRotateAngleBy3d(arg) {
        let rotateX = this.radianToAngle(this.props.info3d.rotation.x + arg.x)
        let rotateY = this.radianToAngle(this.props.info3d.rotation.y + arg.y)
        let rotateZ = this.radianToAngle(this.props.info3d.rotation.z + arg.z)
        this.setState({
            angleX: rotateX,
            angleY: rotateY,
            angleZ: rotateZ,
        })
    }
    handleChange3d(value, type, key) {
        const obj = {
            rotation: {
                x: 'angleX',
                y: 'angleY',
                z: 'angleZ',
            },
            position: {
                x: 'x',
                y: 'y',
                z: 'z',
            },
            scale: {
                x: 'x',
                y: 'y',
                z: 'z',
            },
        }
        let info3d = this.props.info3d
        if (type === 'rotation') {
            let radian = this.angleToRadian(value) - this.state[`transCtrlr${key}`]
            info3d[type][key] = radian
            this.setState({
                [obj[type][key]]: value,
            }, () => {
                this.props.func(['change_3d_info', info3d])
            })
        } else {
            info3d[type][key] = value
            this.props.func(['change_3d_info', info3d])
        }
    }
    // 组件间传值：brushColor
    componentDidMount() {


        this.token = PubSub.subscribe("changeTransCtrl", (functionName, arg) => {
            this.setState({
                transCtrlx: arg.x.toFixed(2),
                transCtrly: arg.y.toFixed(2),
                transCtrlz: arg.z.toFixed(2)
            });
        });
        this.token = PubSub.subscribe("changeTransCtrlr", (functionName, arg) => {
            console.log(arg)
            this.setState({
                transCtrlrx: arg.x.toFixed(2),
                transCtrlry: arg.y.toFixed(2),
                transCtrlrz: arg.z.toFixed(2),
            });
            this.changeRotateAngleBy3d(arg)
        });
        this.token = PubSub.subscribe("changeTransCtrls", (functionName, arg) => {
            this.setState({
                transCtrlsx: arg.x.toFixed(2),
                transCtrlsy: arg.y.toFixed(2),
                transCtrlsz: arg.z.toFixed(2)
            });
        });

    }

    onChangeSize(type, size) {
        this.setState({
            [type]: size,
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe("changeTransCtrl");
        PubSub.unsubscribe("changeTransCtrlr");
        PubSub.unsubscribe("changeTransCtrls");
    };

    /**
     * 改变尺寸
     */
    handleChangeSize() {
        PubSub.publish("changeSize", {
            width: this.state.width,
            height: this.state.height,
            length: this.state.length
        });
    }
    handleResetSize() {
        this.setState({
            width: this.props.layerInfo.size.width,
            height: this.props.layerInfo.size.height,
            length: this.props.layerInfo.size.length
        })
    }

    /**
     * 比例尺
     */
    handleChangeScale(value) {
        this.props.func(['change_pack_scale', value])
    }

    angleToRadian(string) {
        return (string * Math.PI) / 180; // 保留两位小数
    }
    radianToAngle(value) {
        return value * (180 / Math.PI)
    }
    render() {
        return (
            <Fragment>
                {
                    this.props.type === 2 &&
                    <div className='propertyBox'>
                        <div className='annotation'>基本</div>
                        <div className="propertyItem">
                            <Row style={{ width: '100%' }}>
                                <Col span={10}>材质</Col>
                                <Col span={14}>
                                    <Select
                                        value={this.props.layerInfo.tech.advancedMaterial}
                                        style={{ width: '100%' }}
                                        size='small'
                                        onChange={this.handleAdvancedMaterialChange}
                                        options={advancedMaterial}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                        <div className="propertyItem">
                            <Row style={{ width: '100%' }}>
                                <Col span={10}>厚度/克重</Col>
                                <Col span={14}>
                                    <Select
                                        value={this.props.layerInfo.tech.advancedMaterialThicknessAndWeight}
                                        style={{ width: '100%' }}
                                        size='small'
                                        onChange={this.handleChangeTechThickness}
                                    >
                                        {thicknessOrWeights.map((item, index) => {
                                            return <Option key={item.value} value={item.value}>
                                                <Popover content={<img key={item.url} style={{ width: 200 }} src={item.url} alt="" />} placement={"right"}>
                                                    <span>{item.label}</span>
                                                </Popover>
                                            </Option>
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                        <div className="propertyItem">
                            <div className="size-wrapper">
                                长宽高：
                                {this.state.length} x {this.state.width} x {this.state.height}
                                mm
                            </div>
                        </div>
                        <div className="property" style={{ width: '100%' }}>
                            <div>比例尺：（1: {this.props.layerInfo.basic.scale || 1}）</div>
                            <Row gutter={10} style={{ width: '100%' }}>
                                <Col span={24}>
                                    <Slider min={1}
                                        max={10}
                                        step={0.1}
                                        onChange={this.handleChangeScale}
                                        value={typeof this.props.layerInfo.basic.scale === 'number' ? this.props.layerInfo.basic.scale : 1}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <SizeModal width={this.state.width} height={this.state.height} length={this.state.length} modelId={this.props.modelId}></SizeModal>
                        </div>
                        {
                            this.state.tech?.materialDesc &&
                            <>
                                <DescriptionBox value={this.state.tech.materialDesc}></DescriptionBox>
                            </>
                        }
                    </div>
                }
                {
                    this.props.type === 6 &&
                    <div className='propertyBox'>
                        <div className='annotation'>基本</div>
                        <div className="position-box">
                            <div className="position-box-title">位置</div>
                            <Row style={{ width: '100%' }} gutter={10}>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">X</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={parseFloat(this.props.info3d.position.x) + parseFloat(this.state.transCtrlx)}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e - parseFloat(this.state.transCtrlx), 'position', 'x');
                                                // this.setState({transCtrlx:0});
                                                console.log('changelll')
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">Y</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={parseFloat(this.props.info3d.position.z) + parseFloat(this.state.transCtrlz)}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e - parseFloat(this.state.transCtrlz), 'position', 'z');
                                                // this.setState({transCtrlz:0});
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">Z</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={parseFloat(this.props.info3d.position.y) + parseFloat(this.state.transCtrly)}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e - parseFloat(this.state.transCtrly), 'position', 'y');
                                                //this.setState({transCtrly:0});
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="position-box">
                            <div className="position-box-title">旋转</div>
                            <Row style={{ width: '100%' }} gutter={10}>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">X(°)</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={this.state.angleX}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e, 'rotation', 'x');
                                                // this.setState({transCtrlrx:0});
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">Y(°)</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={this.state.angleZ}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e, 'rotation', 'z');
                                                //this.setState({transCtrlrz:0});

                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">Z(°)</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={this.state.angleY}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e, 'rotation', 'y');
                                                //this.setState({transCtrlry:0});
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="position-box">
                            <div className="position-box-title">缩放</div>
                            <Row style={{ width: '100%' }} gutter={10}>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">X</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={parseFloat(this.props.info3d.scale.x) * parseFloat(this.state.transCtrlsx)}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e, 'scale', 'x');
                                                this.setState({ transCtrlsx: 1 });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">Y</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={parseFloat(this.props.info3d.scale.z) * parseFloat(this.state.transCtrlsz)}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e, 'scale', 'z');
                                                this.setState({ transCtrlsz: 1 });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="pos-input">
                                        <div className="pos-type">Z</div>
                                        <InputNumber size='small' style={{ width: '100%' }}
                                            controls={false}
                                            value={parseFloat(this.props.info3d.scale.y) * parseFloat(this.state.transCtrlsy)}
                                            disabled={this.props.layerInfo.isLocked}
                                            onChange={(e) => {
                                                this.handleChange3d(e, 'scale', 'y');
                                                this.setState({ transCtrlsy: 1 });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                {
                    this.props.type !== 2 && this.props.type !== 6 &&
                    <div className="propertyBox">
                        <div className="annotation">基本</div>
                        <div className="propertyItem">
                            <CentiMeterInput
                                disabled={this.props.layerInfo.isLocked}
                                name="X" min={0} max={1000}
                                func={this.handleChange_x}
                                value={this.props.layerInfo.basic.x}></CentiMeterInput>
                        </div>
                        <div className="propertyItem">
                            <CentiMeterInput
                                disabled={this.props.layerInfo.isLocked}
                                name="Y" min={0} max={1000}
                                func={this.handleChange_y}
                                value={this.props.layerInfo.basic.y}></CentiMeterInput>
                        </div>
                        {
                            this.props.type !== 4 &&
                            <>
                                <div className="propertyItem">
                                    <CentiMeterInput name="长" min={1}
                                        max={1000}
                                        disabled={this.props.layerInfo.isLocked}
                                        func={this.handleChange_length}
                                        value={this.props.layerInfo.basic.length}></CentiMeterInput>
                                </div>
                                <div className="propertyItem" style={{ justifyContent: 'flex-end' }}>
                                    <LockOutlined
                                        className={this.props.layerInfo.basic.isLock ?
                                            'fontBoxIconChecked' :
                                            'lockIcon'}
                                        onClick={this.handleChange_lock} />
                                </div>
                                <div className="propertyItem">
                                    <CentiMeterInput name="宽" min={1}
                                        max={1000}
                                        disabled={this.props.layerInfo.isLocked}
                                        func={this.handleChange_width}
                                        value={this.props.layerInfo.basic.width}></CentiMeterInput>
                                </div>
                            </>
                        }
                        <div className="propertyItem_half">
                            <RotateRightOutlined style={{
                                fontSize: 16,
                                marginLeft: -2,
                                marginRight: 6,
                            }} />
                            <InputNumber min={-180} max={180} size="small"
                                style={{ width: 60 }}
                                value={this.props.layerInfo.basic.rotate}
                                formatter={(value) => `${value} °`}
                                disabled={this.props.layerInfo.isLocked}
                                onChange={this.handleChange_rotate}
                            />
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

class PlaneBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    onChange_ot = (new_overallThickness) => {
        this.props.func(
            ['changePlane_ot', new_overallThickness, this.props.index]);
        this.forceUpdate();
    };
    onChange_gt = (new_grooveThickness) => {
        this.props.func(
            ['changePlane_gt', new_grooveThickness, this.props.index]);
    };

    handleChange_dr(e) {
        this.props.func(['changePlane_dr', directionList[e], this.props.index])
    }
    handleChange_shape(e) {
        this.props.func(['changePlane_shape', shapeList[e], this.props.index])
    }
    changeStatus = (e) => {
        this.setState({
            open: !this.state.open
        })
    }
    /**
     * 材质改改变
     * @param texture
     */
    onChangeTexture = (texture) => {
        this.props.func(['changePlane_texture', texture, this.props.index])
        this.forceUpdate()
    }
    /**
     * 等级改变
     * @param level
     */
    onChangeLevel = (level) => {
        this.props.func(['changePlane_level', level, this.props.index])
        this.forceUpdate()
    }
    removeInner = () => {
        this.props.func(['removeInner', this.props.index])
    }
    render() {
        return (
            <div className="plane-box">
                <div className="plane-box-header">
                    <div className="name">{this.props.layerInfo.name}</div>
                    <div className="right">
                        <Button className={`${this.state.open ? 'btn open' : 'close btn'}`} type="link" onClick={this.changeStatus} icon={<DownOutlined />}></Button>
                        <Button type="link" onClick={this.removeInner} icon={<MinusOutlined />}></Button>
                    </div>
                </div>
                <div className={`plane-box-content ${this.state.open ? 'open' : 'close'}`}>
                    <div className='propertyBox'>
                        <div className='annotation'>基础</div>
                        <div className="propertyItem">
                            <Row style={{ width: '100%' }}>
                                <Col span={10}>材质</Col>
                                <Col span={14}>
                                    <Select
                                        value={this.props.layerInfo.texture}
                                        style={{ width: '100%' }}
                                        size='small'
                                        onChange={this.onChangeTexture}
                                        options={innerMaterial}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                        {/* <div className="propertyItem">
                            <Row style={{width: '100%'}}>
                                <Col span={10}>等级</Col>
                                <Col span={14}>
                                    <Select
                                      value={this.props.layerInfo.level}
                                        style={{ width: '100%' }}
                                        size='small'
                                        onChange={this.onChangeLevel}
                                    >
                                        {advancedMaterial.map((item, index) => {
                                            return <Option key={index} value={index}>{item.name}</Option>
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                        </div> */}
                        <div className='annotation'>大小</div>
                        <div>
                            <div>大小比例</div>
                            <Row>
                                <Col span={12}>
                                    <Slider min={1} max={100} style={{ width: 80 }}
                                        onChange={this.onChange_ot}
                                        disabled={this.props.layerInfo.isLocked}
                                        defaultValue={100}
                                        value={typeof this.props.layerInfo.plane.overallThickness === 'number' ? this.props.layerInfo.plane.overallThickness : 0}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={100} size='small' controls={false}
                                        style={{ margin: '8 24px', width: 72, }}
                                        defaultValue={100}
                                        value={this.props.layerInfo.plane.overallThickness}
                                        onChange={this.onChange_ot}
                                        disabled={this.props.layerInfo.isLocked}
                                    />
                                </Col>
                                <Col span={4}><div style={{ margin: '3px 8px' }}>%</div></Col>
                            </Row>
                        </div>
                        <div>
                            <div>凹槽深度</div>
                            <Row gap={1}>
                                <Col span={12}>
                                    <Slider min={1} max={100} style={{ width: 80 }}
                                        onChange={this.onChange_gt}
                                        disabled={this.props.layerInfo.isLocked}
                                        value={typeof this.props.layerInfo.plane.grooveThickness === 'number' ? this.props.layerInfo.plane.grooveThickness : 0}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={100} size='small' controls={false}
                                        style={{ margin: '8 24px', width: 72, }}
                                        disabled={this.props.layerInfo.isLocked}
                                        value={this.props.layerInfo.plane.grooveThickness}
                                        onChange={this.onChange_gt}
                                    />
                                </Col>
                                <Col span={4}><div style={{ margin: '3px 8px' }}>%</div></Col>
                            </Row>
                        </div>
                        <div className='propertyItem'>
                            <div>方位</div>
                            <Select
                                disabled={this.props.layerInfo.isLocked}
                                defaultValue={this.props.layerInfo.plane.direction}
                                style={{ width: 100 }}
                                size='small'
                                onChange={this.handleChange_dr.bind(this)}
                            >
                                {directionList.map((item, index) => {
                                    return <Option key={index} value={index}>{item}</Option>
                                })}
                            </Select>

                        </div>
                        <div className='propertyItem'>
                            <div>形状</div>
                            <Select
                                disabled={this.props.layerInfo.isLocked}
                                defaultValue={this.props.layerInfo.plane.shape}
                                style={{ width: 100 }}
                                size='small'
                                onChange={this.handleChange_shape.bind(this)}
                            >
                                {shapeList.map((item, index) => {
                                    return <Option key={index} value={index}>{item}</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ColorBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.setColor = this.setColor.bind(this)
    }

    //slider
    onChangeAlpha = (newValue) => {
        this.props.func(['changeColor_alpha', this.props.layerInfo.type, newValue])

    };

    setColor(e) {
        this.props.func(['changeColor_color', this.props.layerInfo.type, e])
        // this.forceUpdate();
        this.forceUpdate()
    }

    render() {
        return (
            <div className='propertyBox'>
                {
                    this.props.type !== 3 && this.props.type !== 6 &&
                    <Fragment>
                        <div className='annotation'>颜色</div>
                        <MyColorPicker color={this.props.layerInfo.color.color} confirmColor={this.setColor}></MyColorPicker>
                    </Fragment>
                }
                <div>
                    <div>透明度</div>
                    <Row gutter={10}>
                        <Col span={14}>
                            <Slider min={0} max={100} onChange={this.onChangeAlpha}
                                disabled={this.props.layerInfo.isLocked}
                                value={typeof this.props.layerInfo.color.alpha === 'number' ? this.props.layerInfo.color.alpha : 0}
                            />
                        </Col>
                        <Col span={10}>
                            <div className="opacity-input">
                                <InputNumber
                                    disabled={this.props.layerInfo.isLocked}
                                    min={0} max={100} size='small' controls={false}
                                    style={{ width: '100%' }} value={this.props.layerInfo.color.alpha}
                                    onChange={this.onChangeAlpha}
                                />
                                <div className="units">%</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );

    }

}

class SvgColorBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.setColor = this.setColor.bind(this)
    }

    //slider
    onChangeAlpha = (newValue) => {
        this.props.func(['changeColor_alpha', this.props.layerInfo.type, newValue])
    };

    setColor(color, key) {
        let colorList = this.props.layerInfo.colorList
        colorList[key] = color
        this.props.func(['changeColor_color', this.props.layerInfo.type, colorList])
        // this.forceUpdate();
        this.forceUpdate()
    }

    render() {
        return (
            <div className='propertyBox'>
                {
                    this.props.type !== 3 &&
                    <Fragment>
                        <div className='annotation'>颜色</div>
                        <div className="color-list">
                            {
                                this.props.layerInfo.colorList && Object.entries(this.props.layerInfo.colorList).map((item => {
                                    return <MyColorPicker key={item[0]} color={item[1]} confirmColor={(color) => { this.setColor(color, item[0]) }}></MyColorPicker>
                                }))
                            }
                        </div>
                    </Fragment>
                }
                <div>
                    <div>透明度</div>
                    <Row gutter={10}>
                        <Col span={14}>
                            <Slider min={0} max={100} onChange={this.onChangeAlpha}
                                disabled={this.props.layerInfo.isLocked}
                                value={typeof this.props.layerInfo.color.alpha === 'number' ? this.props.layerInfo.color.alpha : 0}
                            />
                        </Col>
                        <Col span={10}>
                            <div className="opacity-input">
                                <InputNumber
                                    disabled={this.props.layerInfo.isLocked}
                                    min={0} max={100} size='small' controls={false}
                                    style={{ width: '100%' }} value={this.props.layerInfo.color.alpha}
                                    onChange={this.onChangeAlpha}
                                />
                                <div className="units">%</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );

    }

}

class TechnologyBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description_ef: '',
            description_po: '',
            description_pr: '',
        }

        this.handleEffectProcessChange = this.handleEffectProcessChange.bind(this);
        this.handlePostprintingProcessChange = this.handlePostprintingProcessChange.bind(this);
        this.handlePrintingProcessChange = this.handlePrintingProcessChange.bind(this);
    }
    componentDidMount() {
        this.setState({
            description_ef: '',
            description_po: '',
            description_pr: '',
        })
        if (this.props.layerInfo.tech.effectProcess) {
            let obj = effectProcess.find(item => item.value === this.props.layerInfo.tech.effectProcess)
            this.setState({
                description_ef: obj?.description,
            })
        }
        if (this.props.layerInfo.tech.postprintingProcess) {
            let obj = postprintingProcess.find(item => item.value === this.props.layerInfo.tech.postprintingProcess)
            this.setState({
                description_po: obj?.description,
            })
        }
        /*if (this.props.layerInfo.tech.printingProcess) {
            let obj = printingProcess.find(item => item.value === this.props.layerInfo.tech.printingProcess)
            this.setState({
                description_pr: printingProcess[this.props.layerInfo.tech.printingProcess].description,
            })
        }*/
    }
    componentWillReceiveProps(newProps) {
        if (!newProps.layerInfo.tech.effectProcess) {
            this.setState({
                description_ef: '',
            })
        }
        if (!newProps.layerInfo.tech.postprintingProcess) {
            this.setState({
                description_po: '',
            })
        }
    }

    /**
     * 效果处理工艺切换
     * @param e
     */
    handleEffectProcessChange(e, option) {
        if (option.value) {
            if (this.props.layerInfo.tech.advancedMaterial) {
                // 有材质的时候
                this.props.func(['changeTech_ep', this.props.layerInfo.type, option])
                this.setState({
                    description_po: option?.description,
                })
            } else {
                getMaterialDetail({
                    typeCode: 5,
                    valueCode: option.value
                }).then(res => {
                    let effect = res.data.data[0] || {}
                    effect.property = effect.property ? JSON.parse(effect.property) : {}
                    if (process.env.NODE_ENV === 'development') {
                        effect.property['金属度'] = effect.property['金属度'] && effect.property['金属度'].slice(26)
                        effect.property['糙度'] = effect.property['糙度'] && effect.property['糙度'].slice(26)
                    }
                    this.props.func(['changeTech_ep', this.props.layerInfo.type, {
                        value: option.value,
                        label: option.showTxt,
                        url: effect.property['糙度'],
                        materialRoughness: '',
                        property: effect.property
                    }])

                    this.setState({
                        description_po: option?.description,
                    })
                })
            }
        } else {
            this.props.func(['changeTech_ep', this.props.layerInfo.type, option])
            this.setState({
                description_po: option?.description,
            })
        }
    }

    /**
     * 印刷后工艺切换
     * @param e
     * @param option
     */
    handlePostprintingProcessChange(e, option) {
        if (option.value) {
            if (this.props.layerInfo.tech.advancedMaterial) {
                // 有材质的时候
                this.props.func(['changeTech_pop', this.props.layerInfo.type, option])
                this.setState({
                    description_po: option?.description,
                })
            } else {
                getMaterialDetail({
                    typeCode: 6,
                    valueCode: option.value
                }).then(res => {
                    let poster = res.data.data[0]
                    poster.property = JSON.parse(poster.property)
                    if (process.env.NODE_ENV === 'development') {
                        poster.property['金属度'] = poster.property['金属度'].slice(26)
                        poster.property['糙度'] = poster.property['糙度'].slice(26)
                    }
                    this.props.func(['changeTech_pop', this.props.layerInfo.type, {
                        value: option.value,
                        label: poster.showTxt,
                        url: poster.property['糙度'],
                        materialRoughness: '',
                        property: poster.property
                    }])

                    this.setState({
                        description_po: option?.description,
                    })
                })
            }
        } else {
            this.props.func(['changeTech_pop', this.props.layerInfo.type, option])
            this.setState({
                description_po: option?.description,
            })
        }
    }
    handlePrintingProcessChange(e) {
        this.props.func(['changeTech_prp', this.props.layerInfo.type, e])
        /*this.setState({
            description_pr: printingProcess[e].description,
        })*/
    }
    handleChange_Trans(e) {
        this.props.func(['changeTrans', transformList[e]])
    }
    render() {
        return (
            <div className='propertyBox'>

                <div className='annotation'>工艺</div>
                {/* <div className='propertyItem'>
                    <div>变换</div>
                    <Select
                        defaultValue={'translate'}
                        style={{ width: 100 }}
                        size='small'
                        onChange={this.handleChange_Trans.bind(this)}
                    >
                        {transformList.map((item, index) => {
                            return <Option key={index} value={index}>{item}</Option>
                        })}
                    </Select>

                </div> */}
                {(this.props.type === panelState.SVG || this.props.type === panelState.PIC || this.props.type === panelState.TEXT) &&
                    <Fragment>
                        <div className='propertyItem'>
                            <div>效果处理工艺</div>
                            <Select
                                value={this.props.layerInfo.tech.effectProcess}
                                style={{ width: 100 }}
                                size='small'
                                onChange={this.handleEffectProcessChange}
                                options={effectProcess}
                            >
                            </Select>
                        </div>
                        {
                            this.state.description_ef !== '' &&
                            <>
                                <DescriptionBox value={this.state.description_ef}></DescriptionBox>
                            </>
                        }
                    </Fragment>
                }
                {this.props.type === panelState.PACK &&
                    <Fragment>
                        {/*<div className='propertyItem'>
                            <div>印刷工艺</div>
                            <Select
                                value={printingProcess[this.props.layerInfo.tech.printingProcess].name}
                                style={{ width: 100 }}
                                size='small'
                                onChange={this.handlePrintingProcessChange}
                            >
                                {printingProcess.map((item, index) => {
                                    return <Option key={index} value={index}>{item.name}</Option>
                                })}
                            </Select>
                        </div>*/}
                        {
                            this.state.description_pr !== '' &&
                            <>
                                <DescriptionBox value={this.state.description_pr}></DescriptionBox>
                            </>
                        }
                        <div className='propertyItem'>
                            <div>印刷后工艺</div>
                            <Select
                                value={this.props.layerInfo.tech.postprintingProcess}
                                style={{ width: 100 }}
                                size='small'
                                onChange={this.handlePostprintingProcessChange}
                                options={postprintingProcess}
                            >
                            </Select>
                        </div>
                        {
                            this.state.description_po !== '' &&
                            <>
                                <DescriptionBox value={this.state.description_po}></DescriptionBox>
                            </>
                        }
                    </Fragment>
                }
            </div>
        );
    }
}

class FontBox extends React.Component {
    handleChange_ft(font) {
        this.props.func(['changeTEXT_ft', font])
    }
    handleChange_fs(e) {
        this.props.func(['changeTEXT_fs', e])
    }
    handleChange_bold(e) {
        this.props.func(['changeTEXT_bold'])
    }
    handleChange_underlined(e) {
        this.props.func(['changeTEXT_ul'])
    }
    handleChange_italic(e) {
        this.props.func(['changeTEXT_it'])
    }
    render() {
        return (<Fragment>
            {
                this.props.type === panelState.TEXT &&
                <div className='propertyBox'>
                    <div className='annotation'>字符</div>
                    <div className='propertyItem'>
                        <Select
                            style={{ width: 100 }}
                            size='small'
                            value={this.props.layerInfo.text.fontFamily}
                            onChange={this.handleChange_ft.bind(this)}
                        >
                            {fontFamilyList.map((item, index) => {
                                return <Option key={index} value={item.value}>{item.value}</Option>
                            })}
                        </Select>
                        <InputNumber min={1} max={80} size='small' formatter={(value) => `${value} pt`} style={{ marginLeft: 8, width: 76, }}
                            value={this.props.layerInfo.text.fontSize}
                            onChange={this.handleChange_fs.bind(this)}
                        />
                    </div>
                    <div className='propertyItem'>
                        <BoldOutlined className={this.props.layerInfo.text.isBold ? 'fontBoxIconChecked' : 'fontBoxIcon'} onClick={this.handleChange_bold.bind(this)} />
                        <UnderlineOutlined className={this.props.layerInfo.text.isUnderlined ? 'fontBoxIconChecked' : 'fontBoxIcon'} onClick={this.handleChange_underlined.bind(this)} />
                        <ItalicOutlined className={this.props.layerInfo.text.isItalic ? 'fontBoxIconChecked' : 'fontBoxIcon'} onClick={this.handleChange_italic.bind(this)} />
                    </div>

                </div>
            }
        </Fragment>);
    }
}

function OptionHead(props) {
    return (
        <div className='optionHeadContainer'>
            <div className='optionHead'>
                <div className='panelTitle'>预览</div>
                {/* <button className='visibleButton' onClick={props.setVisible}>
                    <img  className='buttonImg' src={props.isVisible===true? Visble:Invisible} ></img>
                </button> */}
            </div>
            <div className='previewWindow'></div>
        </div>
    );
}

class RightPanel extends React.Component {
    constructor(props) {
        super(props);
        this.addShape = this.addShape.bind(this)
        this.state = {
            shape: "",
            newInner: [],
            isVisible: true,
        }
    };
    setVisible = (e) => {
        this.props.onClick(['setVisible', e])
        console.log(this.state.isVisible)
        // this.setState({
        //     isVisible:!this.state.isVisible
        // })
    }

    /**
     * 内存只能添加一个
     * @param e
     */
    addNewShape = (e) => {
        if (this.props.innerList.length === 0) {
            this.props.onClick(['addNewShape', e])
            console.log('addNewShape')
        }
    }
    addShape = (e) => {
        const shape1 = e.target.getAttribute("data-shape")
        let newInner = this.props.innerProfile
        console.log(newInner)
        this.setState({
            inner: newInner
        })
        this.forceUpdate();
        console.log(shape1);
        this.setState({ shape: shape1 })
        PubSub.publish('model1', shape1);

    }
    getMsg = () => {
        this.props.getDatas(this.state.shape)
    }
    render() {
        switch (this.props.panelState) {
            case panelState.MODEL:
                return (
                    <div className='warnPanel'>
                        <img src={nullPic} alt='' className='nullPic'></img>
                        请先选择模板
                    </div>
                );
            case panelState.PACK:
                return (
                    <div className='optionPanel'>
                        <OptionHead setVisible={this.setVisible} isVisible={this.state.isVisible} />
                        <div className='optionBody right'>
                            <div className='optionHead'>
                                <div className='panelTitle'>包装属性</div>
                            </div>
                            <div className='propertyBody'>
                                <BasicBox modelId={this.props.modelId} info3d={this.props.info3d} func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.packProfile}></BasicBox>
                                <div className='panelDivider'></div>
                                {/* <button className='setVisible' onClick={this.setVisible}>可见</button> */}
                                <ColorBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.packProfile}></ColorBox>
                                <div className='panelDivider'></div>
                                <TechnologyBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.packProfile}></TechnologyBox>
                            </div>
                        </div>
                        <OptionFooter func={this.props.onClick}></OptionFooter>
                    </div >
                );
            case panelState.PIC:
                return (
                    <div className='optionPanel'>
                        <OptionHead></OptionHead>
                        <div className='optionBody right'>
                            <div className='optionHead'>
                                <div className='panelTitle'>图片属性</div>
                            </div>
                            <div className='propertyBody'>
                                <BasicBox info3d={this.props.info3d} func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></BasicBox>
                                <div className='panelDivider'></div>
                                <ColorBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></ColorBox>
                            </div>
                        </div>
                        <OptionFooter func={this.props.onClick}></OptionFooter>
                    </div>
                );
            case panelState.TEXT:
                return (
                    <div className='optionPanel'>
                        <OptionHead></OptionHead>
                        <div className='optionBody right'>
                            <div className='optionHead'>
                                <div className='panelTitle'>文字属性</div>
                            </div>
                            <div className='propertyBody'>
                                <BasicBox info3d={this.props.info3d} func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></BasicBox>
                                <div className='panelDivider'></div>
                                <ColorBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></ColorBox>
                                <div className='panelDivider'></div>
                                <FontBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></FontBox>
                                <div className='panelDivider'></div>
                                <TechnologyBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></TechnologyBox>
                            </div>
                        </div>
                        <OptionFooter func={this.props.onClick}></OptionFooter>
                    </div>
                );
            case panelState.SVG:
                return (
                    <div className='optionPanel'>
                        <OptionHead></OptionHead>
                        <div className='optionBody right'>
                            <div className='optionHead'>
                                <div className='panelTitle'>图标属性</div>
                            </div>
                            <div className='propertyBody'>
                                <BasicBox info3d={this.props.info3d} func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></BasicBox>
                                <div className='panelDivider'></div>
                                <SvgColorBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></SvgColorBox>
                                <div className='panelDivider'></div>
                                <TechnologyBox func={this.props.onClick} type={this.props.panelState} layerInfo={this.props.layerList[this.props.selectedLayer]}></TechnologyBox>
                            </div>
                        </div>
                        <OptionFooter func={this.props.onClick}></OptionFooter>
                    </div>
                );
            case panelState.INNER:
                return (
                    <div className='optionPanel'>
                        <OptionHead setVisible={this.setVisible} isVisible={this.state.isVisible} />
                        <div className='optionBody right inner'>
                            <div className='optionHead'>
                                {/* <div></div> */}

                                <div className='panelTitle'>属性</div>
                            </div>
                            <div className='propertyBody'>
                                <BasicBox info3d={this.props.info3d} type={this.props.panelState} func={this.props.onClick} layerInfo={this.props.innerProfile}></BasicBox>
                                <div className='panelDivider'></div>
                                <ColorBox type={this.props.panelState} func={this.props.onClick} layerInfo={this.props.innerProfile}></ColorBox>
                            </div>

                            <div className='optionHead'>
                                {/* <div></div> */}

                                <div className='panelTitle'>内衬属性</div>

                                <Button type="link" onClick={this.addNewShape} icon={<PlusOutlined />}></Button>
                            </div>
                            <div className='propertyBody'>
                                {
                                    this.props.innerList.length > 0 ? this.props.innerList.map((inner, index) => {
                                        return <PlaneBox key={index} func={this.props.onClick} layerInfo={inner} index={index}></PlaneBox>
                                    }) : []
                                }

                            </div>
                        </div>
                        <OptionFooter func={this.props.onClick} ></OptionFooter>
                    </div>
                );

            default: {
                return ''
            }
        }
    }
}

export default RightPanel;
