import React, { createRef } from "react";
import "./DisplayContainer.scss";
import RightContextMenu from "../layers/RightContextMenu";
import LayerBox from "../../components/editableBox/LayerBox";
import { AimOutlined, RotateRightOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import SvgLayer from "../layers/SvgLayer";
import axios from "axios";
import {getImage2Blob} from '../../api';
import uvList from "./uv_svg/uvList";
import {HEX_COLOR_REG, RGB_COLOR_REG} from "./colorReg";
import {isDev} from "../../utils/config";
/**
 * uv-svg加载
 * 1.加载svg底图，分析背景色，提供变色能力
 * 2.加载svg线图
 */
class Model extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorList: {},
      loading: false
    };
  }
  componentDidMount() {
    if (this.props.modelId) {
      this.getColorList(this.props.packInfo.svg.uv);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.modelId !== this.props.modelId) {
      this.getColorList(nextProps.packInfo.svg.uv);
    }
    if (nextProps.fill !== this.props.fill) {
      this.getColorList(nextProps.packInfo.svg.uv);
    }
    if (nextProps.packInfo.svg.uv !== this.props.packInfo.svg.uv) {
      this.getColorList(nextProps.packInfo.svg.uv);
    }
  }

  getColorList = (url) => {
    let img = url;
    if (isDev && url.indexOf('http') > -1) {
      img = img.slice(26);
    }
    this.setState({
      colorList: {},
      loading: true
    })
    axios
      .get(img, {
        contentType: "blob",
      })
      .then((data) => {
        let colorList = {};
        let colors = [
          ...(data.data.match(RGB_COLOR_REG) || []),
          ...(data.data.match(HEX_COLOR_REG) || []),
          ...(data.data.match(/(white)/g) || []),
          ...(data.data.match(/(black)/g) || []),
        ];
        colors.length > 0 &&
          colors.map((color) => {
            colorList[color] = this.props.fill;
          });
        this.setState({
          colorList: colorList,
          loading: false
        });
      });
  };
  render() {
    if (this.props.modelId) {
      //return <UvSvg1 fill={this.props.fill}></UvSvg1>;
      return (
          <>
            { this.props.packInfo.tech.lockUrl ?
                <ImgModel
                    uv={ this.props.previewImg }
                    coverage={ this.props.packInfo.tech.lockUrl }
                /> :
              this.state.loading ? '' : <SvgLayer
                    key={this.props.packInfo.svg.uv}
                    svgUrl={ this.props.packInfo.svg.uv }
                    colorList={ this.state.colorList }
                    id={ 'Uv' + this.props.modelId }
                    alt={ this.props.packInfo.svg.uv }
                    draggable="false"
                />
            }
            { this.props.packInfo.svg.uvLine && <img
                key={this.props.packInfo.svg.uvLine}
                className="uvLine"
                src={ this.props.packInfo.svg.uvLine }
                alt=""
                data-html2canvas-ignore
            /> }
          </>
      );
    } else {
      return "";
    }
  }
}
export class ImgModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maskUrl: ''
    }
  }
  componentDidMount() {
    this.renderMask()
  }

  renderMask = () => {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    // 加载第一张图像
    let imageUv = new Image();
    imageUv.src = this.props.uv;
    imageUv.onload = () => {
      let domWidth =imageUv.width,domHeight = imageUv.height
      canvas.width = imageUv.width
      canvas.height = imageUv.height
      ctx.drawImage(imageUv, 0, 0, domWidth, domHeight)
      let imageData  = ctx.getImageData(0,0, canvas.width, canvas.height)
      let length = domWidth * domHeight
      for (let i = 0; i < length * 4; i+=4) {
        if(imageData.data[1] === 255 && imageData.data[i+1]===255 && imageData.data[i+2] === 255){
          imageData.data[i+3] = 0 // 设为透明
        }
      }
      ctx.putImageData(imageData, 0, 0)
      getImage2Blob(this.props.coverage).then(coverage => {
        let imageCover = new Image();
        imageCover.src = coverage;
        imageCover.onload = () => {
          // 设置混合模式为 source-in
          ctx.globalCompositeOperation = 'source-in';

          // 绘制第二张图像
          ctx.drawImage(imageCover, 0, 0);

          // 清除混合模式
          ctx.globalCompositeOperation = 'source-over';
          let result = canvas.toDataURL()
          this.setState({
            maskUrl: result
          })
        }
      })
    }

  }
  render() {
    return (
      <div className="model-coverage-box" style={{
        backgroundImage: `url(${this.state.maskUrl})`,
      }}>
      </div>
    )
  }
}
class Canvas2D extends React.Component {
  constructor(props) {
    super(props);
    this.RightMenu = createRef();
    this.state = {
      layerIndex: null,
      scale: 100,
      eventX: null,
      eventY: null,
      oldTranslateX: null,
      oldTranslateY: null,
      startMoving: false,
      moving: false,
      translateX: 0,
      translateY: 0,
      canvasRotate: 0,
    };
  }
  componentDidMount() {
    window.addEventListener("keypress", (e) => {
      if (e.keyCode === 32) {
        this.setState({
          moving: true,
        });
      }
    });
    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 32) {
        this.setState({
          moving: false,
          eventX: null,
          eventY: null,
          oldTranslateX: null,
          oldTranslateY: null,
          startMoving: false,
        });
      }
    });
    let container = document.getElementById("canvasWrapper");
    if (document.addEventListener) {
      //firefox
      container.addEventListener(
        "DOMMouseScroll",
        (e) => {
          this.scrollFn(e);
        },
        false
      );
    }
    container.addEventListener(
      "mousewheel",
      (e) => {
        this.scrollFn(e);
      },
      false
    );
  }

  /**
   * 右键菜单功能
   * @param type
   */
  handleRightMenu(type) {
    if (type === "copy") {
      this.props.onChange(["copy"]);
    } else if (type === "delete") {
      this.props.onChange(["delete"]);
    } else {
      this.props.onChange(["updateLevel", type]);
    }
  }

  /**
   * 图层右键点击
   * @param e
   * @param layer
   * @param index
   */
  handleOnRightClick = (e, index) => {
    e.preventDefault();
    this.setState({
      layerIndex: index,
    });
    this.props.onChange(["changeSelectedLayer", index]);
    this.RightMenu.current.handleRightClick(e);
  };

  scrollFn(e) {
    if (!this.props.modelCode && this.props.modelCode !== 0) return;
    if (window.scrollTimer) return;
    // 如果有需要阻止默认事件或事件冒泡的可以打开
    // e.preventDefault();
    // e.stopPropagation();
    window.scrollTimer = setTimeout(() => {
      e = e || window.event;
      var isGoDown = false;
      if (e.wheelDelta) {
        //第一步：先判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) {
          //当滑轮向上滚动时
          isGoDown = true;
        }
      } else if (e.detail) {
        //Firefox滑轮事件
        if (e.detail < 0) {
          //当滑轮向下滚动时
          isGoDown = true;
        }
      }
      if (isGoDown) {
        //向下滚动，执行放大元素
        this.zoomUp();
      } else {
        //向上滚动，执行缩小元素
        this.zoomDown();
      }
      clearTimeout(window.scrollTimer);
      window.scrollTimer = null;
    }, 100);
  }
  /**
   * 画布放大
   */
  zoomUp() {
    let scale = this.state.scale;
    scale += 20;
    if (scale > 1000) {
      scale = 1000;
    }
    this.setState({
      scale: scale,
    });
  }

  /**
   * 画布缩小
   */
  zoomDown() {
    let scale = this.state.scale;
    scale -= 20;
    if (scale < 20) {
      scale = 20;
    }
    this.setState({
      scale: scale,
    });
  }

  /**
   * 输入画布百分比
   * @param e
   */
  onScaleCanvasChange = (e) => {
    this.setState({
      scale: parseInt(e.target.value),
    });
  };

  onStartMove(e) {
    if (!this.props.modelCode && this.props.modelCode !== 0) return;
    if (!this.state.moving) return;
    this.setState({
      eventX: e.pageX,
      eventY: e.pageY,
      oldTranslateX: this.state.translateX,
      oldTranslateY: this.state.translateY,
      startMoving: true,
    });
  }
  onFinishMove(e) {
    if (!this.props.modelCode && this.props.modelCode !== 0) return;
    if (!this.state.moving) return;
    this.setState({
      eventX: null,
      eventY: null,
      oldTranslateX: null,
      oldTranslateY: null,
      startMoving: false,
    });
  }
  onMoving(e) {
    if (this.state.moving && this.state.startMoving) {
      let moveX = (e.pageX - this.state.eventX) / (this.state.scale/100);
      let moveY = (e.pageY - this.state.eventY) / (this.state.scale/100);
      this.setState({
        translateX: this.state.oldTranslateX + moveX,
        translateY: this.state.oldTranslateY + moveY,
      });
    } else {
    }
  }
  handleRotateCanvas() {
    let canvasRotate = this.state.canvasRotate + 45;
    this.setState({
      canvasRotate: canvasRotate % 360,
    });
  }

  render() {
    return (
      <div className="canvas-wrapper"
           id="canvasWrapper"
           onMouseDown={this.onStartMove.bind(this)}
           onMouseUp={this.onFinishMove.bind(this)}
           onMouseMove={this.onMoving.bind(this)}
           style={{cursor: this.state.moving ? "grab" : "default", width: this.props.viewMode === "3D" ? 0: '100%'}}
      >
        <div
          className={
            this.props.viewMode === "3D"
              ? "previewCanvas canvas2D"
              : "container-block"
          }
          style={{
            width: this.props.pack.basic.width * this.props.pack.basic.scale + "mm",
            height: this.props.pack.basic.length * this.props.pack.basic.scale + "mm",
            position: "absolute",
            pointerEvents: this.state.moving ? "none" : "all",
            transform:
              this.props.viewMode === "3D"
                ? ""
                : `scale(${this.state.scale / 100})
                 translate(${this.state.translateX}px,${this.state.translateY}px)
                 rotate(${this.state.canvasRotate}deg)`,
          }}
          onClick={this.props.handleOnContainerClick}
          name="画布"
        >
          <div
            id="container"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          >
            <div className="preview-img" data-html2canvas-ignore>
              <img src={this.props.previewImg} alt=""/>
            </div>
            <div
              id="canvasContainer"
              className={"model-container"}
              style={{
                height: "100%",
                width: "100%",
                opacity: this.props.pack.color.alpha / 100,
              }}
            >
              <Model
                modelId={this.props.modelId}
                packInfo={this.props.pack}
                previewImg={this.props.previewImg}
                fill={this.props.pack.color.color}
              ></Model>
            </div>
            <div style={{zIndex: 2}}>
              {this.props.layerList.map((layer, index) => {
                return (
                  // 图层统一dom
                  layer?.isVisible ? (
                    <LayerBox
                      key={layer.id}
                      name="图层内容"
                      onChange={this.props.onChange}
                      scale={this.state.scale}
                      layerInfo={layer}
                      isSelected={this.props.selectedLayer === index}
                      index={index}
                      canvasRotate={this.state.canvasRotate}
                      onRightMenu={this.handleOnRightClick.bind(this)}
                    />
                  ) : (
                    ""
                  )
                );
              })}
            </div>
          </div>
        </div>
        <RightContextMenu
          ref={this.RightMenu}
          handleRightMenu={this.handleRightMenu.bind(this)}
          layerIndex={this.state.layerIndex}
          layerList={this.props.layerList}
        />
        {this.props.viewMode === "2D" && (
          <div className="canvas-status-container">
            <InputNumber
              min={20}
              max={1000}
              style={{width: "120px"}}
              controls={false}
              onBlur={this.onScaleCanvasChange}
              addonBefore={<InputBefore down={this.zoomDown.bind(this)}/>}
              addonAfter={<InputAfter up={this.zoomUp.bind(this)}/>}
              value={this.state.scale}
            />
            <div
              className="moving-status rotate"
              onClick={this.handleRotateCanvas.bind(this)}
            >
              <RotateRightOutlined/>
            </div>
            <div
              className={`moving-status ${this.state.moving ? "active" : ""}`}
            >
              <AimOutlined/>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const InputBefore = (props) => {
  return (
    <div className="input-addon-btn" onClick={props.down}>
      -
    </div>
  );
};
const InputAfter = (props) => {
  return (
    <div className="input-addon-btn" onClick={props.up}>
      +
    </div>
  );
};

export default Canvas2D;
