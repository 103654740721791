import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './views/home';
import WcLogin from './views/wcLogin';
import Community from './views/community';
import MyProject from './views/myProject';
import Design from './views/design/design';
import reportWebVitals from './reportWebVitals';
import './App.css';
import { getToken } from "./api";
import { isDev } from "./utils/config";

if (isDev || process.env.REACT_APP_IS_TEST) {
  getToken({
    mobile: '15757575757'
  }).then(res => {
    sessionStorage.setItem('__config_token', res?.data.token)
  });
}

if (!sessionStorage.getItem('__config_token')) {
  window.location = process.env.REACT_APP_IS_TEST ? "/lb/#/home" : "/#/home";
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <Routes>
      <Route path="/wcLogin" element={<WcLogin />} />
      <Route path="/home" element={<Home />} />
      <Route path="/community" element={<Community />} />
      <Route path="/design" element={<Design />} />
      <Route path="/myProject" element={<MyProject />} />
      <Route path="/" element={<Navigate to="/home" />} />
    </Routes>
  </Router>
);

reportWebVitals();
