import React, { Component } from "react";
import "./DisplayContainer.scss";
import Canvas3D from "./canvas3D";
import Canvas2D from "./canvas2D";
import PubSub from "pubsub-js";

// 更新3d防抖
window.updateTimeout = null;
class DisplayContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onRef(this);
    this.Token = PubSub.subscribe("clearCount", (_, data) => {
      // console.log('消息',data)
      this.forceUpdate();
      this.setState({ url: data });
    });
  }

  handleOnContainerClick = (e) => {
    this.props.onChange(["changeSelectedLayer", null]);
  };

  // 图层变化
  onLayerChange(data) {
    this.props.onChange(data);
  }

  render() {
    return (
      <div className="display-container">
        <Canvas3D
          viewMode={this.props.viewMode}
          isVisible={this.props.isVisible}
          onClick={this.props.onChange}
          modelCode={this.props.modelCode}
          img_src={this.props.previewImg}
          inner={this.props.inner}
          innerList={this.props.innerList}
          transList={this.props.transList}
          shape={this.props.shape}
          active={this.props.active}
          outerPrint={this.props.pack}
          modelId={this.props.modelId}
          info3d={this.props.info3d}
          techImages={this.props.techImages}
          renderLoading={this.props.renderLoading}
          isRender={this.props.isRender}
          layerList={ this.props.layerList }
          selectedLayer={ this.state.selectedLayer }
          // layerInfo={this.props.layerList[this.props.selectedLayer]}
          pack={this.props.pack}
        />
        <Canvas2D
          onChange={this.onLayerChange.bind(this)}
          selectedLayer={this.props.selectedLayer}
          viewMode={this.props.viewMode}
          prototype_uv={this.props.prototype_uv}
          layerList={this.props.layerList}
          pack={this.props.pack}
          modelCode={this.props.modelCode}
          modelId={this.props.modelId}
          handleOnContainerClick={this.handleOnContainerClick}
          previewImg={this.props.previewImg}
          techImages={this.props.techImages}
        />
      </div>
    );
  }
}

export default DisplayContainer;
