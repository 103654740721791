import React, { Component, useCallback } from 'react';
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { Button, message, Modal, notification, Spin } from 'antd';
// import {Prompt} from 'react-router-dom';
import { useNavigate, useLocation, useBlocker } from 'react-router-dom';
import html2canvas from 'html2canvas';
import '../../App.css';
import NavigateBar from '../../components/navigateBar/navigateBar';
import styles from './design.module.css';
import LeftPanel from '../../components/leftPanel/leftPanel';
import RightPanel from '../../components/rightPanel/rightPanel';
import DisplayContainer from '../../components/displayContainer/DisplayContainer';

import UserModel from '../../model';
import { getHashQuery, getImageWH } from '../../utils/common';
import {
  downloadImage, getMaterialDetail,
  getModelData,
  getProjectData,
  getRenderStatus,
  onUploadMyProject,
  requestRender,
  saveData,
} from '../../api';
import axios from 'axios';
import { getMetalnessPic, getRoughnessPic } from './func';
import PubSub from "pubsub-js";
import * as jspdf from "jspdf";
import { baseUrl, isDev } from '../../utils/config';

const userModel = new UserModel();

const antIcon = <LoadingOutlined style={{ fontSize: 100, color: '#1890FF' }} spin />;
// 内衬inner
class MaterialInfo {   //获取位置信息等

  constructor(shape, color, pos, rot, sca) {
    this.shape = shape;
    this.color = color; // array length: two
    this.pos = pos; // (left, top)
    this.rot = rot//旋转
    this.sca = sca//缩放
  }

  get material_shape() { return this.shape }
  set material_shape(new_shape) { this.shape = new_shape; }
  get material_color() { return this.color }
  set material_color(new_color) { this.color = new_color; }
  get material_pos() { return this.pos }
  set material_pos(new_pos) { this.pos = new_pos; }
  get material_rot() { return this.rot }
  set material_rot(new_rot) { this.rot = new_rot }
  get material_sca() { return this.sca }
  set material_sca(new_sca) { this.sca = new_sca }
  get is_selected() { return this.isSelected }
  set is_selected(new_state) { this.isSelected = new_state }
}

var history = [];
var currentStep = -1;
var MAX_LAYER_ID = 1;
const panelState = {
  MODEL: 1,
  PACK: 2,
  PIC: 3,
  TEXT: 4,
  SVG: 5,
  INNER: 6,
};




//中途退出弹窗提示
const openNotificationWithIcon = (type) => {
  const key = `open${Date.now()}`;
  notification[type]({
    description: '选定模板后，您可以在此进行切换，但可能会丢失所有的更改。',
    placement: 'topLeft',
    btn: <Button type="primary" size="small"
      onClick={() => notification.close(key)}>我知道了</Button>,
    key,
    icon: <ArrowLeftOutlined style={{ color: '#108ee9' }} />,
    closeIcon: <></>,
    style: { marginLeft: 200, marginTop: 10 },
  });
};
const successSave = () => {
  Modal.success({
    content: '文件保存成功！',
  });
};

class DimensionControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleClick(e) {
    this.props.func(e);
  }

  render() {
    if (this.props.selectingState === panelState.MODEL) {
      return (<></>);
    }
    return (
      <div className={styles.dimensionControl}>
        {
          this.props.viewMode === '3D' ?
            <>
              <div className={styles.unselectedDimension}
                onClick={this.handleClick.bind(this, ['changeViewMode', '2D'])}>2D
              </div>
              <div className={styles.selectedDimension}>3D</div>
            </>
            :
            <>
              <div className={styles.selectedDimension}>2D</div>
              <div className={styles.unselectedDimension}
                onClick={this.handleClick.bind(this, ['changeViewMode', '3D'])}>3D
              </div>
            </>
        }
      </div>
    );
  }
}

/**
 * 展示区
 */
class ViewBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shape: "",
    }
  }

  onRef = (ref) => {
    this.child = ref;
  };

  onModelChange = () => {
    this.child.update();
  };

  onAddNewImgMaterial = (materials_src) => {
    this.child.addNewImgMaterial(materials_src);
  };
  onAddPrototype = (prototype_index) => {
    this.child.addPrototype(prototype_index);
  };
  onAddNewTextMaterial = (
    placeholder, font_size, font_color, font_family, content) => {
    this.child.addNewTextMaterial(placeholder, font_size, font_color,
      font_family, content);
  };

  render() {
    return (
      <DisplayContainer
        onRef={this.onRef}
        onChange={this.props.onChange}
        selectedLayer={this.props.selectedLayer}
        viewMode={this.props.viewMode}
        model={this.props.model}
        modelId={this.props.modelId}
        pack={this.props.pack}
        modelCode={this.props.modelCode}
        prototype_uv={this.props.prototype_uv}
        layerList={this.props.layerList}
        updateImg={this.props.updateImg}
        isVisible={this.props.isVisible}
        transList={this.props.transList}
        previewImg={this.props.previewImg}
        innerList={this.props.innerList}
        inner={this.props.inner}
        shape={this.props.shape}
        active={this.props.active}
        info3d={this.props.info3d}
        techImages={this.props.techImages}
        renderLoading={this.props.renderLoading}
        isRender={this.props.isRender}
      // layerInfo={this.props.layerList[this.props.selectedLayer]}
      />
    );
  }
}

/**
 * 设计
 */
// 包装组件
const withNavigationAndPrompt = WrappedComponent => {
  return props => {
    const navigate = useNavigate();
    const location = useLocation();

    const usePrompt = (message, when) => {
      const blocker = useCallback(
        tx => {
          if (when) {
            Modal.confirm({
              title: '您还未保存，真的要退出吗?',
              icon: <ExclamationCircleOutlined />,
              content: '当前所做的修改可能会丢失。',
              okText: '保存',
              cancelType: 'danger',
              cancelText: '直接退出',
              onOk: () => {
                tx.retry();
              },
              onCancel: () => { },
            });
            return false;
          }
          return true;
        },
        [when]
      );

      useBlocker(blocker, when);
    };

    return (
      <WrappedComponent
        {...props}
        navigate={navigate}
        location={location}
        usePrompt={usePrompt}
      />
    );
  };
};

class Design extends React.Component {
  constructor(props) {
    super(props);
    const id = getHashQuery('id')
    const modelId = getHashQuery('modelId')
    const modelName = getHashQuery('title')
    this.state = {
      id: id,
      renderMask: false,
      renderLoading: false,
      nowurl: '',
      fileTitle: '请选择一个模板',
      selectingState: panelState.MODEL,
      modelId: modelId,//modelCode
      prototype_uv: null,
      undo: false,
      redo: false,
      active: false,
      // 包装
      pack: {
        type: 'pack',
        glb: '',
        basic: {
          x: 0,
          y: 0,
          length: 120,
          width: 120,
          rotate: 0,
          isLock: false,
          scale: 1
        },
        tech: {
          effectProcess: 0,
          advancedMaterial: undefined, // 材质
          materialProperty: {}, //材质贴图
          advancedMaterialThicknessAndWeight: null,
          postprintingProcess: 0, // 印刷后工艺
          postprintingPicture: '', // 印刷后工艺膜贴图
          printingProcess: 0,
        },
        color: {
          color: '#ff0000',
          alpha: 100,
        },
        svg: {
          uv: '',
          uvLine: ''
        }
      },
      // 内衬
      inner: {
        type: 'inner',
        level: undefined,
        texture: 0,
        textureData: {},
        basic: {
          x: 0,
          y: 0,
          length: 1,
          width: 1,
          rotate: 0,
          isLock: false
        },
        plane: {
          overallThickness: 100,
          grooveThickness: 40,
          direction: '上方',
          shape: '矩形'
        },
        tech: {
          effectProcess: 0,
          advancedMaterial: 0,
          postprintingProcess: 0,
          printingProcess: 0
        },
        color: {
          color: '#ff0000',
          alpha: 100
        },
        shape: {
          shape: "",
          list: [],
        }
      },
      innerList: [],
      layerList: [],
      transList: 'translate',
      selectedLayer: null,
      info3d: {
        position: {
          x: 0,
          y: 0,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0
        },
        scale: {
          x: 1,
          y: 1,
          z: 1
        },
        camera: {
          cameraId: 4,
          fov: 38.6,
          position: [0.803162, -0.003795, -1.16228]
        }
      },
      modelInfo: {
        sca_data: [1, 1, 1],
        rot_data: [0, 0, 0],
        pos_data: [0, 0, 0],
        lens_data: 38.6,
        campos_data: [0.803162, -1.16228, 0.003795],
        camrot_data: [0, 0, 0],
        node_data: [100, 100, 100]
      },
      viewMode: '2D',
      previewImg: '',
      techImages: [],
      isRender: false
    };
    this.clickBlank = this.clickBlank.bind(this);
  }

  componentDidMount() {
    console.log(this.state); // 检查 this.state 是否定义

    // 添加注册事件
    this.addHistory();
    PubSub.subscribe("update3D", (functionName, arg) => {
      this.update3DImg()
    });
    PubSub.subscribe("changeSize", (functionName, arg) => {
      const { res, size } = arg
      this.changeModelSize(res, size)
    });
    // 检查内容：
    if (this.props.location && this.props.location.state && this.props.location.state.title) {
      this.setState({
        fileTitle: this.props.location.state.title,
      });
    }
    if (this.state.id) {
      this.onLoadProject()
    }
    if (this.state.modelId) {
      this.onLoadModel(this.state.modelId)
    }
    //     // 检测返回
    //     window.current_url = document.URL //保留当前页面地址
    //     window.addEventListener('popstate', this.window_back) //监听popstate，检测到变化时，执行window_back
  }

  componentWillUnmount() {
    // 移除注册事件
    PubSub.unsubscribe("update3D");
    PubSub.unsubscribe("changeSize");
  }

  addHistory() {
    window.historyTimer && clearTimeout(window.historyTimer);
    window.historyTimer = setTimeout(() => {
      history = JSON.parse(JSON.stringify(history.slice(0, currentStep + 1)));
      history.push(JSON.parse(JSON.stringify(this.state)));
      currentStep++;
      this.update3DImg()
      this.handleNavigateState();
    }, 300)
  }


  handleNavigateState() {
    this.setState({
      undo: currentStep > 1,
      redo: currentStep < history.length - 1,
    });
  }

  /**
   * 预览图
   * @param url
   */
  updateImg = (url) => {
    this.setState({
      previewImg: url
    })
  }

  /**
   * 提交保存
   */
  async onSubmit() {
    let {
      modelId,
      prototype_uv,
      pack,
      innerList,
      layerList,
      id,
      previewImg
    } = this.state
    let params = {
      modelId,
      pack,
      innerList,
      layerList,
    }
    if (id) {
      params.id = id
    }
    if (previewImg) {
      let res = await onUploadMyProject(previewImg)
      params.prototype_uv = res.data.data?.img
    } else {
      params.prototype_uv = prototype_uv
    }
    return new Promise((resolve, reject) => {
      saveData(params).then(res => {
        if (res.data.projectId) {
          successSave()
          resolve(res.data.projectId)
        } else {
          reject(false)
        }
      })
    })
  }

  async onRenderPic() {
    let params = {
      modelId: this.state.modelId,
      srcImage: this.state.previewImg,
      arguJson: this.state.modelInfo,
      width: this.state.pack.size.width,
      height: this.state.pack.size.height,
      length: this.state.pack.size.length,
    }
    if (this.state.previewImg) {
      if (this.state.previewImg.indexOf('http') > -1) {
        params.srcImage = this.state.previewImg
      } else {
        let res = await onUploadMyProject(this.state.previewImg)
        params.srcImage = res.data.data?.img
      }
    } else {
      params.srcImage = this.state.prototype_uv
    }
    if (this.state.techImages.length > 0) {
      // 金属度贴图上传
      if (this.state.techImages[0].url) {
        if (this.state.techImages[0].url.indexOf('http') > -1) {
          params.srcImage2 = this.state.techImages[0].url
        } else {
          let res = await onUploadMyProject(this.state.techImages[0].url)
          params.srcImage2 = res.data.data?.img
        }
      }
      // 粗糙度贴图上传
      if (this.state.techImages[0].mixUrl) {
        if (this.state.techImages[0].mixUrl.indexOf('http') > -1) {
          params.srcImage3 = this.state.techImages[0].mixUrl
        } else {
          let res = await onUploadMyProject(this.state.techImages[0].mixUrl)
          params.srcImage3 = res.data.data?.img
        }
      }
    }
    this.setState({
      renderMask: true,
      renderLoading: true,
    })
    requestRender(params).then(res => {
      let renderId = res.data.renderId
      if (renderId) {
        this.checkRenderStatus(renderId)
      } else {
        this.setState({
          renderMask: false,
          renderLoading: false,
        })
        message.warning('生成失败')
      }
    });
  }

  /**
   * 轮训校验渲染状态
   */
  checkRenderStatus(id) {
    window.renderTimer && clearTimeout(window.renderTimer)
    window.renderTimer = setTimeout(() => {
      getRenderStatus(id).then(res => {
        if (res.data.status == 4) {
          this.setState({
            renderMask: false,
            renderLoading: false,
          })
          let url = res.data.fileUrl
          let currentTime = new Date();
          let filename = `${currentTime.getFullYear()}_${currentTime.getMonth()}_${currentTime.getDate()}_` +
            `${currentTime.getHours()}_${currentTime.getMinutes()}_${currentTime.getSeconds()}.png`;
          downloadImage(url, filename)
        } else if (res.data.status == 3) {
          this.setState({
            renderMask: false,
            renderLoading: false,
          })
          message.warning('生成失败')
        } else {
          this.checkRenderStatus(id)
        }
      })
    }, 10000)
  }
  /**
   * 加载数据
   */
  onLoadProject() {
    getProjectData({ id: this.state.id }).then(res => {
      let data = res.data.data.data
      data = JSON.parse(data)
      // 恢复id递增
      MAX_LAYER_ID = Math.max(...data.layerList.map(item => item.id)) || 1
      this.setState({
        ...data,
        previewImg: data.prototype_uv,
        selectingState: panelState.PACK,
      }, () => {
        this.addHistory()
      })
    })
  }
  /**
   * 加载模版数据
   */
  async onLoadModel(id) {
    getModelData({ modelId: id }).then(async (res) => {
      let modelData = res.data.data
      modelData.modelSize = JSON.parse(modelData.modelSize)
      modelData.size = {
        length: modelData.modelSize[0],
        width: modelData.modelSize[1],
        height: modelData.modelSize[2],
      }
      this.setState({
        modelId: id,
        // 包装
        pack: {
          type: 'pack',
          basic: {
            x: 0,
            y: 0,
            length: 120,
            width: 120,
            rotate: 0,
            isLock: false,
          },
          // 初始化尺寸
          size: modelData.size ? modelData.size : {
            width: 100,
            height: 100,
            length: 100
          },
          tech: {
            effectProcess: 0,
            advancedMaterial: undefined, // 材质
            materialProperty: {}, //材质贴图
            advancedMaterialThicknessAndWeight: null,
            postprintingProcess: 0, // 印刷后工艺
            postprintingPicture: '', // 印刷后工艺膜贴图
            printingProcess: 0,
          },
          // uv uvline图片地址
          svg: {
            uv: modelData.uv_url,
            uvLine: modelData.uvLine_url,
          },
          // glb模型地址
          glb: modelData.modelFileUrl,
          color: {
            color: '#ff0000',
            alpha: 100,
          },
        },
        // 内衬
        inner: {
          type: 'inner',
          level: undefined,
          texture: 0,
          textureData: {},
          basic: {
            x: 0,
            y: 0,
            length: 1,
            width: 1,
            rotate: 0,
            isLock: false
          },
          plane: {
            overallThickness: 100,
            grooveThickness: 40,
            direction: '上方',
            shape: '矩形'
          },
          tech: {
            effectProcess: 0,
            advancedMaterial: 0,
            postprintingProcess: 0,
            printingProcess: 0
          },
          color: {
            color: '#ff0000',
            alpha: 100
          },
          shape: {
            shape: "",
            list: [],
          }
        },
        innerList: [],
        layerList: [],
        selectingState: this.state.viewMode === '3D' ? panelState.INNER : panelState.PACK,
      }, () => {
        this.addHistory()
      })
    })
  }

  /**
   * 修改模版尺寸
   */
  async changeModelSize(res, size) {
    if (res.status == 200 && res.data.result === 0) {
      // let {width_mm, height_mm} = await getImageWH(res.data.uvSvg)
      let newPack = { ...this.state.pack }
      // newPack.basic.width = width_mm
      // newPack.basic.length = height_mm
      newPack.size = size
      newPack.svg = {
        uv: res.data.uvSvg,
        uvLine: res.data.uvLineSvg,
      }
      newPack.glb = res.data.glbFile
      newPack.glb3D = res.data.glbFile2
      this.setState({
        pack: newPack
      }, () => {
        setTimeout(() => {
          this.update3DImg()
        }, 300)
      })
    } else {
      message.warning('修改失败')
    }
  }
  /**
   * 退出提示
   * @param path
   */
  // showConfirm = (path) => {
  //   let hash = baseUrl + '/#/home'
  //   if (sessionStorage.getItem('__config_token')) {
  //     window.history.pushState(null, null, hash);
  //     window.location.reload();
  //     return
  //   }
  //   Modal.confirm({
  //     title: '您还未保存，真的要退出吗?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: '当前所做的修改可能会丢失。',
  //     okText: '保存',
  //     cancelType: 'danger',
  //     cancelText: '直接退出',
  //     onOk: () => {
  //       this.onSubmit().then(res => {
  //         if (res) {
  //           message.success('保存成功')
  //           window.history.pushState(null, null, hash);
  //           window.location.reload();
  //         }
  //       })
  //     },
  //     onCancel: () => {
  //       window.history.pushState(null, null, hash);
  //       window.location.reload();
  //     },
  //   });
  // };

  /**
   * 事件处理
   * @param e [type, payload]
   * type 事件类型
   * payload 参数
   */
  handleClick = (e) => {
    switch (e[0]) {
      case 'back': {
        this.showConfirm(e[1]);
        break;
      }
      case 'save': {
        this.onSubmit().then(res => {
          this.setState({
            id: res
          })
        });
        break;
      }
      case 'undo': {
        if (currentStep === 1) return;
        currentStep = currentStep - 1;
        this.setState(JSON.parse(JSON.stringify(history[currentStep])), () => {
          this.update3DImg()
        });

        this.handleNavigateState();
        break;
      }
      case 'redo': {
        if (currentStep === history.length - 1) return;
        currentStep = currentStep + 1;
        console.log('history:', history, 'Step', currentStep);
        this.setState(JSON.parse(JSON.stringify(history[currentStep])));
        this.handleNavigateState();
        break;
      }
      case 'changeModel': {
        this.setState({
          selectingState: panelState.MODEL,
        })
        break;
      }
      case 'cancelChangeModel': {
        this.setState({
          selectingState: panelState.PACK,
        })
        break;
      }
      case 'next': {
        // 下一步
        // 应获取到模型数据，拿到uv设置url先写死
        if (this.state.modelId && history.length > 0) {
          // 更改模版确认
          Modal.confirm({
            title: '您还未保存，确认更换模版?',
            icon: <ExclamationCircleOutlined />,
            content: '当前所做的修改将会丢失。',
            okText: '保存',
            okType: 'primary',
            cancelText: '不保存',
            cancelType: 'danger',
            onOk: () => {
              // 保存后再更改
              this.onSubmit().then(res => {
                history = []
                currentStep = 0
                let modelData = e[1]
                this.onLoadModel(modelData.id)
              })
            },
            onCancel: () => {
              // 不保存
              history = []
              currentStep = 0
              let modelData = e[1]
              this.onLoadModel(modelData.id)
            },
          });
        } else {
          // 第一次选择
          history = []
          currentStep = 0
          let modelData = e[1]
          this.onLoadModel(modelData.id)
        }
        break;
      }
      // 画布相关
      case 'changeViewMode': {
        if (e[1] === '2D') {
          this.setState({
            viewMode: e[1],
            selectingState: panelState.PACK,
          });
        } else {
          this.setState({
            viewMode: e[1],
            selectingState: panelState.INNER,
          });
          notification.info({
            message: '温馨提示',
            description: '3D模型资源加载较大，请耐心等待...',
            duration: 10000
          })
        }
        break;
      }
      // 图层相关
      case 'changeLayer': {
        this.setState({
          layerList: e[1],
        });
        break;
      }
      case 'changeSelectedLayer': {
        console.log('changeSelectedLayer');
        this.setState({
          selectedLayer: e[1],
        });
        if (e[1] || e[1] === 0) {
          switch (this.state.layerList[e[1]].type) {
            case 'svg': {
              this.setState({
                selectingState: panelState.SVG,
              });
              break;
            }
            case 'pic': {
              this.setState({
                selectingState: panelState.PIC,
              });
              break;
            }
            case 'text': {
              this.setState({
                selectingState: panelState.TEXT,
              });
              break;
            }
            default: { }
          }
        } else {
          if (this.state.modelId) {
            this.setState({
              selectingState: panelState.PACK,
            });
          }
        }
        break;
      }
      case 'exportPic': {
        html2canvas(document.querySelector(e[1] === '刀板图' ? '#canvasContainer' : '#container'), { scale: 4, dpi: 300 }).then(canvas => {
          if (e[1] === '刀板图') {
            let currentTime = new Date();
            let pdfFileName = `${currentTime.getFullYear()}_${currentTime.getMonth()}_${currentTime.getDate()}_` +
              `${currentTime.getHours()}_${currentTime.getMinutes()}_${currentTime.getSeconds()}`;
            // 创建PDF文档
            const pdf = new jspdf.jsPDF();

            // 将canvas转换为图片
            const imgData = canvas.toDataURL('image/png');

            // 将图片添加到PDF文档中
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

            // 保存PDF文件
            pdf.save(pdfFileName + '.pdf');
          } else {
            let url = canvas.toDataURL();
            console.log(url);
            let a = document.createElement('a');
            let currentTime = new Date();
            let filename = `${currentTime.getFullYear()}_${currentTime.getMonth()}_${currentTime.getDate()}_` +
              `${currentTime.getHours()}_${currentTime.getMinutes()}_${currentTime.getSeconds()}.png`;
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            a.click();
          }
        });
        console.log('导出' + e[1]);
        break;
      }
      case 'change3D': {
        let newinfo3d = this.state.info3d;
        newinfo3d.position.x = e[1];
        newinfo3d.position.y = e[2];
        newinfo3d.position.z = e[3];
        newinfo3d.rotation.x = e[4];
        newinfo3d.rotation.y = e[5];
        newinfo3d.rotation.z = e[6];
        newinfo3d.scale.x = e[7];
        newinfo3d.scale.y = e[8];
        newinfo3d.scale.z = e[9];
        console.log(newinfo3d)
        this.setState({
          info3d: newinfo3d,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeCamPos': {
        // let newinfo3d = this.state.info3d;
        let newmodelInfo = this.state.modelInfo
        newmodelInfo.campos_data = [e[1], e[2], e[3]];
        newmodelInfo.camrot_data = [e[4], e[5], e[6]];
        console.log(newmodelInfo)
        console.log(222)
        this.setState({
          modelInfo: newmodelInfo,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeModelInfo': {
        let newmodelInfo = this.state.modelInfo
        console.log(newmodelInfo.sca_data[2])
        // newmodelInfo.sca_data=[e[1],e[2],e[3]];
        // newmodelInfo.rot_data=[e[4],e[5],e[6]];
        // newmodelInfo.pos_data=[e[7],e[8],e[9]]
        newmodelInfo.sca_data[0] = e[1];
        newmodelInfo.sca_data[1] = e[2];
        newmodelInfo.sca_data[2] = e[3];
        newmodelInfo.rot_data[0] = e[4];
        newmodelInfo.rot_data[1] = e[5];
        newmodelInfo.rot_data[2] = e[6];
        newmodelInfo.pos_data[0] = e[7];
        newmodelInfo.pos_data[1] = e[8];
        newmodelInfo.pos_data[2] = e[9];
        console.log('changeModelInfo')
        this.setState({
          modelInfo: newmodelInfo,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'testFunction': {
        console.log('测试')
        let newmodelInfo = this.state.modelInfo
        newmodelInfo.sca_data = [e[1], e[2], e[3]];
        newmodelInfo.rot_data = [e[4], e[5], e[6]];
        newmodelInfo.pos_data = [e[7], e[8], e[9]]
        console.log('changeModelInfo')
        this.setState({
          modelInfo: newmodelInfo,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'renderPics': {
        if (this.state.renderLoading) {
          message.info('正在渲染中，请稍后..')
        } else {
          this.onRenderPic()
        }
      }
      case 'changeCameraFov': {
        let newmodelInfo = this.state.modelInfo
        let newinfo3d = this.state.info3d;
        console.log(e[1], 'changeCameraFov')

        newinfo3d.camera.cameraId = e[1] ? e[1].value : newinfo3d.camera.cameraId;
        newinfo3d.camera.fov = e[1] ? e[1].camera.fov : newinfo3d.camera.fov;
        newmodelInfo.lens_data = e[1] ? e[1].camera.lens_data : newmodelInfo.lens_data;
        this.setState({
          info3d: newinfo3d,
          modelInfo: newmodelInfo
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeBasic_x': {
        //console.log(e[1],e[2])
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.basic.x = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.basic.x = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            if (this.state.selectedLayer || this.state.selectedLayer === 0) {
              let newLayerList = this.state.layerList;
              if (newLayerList[this.state.selectedLayer]) {
                newLayerList[this.state.selectedLayer].basic.x = e[2];
                this.setState({
                  layerList: newLayerList,
                }, () => {
                  this.addHistory();
                });
              }
            }
            break;
          }
        }
        break;
      }
      case 'changeBasic_y': {
        //console.log(e[1],e[2])
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.basic.y = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.basic.y = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            if (this.state.selectedLayer || this.state.selectedLayer === 0) {
              let newLayerList = this.state.layerList;
              newLayerList[this.state.selectedLayer].basic.y = e[2];
              this.setState({
                layerList: newLayerList,
              }, () => {
                this.addHistory();
              });
            }
            break;
          }
        }
        break;
      }
      case 'changeBasic_rotate': {
        //console.log(e[1],e[2])
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.basic.rotate = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.basic.rotate = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].basic.rotate = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeBasic_scale': {
        //console.log(e[1],e[2])
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.basic.scale = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.basic.scale = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].basic.scale = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeBasic_lock': {
        //console.log(e[1],'switchlock')
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.basic.isLock = !newInner.basic.isLock;
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.basic.isLock = !newPack.basic.isLock;
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].basic.isLock = !newLayerList[this.state.selectedLayer].basic.isLock;
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeBasic_width': {
        //console.log(e[1],e[2])
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            if (newInner.basic.isLock ===
              true) newInner.basic.length = this.state.inner.basic.length *
                e[2] / this.state.inner.basic.width;
            newInner.basic.width = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            if (newPack.basic.isLock ===
              true) newPack.basic.length = this.state.pack.basic.length *
                (e[2] / this.state.pack.basic.width);
            newPack.basic.width = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            if (newLayerList[this.state.selectedLayer].basic.isLock === true)
              newLayerList[this.state.selectedLayer].basic.length = this.state.layerList[this.state.selectedLayer].basic.length *
                e[2] /
                this.state.layerList[this.state.selectedLayer].basic.width;
            newLayerList[this.state.selectedLayer].basic.width = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeBasic_length': {
        //console.log(e[1],e[2])
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            if (newInner.basic.isLock ===
              true) newInner.basic.width = this.state.inner.basic.width *
                e[2] / this.state.inner.basic.length;
            newInner.basic.length = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            if (newPack.basic.isLock ===
              true) newPack.basic.width = this.state.pack.basic.width *
                (e[2] / this.state.pack.basic.length);
            newPack.basic.length = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            if (newLayerList[this.state.selectedLayer].basic.isLock === true)
              newLayerList[this.state.selectedLayer].basic.width = this.state.layerList[this.state.selectedLayer].basic.width *
                e[2] /
                this.state.layerList[this.state.selectedLayer].basic.length;
            newLayerList[this.state.selectedLayer].basic.length = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changePlane_ot': {
        let newInnerList = this.state.innerList;
        newInnerList[e[2]].plane.overallThickness = e[1];
        this.setState({
          innerList: [...newInnerList],
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'setActive': {
        this.setState({
          active: !this.state.active
        }, () => {
          // console.log(1111)
          this.addHistory();
        })
        this.forceUpdate();
        break;
      }
      case 'addNewShape': {
        console.log(e)
        let newInner = JSON.parse(JSON.stringify(this.state.inner));
        let index = 1
        let lastInner = this.state.innerList[this.state.innerList.length - 1]
        if (lastInner) {
          let nowIndex = lastInner.name.split('内衬')[1]
          index = Number(nowIndex) ? Number(nowIndex) + 1 : 1
        }
        newInner.name = '内衬' + index
        newInner.shape.shape = e[1].target.getAttribute("data-shape")
        console.log(newInner.shape.shape)
        var inner_histories = this.state.innerList
        inner_histories.push(newInner)
        this.setState({
          innerList: inner_histories
        }, () => {
          this.addHistory();
        })
        this.forceUpdate();
        break;
      };
      case 'removeInner': {
        let index = e[1]
        let newInnerList = this.state.innerList
        newInnerList.splice(index, 1)
        this.setState({
          innerList: newInnerList
        })
      }
      case 'setVisible': {

        this.setState({
          isVisible: !this.state.isVisible
        }, () => {
          this.addHistory();
        })
        break;

      };
      case 'changePlane_gt': {
        let newInnerList = this.state.innerList;
        newInnerList[e[2]].plane.grooveThickness = e[1];
        this.setState({
          innerList: newInnerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changePlane_dr': {
        let newInnerList = this.state.innerList;
        newInnerList[e[2]].plane.direction = e[1];
        this.setState({
          innerList: newInnerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTrans': {
        let newTrans = this.state.transList;
        newTrans = e[1];
        console.log(newTrans)
        this.setState({
          transList: newTrans
        }, () => {
          this.addHistory();
        })
        break;
      };
      case 'changeTrans1': {
        let newTrans = this.state.transList;
        newTrans = 'translate';
        console.log(newTrans)
        this.setState({
          transList: newTrans
        }, () => {
          this.addHistory();
        })
        break;
      };
      case 'changeTrans2': {
        let newTrans = this.state.transList;
        newTrans = 'rotate';
        console.log(newTrans)
        this.setState({
          transList: newTrans
        }, () => {
          this.addHistory();
        })
        break;
      };
      case 'changeTrans3': {
        let newTrans = this.state.transList;
        newTrans = 'scale';
        console.log(newTrans)
        this.setState({
          transList: newTrans
        }, () => {
          this.addHistory();
        })
        break;
      };
      case 'changePlane_shape': {
        let newInnerList = this.state.innerList;
        newInnerList[e[2]].plane.shape = e[1];
        this.setState({
          innerList: newInnerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changePlane_texture': {
        let newInnerList = this.state.innerList;
        newInnerList[e[2]].texture = e[1];
        getMaterialDetail({
          typeCode: 9,
          valueCode: e[1]
        }).then(res => {
          let poster = res.data.data[0]
          poster.property = JSON.parse(poster.property)

          newInnerList[e[2]].textureData = poster.property
          this.setState({
            innerList: newInnerList,
          }, () => {
            this.addHistory();
          });

        })
        break;
      }
      case 'changePlane_level': {
        let newInnerList = this.state.innerList;
        newInnerList[e[2]].level = e[1];
        this.setState({
          innerList: newInnerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTEXT_ft': {
        let newLayerList = this.state.layerList;
        newLayerList[this.state.selectedLayer].text.fontFamily = e[1];
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTEXT_fs': {
        let newLayerList = this.state.layerList;
        newLayerList[this.state.selectedLayer].text.fontSize = e[1];
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTEXT_bold': {
        let newLayerList = this.state.layerList;
        newLayerList[this.state.selectedLayer].text.isBold = !newLayerList[this.state.selectedLayer].text.isBold;
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTEXT_ul': {
        let newLayerList = this.state.layerList;
        newLayerList[this.state.selectedLayer].text.isUnderlined = !newLayerList[this.state.selectedLayer].text.isUnderlined;
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTEXT_it': {
        let newLayerList = this.state.layerList;
        newLayerList[this.state.selectedLayer].text.isItalic = !newLayerList[this.state.selectedLayer].text.isItalic;
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'changeTech_ep': {
        switch (e[1]) {
          case 'pack': {
            let newPack = this.state.pack;
            newPack.tech.effectProcess = e[2].value;
            newPack.tech.effectProcessPicture = e[2].url;
            // 素材自带烫金图
            newPack.tech.materialMetalness = e[2].materialMetalness;
            newPack.tech.effectData = e[2].property;
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].tech.effectProcess = e[2].value;
            newLayerList[this.state.selectedLayer].tech.effectProcessPicture = e[2].url;
            newLayerList[this.state.selectedLayer].tech.materialMetalness = e[2].materialMetalness;
            newLayerList[this.state.selectedLayer].tech.effectData = e[2].property;
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeTech_adm': {
        switch (e[1]) {
          case 'pack': {
            let newPack = this.state.pack;
            newPack.tech.advancedMaterial = e[2];
            newPack.tech.materialProperty = e[3].property;
            // 材质选择后重制
            // 效果处理工艺
            newPack.tech.effectProcess = 0
            newPack.tech.effectProcessPicture = ''
            // 印刷后工艺
            newPack.tech.postprintingProcess = 0
            newPack.tech.postprintingPicture = ''
            newPack.tech.materialRoughness = ''
            // 厚度克重
            newPack.tech.advancedMaterialThicknessAndWeight = 0
            // 固定底图的图
            newPack.tech.lockUrl = e[3].lockUrl
            let newLayerList = this.state.layerList;
            newLayerList = newLayerList.map(layer => {
              layer.tech.effectProcess = 0
              layer.tech.effectProcessPicture = ''
              layer.tech.materialMetalness = ''
              return layer
            })
            this.setState({
              pack: newPack,
              layerList: newLayerList
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].tech.advancedMaterial = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeTech_adm_t_w': {
        switch (e[1]) {
          case 'pack': {
            let newPack = this.state.pack;
            newPack.tech.advancedMaterialThicknessAndWeight = e[2];
            console.log(newPack)
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].tech.advancedMaterial = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeTech_pop': {
        switch (e[1]) {
          case 'pack': {

            let newPack = this.state.pack;
            newPack.tech.postprintingProcess = e[2].value;
            // 粗糙度贴图
            newPack.tech.postprintingPicture = e[2].url;
            // 材质自带的粗糙度贴图
            newPack.tech.materialRoughness = e[2].materialRoughness;
            newPack.tech.postprintingData = e[2].property
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].tech.postprintingProcess = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeTech_prp': {
        switch (e[1]) {
          case 'pack': {
            let newPack = this.state.pack;
            newPack.tech.printingProcess = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].tech.printingProcess = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeColor_alpha': {
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.color.alpha = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.color.alpha = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].color.alpha = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'changeColor_color': {
        switch (e[1]) {
          case 'inner': {
            let newInner = this.state.inner;
            newInner.color.color = e[2];
            this.setState({
              inner: newInner,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'pack': {
            let newPack = this.state.pack;
            newPack.color.color = e[2];
            this.setState({
              pack: newPack,
            }, () => {
              this.addHistory();
            });
            break;
          }
          case 'svg': {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].colorList = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
          default: {
            let newLayerList = this.state.layerList;
            newLayerList[this.state.selectedLayer].color.color = e[2];
            this.setState({
              layerList: newLayerList,
            }, () => {
              this.addHistory();
            });
            break;
          }
        }
        break;
      }
      case 'addText': {
        console.log('addText');
        let newLayerList = this.state.layerList;
        newLayerList.unshift(
          {
            id: ++MAX_LAYER_ID,
            name: '点此修改文本',
            type: 'text',
            isLocked: false,
            isVisible: true,
            basic: {
              x: this.state.pack.basic.width / 2,
              y: this.state.pack.basic.length / 2,
              length: 0,
              width: 0,
              rotate: 0,
              isLock: false,
            },
            text: {
              fontFamily: undefined,
              fontSize: 12,
              isBold: false,
              isUnderlined: false,
              isItalic: false,
            },
            tech: {
              effectProcess: 0,
              advancedMaterial: 0,
              postprintingProcess: 0,
              printingProcess: 0,
            },
            color: {
              color: '#000000',
              alpha: 100,
            },
          },
        );
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'modifyText': {
        console.log('modifyText', e);
        let newLayerList = this.state.layerList;
        newLayerList[e[1]].name = e[2];
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'addPic': {
        console.log('addPic');
        console.log(e);
        let img = e[1]
        // this.setState({nowurl:item})
        let newLayerList = this.state.layerList;
        if (!img.name) {
          let arr = img.imgUrl.split('/')
          img.name = arr[arr.length - 1]
        }

        getImageWH(img.imgUrl).then(res => {
          console.log(res);
          let imgH = 20, imgW = res.width_mm * 20 / res.height_mm
          newLayerList.unshift(
            {
              id: ++MAX_LAYER_ID,
              name: img.name,
              type: 'pic',
              isLocked: false,
              isVisible: true,
              basic: {
                x: this.state.pack.basic.width / 2 - imgW / 2,
                y: this.state.pack.basic.length / 2 - imgH / 2,
                length: imgH,
                width: imgW,
                rotate: 0,
                isLock: false,
              },
              text: {
                fontFamily: '宋体',
                fontSize: 12,
                isBold: false,
                isUnderlined: false,
                isItalic: false,
              },
              tech: {
                effectProcess: 0,
                advancedMaterial: 0,
                postprintingProcess: 0,
                printingProcess: 0,
              },
              color: {
                color: '#ff0000',
                alpha: 100,
              },
              url: img.imgUrl,
            },
          );
          this.setState({
            layerList: newLayerList,
          }, () => {
            this.addHistory();
          });
        })
        break;
      }
      case 'modifyPic': {
        console.log('modifyPic');
        console.log(e);
        // this.setState({nowurl:item})
        let newLayerList = this.state.layerList;
        newLayerList[this.state.selectedLayer] = e[1]
        this.setState({
          layerList: newLayerList,
        }, () => {
          this.addHistory();
        });
        break;
      }
      case 'addSvg': {
        // 添加图标
        let img = { ...e[1] }
        if (isDev && img.imgUrl.indexOf('http') > -1) {
          img.imgUrl = img.imgUrl.slice(26)
        }
        if (!img.name) {
          let arr = img.imgUrl.split('/')
          img.name = arr[arr.length - 1]
        }
        // this.setState({nowurl:item})
        let newLayerList = this.state.layerList;
        axios.get(img.imgUrl, {
          contentType: 'blob'
        }).then((data) => {
          let colorList = {}
          let colors = data.data.match(/#(?:[0-9a-fA-F]{6})/g) || []
          colors.length > 0 && colors.map(color => {
            colorList[color] = color
          })
          newLayerList.unshift(
            {
              id: ++MAX_LAYER_ID,
              name: img.name,
              type: 'svg',
              isLocked: false,
              isVisible: true,
              basic: {
                x: this.state.pack.basic.width / 2 - 20 / 2,
                y: this.state.pack.basic.length / 2 - 20 / 2,
                length: 20,
                width: 20,
                rotate: 0,
                isLock: false,
              },
              text: {
                fontFamily: '宋体',
                fontSize: 12,
                isBold: false,
                isUnderlined: false,
                isItalic: false,
              },
              tech: {
                effectProcess: 0,
                advancedMaterial: 0,
                postprintingProcess: 0,
                printingProcess: 0,
              },
              color: {
                color: '#ff0000',
                alpha: 100,
              },
              colorList: colorList,
              url: img.imgUrl,
            },
          );
          this.setState({
            layerList: newLayerList,
          }, () => {
            this.addHistory();
          });
        });
        break;
      }
      case 'updateLevel': {
        console.log('层级更新', e);
        let type = e[1];
        this.updateLevel(type);
        break;
      }
      // case 'renderImage':{
      //   let vector = new THREE.Vector3();
      //                  vectorEvent.object.getWorldPosition(vector);
      //                  console.log(vector);
      // }
      case 'copy': {
        console.log('copy', e);
        this.copyLayer();
        break;
      }
      case 'delete': {
        console.log('delete', e);
        this.deleteLayer();
        break;
      }
      case 'change_3d_info': {
        let new3dInfo = e[1]
        this.setState({
          info3d: new3dInfo
        })
        break;
      }
      case 'change_pack_scale': {
        let pack = this.state.pack
        pack.basic.scale = e[1]
        this.setState({
          pack
        })
      }
      default: {
        console.log('no event');
      }
    }
  };
  getDatas = (msg) => {
    //把子组件传递过来的值赋给this.state中的属性
    this.setState({
      shape: msg
    });
  }
  /**
   * 右键图层层级更新
   * @param type up/上移 down/下移 top/置顶 bottom/置底
   *
   */
  updateLevel(type) {
    let layerList = this.state.layerList;
    let index = this.state.selectedLayer;
    if (type === 'up') {
      // 上移
      [layerList[index], layerList[index - 1]] = [
        layerList[index - 1],
        layerList[index]];
      index = index - 1;
    } else if (type === 'down') {
      //下移
      [layerList[index], layerList[index + 1]] = [
        layerList[index + 1],
        layerList[index]];
      index = index + 1;

    } else if (type === 'top') {
      //置顶
      let cur = layerList.splice(index, 1);
      layerList.unshift(...cur);
      index = 0;

    } else if (type === 'bottom') {
      //至底
      let cur = layerList.splice(index, 1);
      layerList.push(...cur);
      index = layerList.length - 1;

    }

    this.setState({
      layerList: layerList,
      selectedLayer: index,
    }, () => {
      this.addHistory();
    });
  }

  clickBlank() {
    if (this.state.selectingState === panelState.MODEL) return;
    if (this.state.viewMode === '2D' && this.state.selectingState !== panelState.PACK) {
      this.setState({
        selectingState: panelState.PACK,
        selectedLayer: null,
      });
    } else if (this.state.viewMode === '3D' && this.state.selectingState !== panelState.INNER) {
      this.setState({
        selectingState: panelState.INNER,
        selectedLayer: null,
      });
    }

  }


  /**
   * 复制图层
   */
  copyLayer() {
    let layer = this.state.layerList[this.state.selectedLayer]
    let copyLayer = JSON.parse(JSON.stringify(layer))
    copyLayer.id = ++MAX_LAYER_ID
    let layerList = [...this.state.layerList, copyLayer]
    this.setState({
      layerList: layerList
    }, () => {
      this.addHistory();
    })
  }

  /**
   * 删除图层
   */
  deleteLayer() {
    let layerList = this.state.layerList.filter((layer, index) => index !== this.state.selectedLayer)
    this.setState({
      selectedLayer: null,
      selectingState: panelState.PACK,
      layerList: layerList
    }, () => {
      this.addHistory();
    })
  }

  /**
   * 更新3D贴图
   * @constructor
   */
  update3DImg = async () => {
    console.log('update3d')
    if (this.state.viewMode === '3D') return
    window.updateTimeout && clearTimeout(window.updateTimeout);
    window.updateTimeout = setTimeout(async () => {
      let dom = document.querySelector('#container')
      if (dom) {
        let canvas = await html2canvas(dom, { scale: 4, dpi: 300 })
        let url = canvas.toDataURL();
        let techImages = await this.getTechImagesNew()
        console.table([
          {
            name: '基础贴图',
            value: url
          },
          {
            name: '金属度贴图',
            value: techImages[0] ? techImages[0].url : ''
          },
          {
            name: '粗糙度贴图',
            value: techImages[0] ? techImages[0].mixUrl : ''
          },
        ])
        if (url !== 'data:,') {
          this.setState({
            previewImg: url,
            techImages: techImages
          });
        }
      } else {
        this.update3DImg()
      }
    }, 500)
  };

  /**
   * 获取效果处理工艺图片--金属度贴图
   * 由素材黑白图 + 材质自带金属度贴图 + 工艺金属度贴图混合而成
   */
  async getTechImagesNew() {
    let techImages = [], // 烫金图层
      metalnessBg = '', // 金属度贴图底图
      roughnessBg = '' // 粗糙度贴图底图
    // 是否存在材质金属度和粗糙度贴图
    if (this.state.pack.tech.advancedMaterial && this.state.pack.tech.materialProperty['金属度']) {
      metalnessBg = this.state.pack.tech.materialProperty['金属度']
      roughnessBg = this.state.pack.tech.materialProperty['糙度']
    }
    // 是否存在印刷后工艺金属度和粗糙度贴图
    if (this.state.pack.tech.postprintingProcess) {
      metalnessBg = this.state.pack.tech.postprintingData['金属度']
      roughnessBg = this.state.pack.tech.postprintingData['糙度']
    }

    // 获取有效果处理工艺的图层
    let techLayers = this.state.layerList.filter(item => {
      return !!item.tech?.effectProcess
    })

    // 存在效果处理工艺
    if (techLayers.length > 0) {
      let techLayerBasicImages = []
      let dom = document.querySelector('#container')
      let width = 0, height = 0
      // 循环获得所有效果处理工艺的黑白图
      for (let i = 0; i < techLayers.length; i++) {
        let canvasPack = await html2canvas(dom, {
          scale: 4,
          dpi: 300,
          ignoreElements: (element) => {
            return element.id === 'canvasContainer' || (element.dataset.node === 'layer-item' && element.dataset.layerId != techLayers[i].id)
          }
        })

        if (!(width && height)) {
          width = canvasPack.width
          height = canvasPack.height
        }
        let length = canvasPack.width * canvasPack.height
        let ctx = canvasPack.getContext("2d", { willReadFrequently: true })
        let basicImage // 基础金属度贴图-黑白图
        basicImage = ctx.getImageData(0, 0, canvasPack.width, canvasPack.height);
        for (let j = 0; j < length * 4; j += 4) {
          let myRed = basicImage.data[j];
          let myGreen = basicImage.data[j + 1];
          let myBlue = basicImage.data[j + 2];
          let myGray = (myRed === 255 && myBlue === 255 && myGreen === 255) ? 0 : 255
          basicImage.data[j] = myGray;
          basicImage.data[j + 1] = myGray;
          basicImage.data[j + 2] = myGray;
          basicImage.data[j + 3] = 255;
        }
        let basicData = {
          effectData: techLayers[i].tech.effectData,
          imageData: basicImage
        }
        techLayerBasicImages.push(basicData)
      }
      let metalness = await getMetalnessPic(width, height, techLayerBasicImages, metalnessBg)

      let roughness = await getRoughnessPic(width, height, techLayerBasicImages, metalnessBg)

      let techObj = {
        url: metalness,
        mixUrl: roughness
      }
      techImages.push(techObj)
    } else {
      // 无效果处理工艺图层，直接返回底图
      let techObj = {
        url: metalnessBg,
        mixUrl: roughnessBg
      }
      techImages.push(techObj)
    }
    console.log(techImages)
    return techImages
  }
  async getTechImages() {
    let dom = document.querySelector('#container')
    let techImages = [], // 烫金图层
      techMaterialMetalness = '', // 材质金属度贴图
      techMaterialRoughness = '' // 材质粗糙度贴图
    // 是否存在材质金属度贴图
    if (this.state.pack.tech.advancedMaterial && this.state.pack.tech.materialProperty['金属度']) {
      techMaterialMetalness = this.state.pack.tech.materialProperty['金属度']
      techMaterialRoughness = this.state.pack.tech.materialProperty['糙度']
    }
    // 1、获取有效果处理工艺的图层 2、效果处理工艺的key
    let techLayers = this.state.layerList.filter(item => {
      return !!item.tech?.effectProcess
    })
    // key去重
    techLayers = Array.from(new Set(techLayers.map(item => item.tech.effectProcess)))
    if (techLayers.length > 0) {
      // 存在效果处理工艺
      for (let i = 0; i < techLayers.length; i++) {
        let canvasPack = await html2canvas(dom, {
          scale: 4,
          dpi: 300,
          ignoreElements: (element) => {
            return element.id === 'canvasContainer' || (element.dataset.node === 'layer-item' && element.dataset.tech != techLayers[i])
          }
        })
        let length = canvasPack.width * canvasPack.height
        let ctx = canvasPack.getContext("2d", { willReadFrequently: true })
        let basicImage // 基础金属度贴图-黑白图
        basicImage = ctx.getImageData(0, 0, canvasPack.width, canvasPack.height);
        for (let j = 0; j < length * 4; j += 4) {
          let myRed = basicImage.data[j];
          let myGreen = basicImage.data[j + 1];
          let myBlue = basicImage.data[j + 2];
          let myGray = (myRed === 255 && myBlue === 255 && myGreen === 255) ? 0 : 255
          basicImage.data[j] = myGray;
          basicImage.data[j + 1] = myGray;
          basicImage.data[j + 2] = myGray;
          basicImage.data[j + 3] = 255;
        }
        ctx = null
        let techObj = {
          url: '',
          mixUrl: ''
        }
        if (this.state.pack.tech.advancedMaterial) {
          // 存在材质
          if (this.state.pack.tech.postprintingProcess) {
            // 有哑膜
            techObj.url = await this.getTechMetalnessImages(canvasPack, basicImage, techMaterialMetalness)
            techObj.mixUrl = await this.getTechMixImages(canvasPack, basicImage, this.state.pack.tech.postprintingPicture)
          } else {
            // 无哑膜
            techObj.url = await this.getTechMetalnessImages(canvasPack, basicImage, techMaterialMetalness)
            techObj.mixUrl = await this.getTechMixImages(canvasPack, basicImage, techMaterialRoughness)
          }
        } else {
          // 无材质
          if (this.state.pack.tech.postprintingProcess) {
            // 有哑膜
            techObj.url = await this.getTechMetalnessImages(canvasPack, basicImage)
            techObj.mixUrl = await this.getTechMixImages(canvasPack, basicImage, this.state.pack.tech.postprintingPicture)
          } else {
            // 无哑膜
            techObj.url = await this.getTechMetalnessImages(canvasPack, basicImage)
            techObj.mixUrl = ''
          }
        }
        // 印刷后工艺，覆膜
        // pack.tech.postprintingProcess
        techImages.push(techObj);
      }
    } else {
      // 无烫金图层
      if (this.state.pack.tech.advancedMaterial) {
        // 存在材质
        let techObj = {
          url: this.state.pack.tech.materialProperty['金属度'],
          mixUrl: ''
        }
        if (this.state.pack.tech.postprintingProcess) {
          // 有哑膜
          techObj.mixUrl = this.state.pack.tech.postprintingPicture
        } else {
          // 无哑膜
          techObj.mixUrl = this.state.pack.tech.materialProperty['糙度']
        }
        techImages.push(techObj)
      } else {
        // 无材质 无烫金
        if (this.state.pack.tech.postprintingProcess) {
          // 有哑膜
          let techObj = {
            url: '',
            mixUrl: this.state.pack.tech.postprintingPicture
          }
          techImages.push(techObj)
        } else {
          // 无哑膜
        }
      }
    }
    console.log(techImages)
    return techImages
  }

  /**
   *  获取金属度贴图
   *  金属度贴图（烫金部分白色，其余部分黑色/材质金属度贴图）
   * @param canvas 画布
   * @param basicImageData 基础黑白图数据
   * @param techMaterialMetalness 材质自带金属度贴图
   * @returns {Promise<void>}
   */
  async getTechMetalnessImages(canvas, basicImageData, techMaterialMetalness) {
    return new Promise((resolve, reject) => {
      let ctx = canvas.getContext('2d', { willReadFrequently: true })
      let resultData = new ImageData(canvas.width, canvas.height)
      if (techMaterialMetalness) {
        //创建image对象
        let imgObj = new Image();
        imgObj.src = techMaterialMetalness
        //待图片加载完后，将其显示在canvas上
        imgObj.onload = function () {
          // 创建CanvasPattern对象
          // 将新创建的CanvasPattern对象赋值给fillStyle属性
          canvas.width = canvas.width
          canvas.height = canvas.height
          ctx.fillStyle = ctx.createPattern(imgObj, 'repeat');
          // 清除画布
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          // 绘制图案
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          let materialMetalnessData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          let length = canvas.width * canvas.height
          for (let i = 0; i < length * 4; i += 4) {
            // 颜色混合
            let r1 = basicImageData.data[i], r2 = materialMetalnessData.data[i],
              g1 = basicImageData.data[i + 1], g2 = materialMetalnessData.data[i + 1],
              b1 = basicImageData.data[i + 2], b2 = materialMetalnessData.data[i + 2]
            let isBlack = ((r1 === 0 && g1 === 0 && b1 === 0));
            if (isBlack) {
              resultData.data[i] = r2
              resultData.data[i + 1] = g2
              resultData.data[i + 2] = b2
              resultData.data[i + 3] = 255
            } else {
              resultData.data[i] = 255
              resultData.data[i + 1] = 255
              resultData.data[i + 2] = 255
              resultData.data[i + 3] = 255
            }
          }
          ctx.putImageData(resultData, 0, 0)
          let url = canvas.toDataURL()
          resolve(url)
          ctx = null
        }

      } else {
        // 得到基础黑白图
        ctx.putImageData(basicImageData, 0, 0)
        let url = canvas.toDataURL()
        resolve(url)
        ctx = null
      }
    })
  }

  /**
   *
   * 获取印刷后工艺--覆膜-粗糙度贴图
   * 由素材白黑图 + 材质自带粗糙度贴图 + 工艺粗糙度贴图混合而成
   * @param canvas 画布
   * @param basicImageData 烫金金属度图
   * @param mixImageUrl 混合图 有哑膜取哑膜粗糙度贴图，没有取材质粗糙度贴图
   * @returns {Promise<unknown>}
   */
  async getTechMixImages(canvas, basicImageData, mixImageUrl) {
    function loadImage(url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = function () {
          resolve(img)
        }
        img.src = url
      })
    }
    // 工艺膜图
    let postprintingImage
    if (mixImageUrl) {
      postprintingImage = await loadImage(mixImageUrl)
    }
    return new Promise((resolve, reject) => {
      let ctx = canvas.getContext('2d', { willReadFrequently: true })
      // 覆膜图存在
      if (mixImageUrl) {
        let postprintingImageData
        canvas.width = canvas.width
        canvas.height = canvas.height
        ctx.fillStyle = ctx.createPattern(postprintingImage, 'repeat');
        // 清除画布
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // 绘制图案
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        postprintingImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        let length = canvas.width * canvas.height
        let resultData = new ImageData(canvas.width, canvas.height)
        for (let j = 0; j < length * 4; j += 4) {
          // 黑白图编程白黑图，目标区为黑色
          let r1 = basicImageData.data[j], g1 = basicImageData.data[j + 1], b1 = basicImageData.data[j + 2],
            // 工艺粗糙图rgb
            r2 = postprintingImageData.data[j], g2 = postprintingImageData.data[j + 1], b2 = postprintingImageData.data[j + 2]
          // 混合逻辑 烫金部分黑色，其余用工艺粗糙度颜色替换
          let resultPoint = [255, 255, 255, 255]
          if ((r1 === 255 && g1 === 255 && b1 === 255)) {
            // 烫金部分是黑色
            resultPoint = [0, 0, 0, 255]
          } else {
            resultPoint = [r2, g2, b2, 255]
          }
          resultData.data[j] = resultPoint[0]
          resultData.data[j + 1] = resultPoint[1]
          resultData.data[j + 2] = resultPoint[2]
          resultData.data[j + 3] = 255
        }
        ctx.putImageData(resultData, 0, 0)
        let url = canvas.toDataURL();
        resolve(url)
        ctx = null
      } else {
        // 没有粗糙度
        resolve('')
        ctx = null
      }
    })
  }

  handleCloseMask = () => {
    this.setState({
      renderMask: false
    })
  }

  // onAddNewImgMaterial = (materials_src) => {
  //     this.child.addNewImgMaterial(materials_src);
  //   }

  render() {
    return (
      <div className={styles.design}>
        {this.state.renderMask && <div className={styles.renderMask} id="mask">
          <CloseCircleOutlined className={styles.maskClose} onClick={this.handleCloseMask} />
          <Spin style={{ fontSize: 20, lineHeight: '40px', color: '#fff' }} indicator={antIcon}
            tip="正在渲染，请稍后。。。" />
        </div>}
        <div className={styles.blank} onClick={this.clickBlank}></div>
        <NavigateBar title={this.state.fileTitle} state="loggedIn_2D"
          onClick={this.handleClick} undo={this.state.undo}
          redo={this.state.redo}></NavigateBar>
        <DimensionControl selectingState={this.state.selectingState}
          viewMode={this.state.viewMode}
          func={this.handleClick}></DimensionControl>
        <LeftPanel selectedLayer={this.state.selectedLayer}
          panelState={this.state.selectingState}
          modelId={this.state.modelId}
          onClick={this.handleClick}
          layerList={this.state.layerList}
          onAddNewImgMaterial={this.onAddNewImgMaterial}></LeftPanel>
        <RightPanel selectedLayer={this.state.selectedLayer}
          panelState={this.state.selectingState}
          onClick={this.handleClick}
          layerList={this.state.layerList}
          innerProfile={this.state.inner}
          packProfile={this.state.pack}
          modelId={this.state.modelId}
          getDatas={this.getDatas}
          info3d={this.state.info3d}
          innerList={this.state.innerList}

        ></RightPanel>
        <ViewBox viewMode={this.state.viewMode}
          renderLoading={this.state.renderLoading}
          isRender={this.state.isRender}
          updateImg={this.updateImg}
          model={userModel}
          modelId={this.state.modelId}
          modelCode={this.state.modelId - 1}
          pack={this.state.pack}
          prototype_uv={this.state.prototype_uv}
          layerList={this.state.layerList}
          selectedLayer={this.state.selectedLayer}
          onChange={this.handleClick}
          transList={this.state.transList}
          inner={this.state.inner}
          innerList={this.state.innerList}
          shape={this.state.shape}
          isVisible={this.state.isVisible}
          active={this.state.active}
          previewImg={this.state.previewImg}
          info3d={this.state.info3d}
          techImages={this.state.techImages}

        />
        {/* <Prompt message={ () => {
            this.showConfirm();
            return false;
          } }/> */}
      </div>
    );
  }
}

// export default Design;
export default withNavigationAndPrompt(Design);
