import React from 'react';
import './selectBar.css'

class SelectedChip extends React.Component {
    render() {
        return (
            this.props.selectedItem === this.props.tag.code ?
                <div className='selected'>
                    <div style={{ color: '#1890FF' }}>{this.props.tag.name}</div>
                </div> :
                <a className='unselected' onClick={() => this.props.handleClick(this.props.tag)}>
                    {this.props.tag.name}
                </a>
        );
    }

}

class SelectBar extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(tag) {
        this.props.changeType(tag.code)
    }

    render() {
        return (
            <div className='chipContainer'>
                {this.props.typeList.map((type, index) =>
                    <SelectedChip key={type.code} tag={type} selectedItem={this.props.selectedItem} handleClick={this.handleClick}></SelectedChip>)
                }
            </div>
        );
    }
}

export default SelectBar;
