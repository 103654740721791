import React, { useState } from 'react';
import {Button, Col, InputNumber, Modal, Row, Slider} from 'antd';
import PubSub from "pubsub-js";
import {getModelSizeData} from "../../api";
const App = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [length, setLength] = useState(props.length);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    let params = {
      modelId: props.modelId,
      width: width,
      height: height,
      length: length
    }
    setConfirmLoading(true);
    let res = await getModelSizeData(params)

    PubSub.publish('changeSize', {res, size:{width, height, length}})
    setIsModalOpen(false);
    setConfirmLoading(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>修改尺寸</Button>
      <Modal title="修改尺寸"
             open={isModalOpen}
             onOk={handleOk}
             onCancel={handleCancel}
             okText='修改'
             cancelText='取消'
             width={400}
             centered
             confirmLoading={confirmLoading}
      >
        <Row style={{width: '100%', marginBottom: 10}} align="middle">
          <Col span={3}>
            <div className="name">长</div>
          </Col>
          <Col span={12}>
            <Slider
              min={0}
              max={1000}
              value={length}
              onChange={(val) => setLength(val)}
            ></Slider>
          </Col>
          <Col span={6}>
            <InputNumber
              controls={false}
              min={0}
              max={1000}
              style={{ margin: '0 5%', width: '90%', padding: '0 2px' }}
              value={length}
              onChange={(val) => setLength(val)}
            />
          </Col>
          <Col span={3}>
            <div className="name">mm</div>
          </Col>
        </Row>
        <Row style={{width: '100%', marginBottom: 10}} align="middle">
          <Col span={3}>
            <div className="name">宽</div>
          </Col>
          <Col span={12}>
            <Slider
              min={0}
              max={1000}
              value={width}
              onChange={(val) => setWidth(val)}
            ></Slider>
          </Col>
          <Col span={6}>
            <InputNumber
              controls={false}
              min={0}
              max={1000}
              style={{ margin: '0 5%', width: '90%', padding: '0 2px' }}
              value={width}
              onChange={(val) => setWidth(val)}
            />
          </Col>
          <Col span={3}>
            <div className="name">mm</div>
          </Col>
        </Row>
        <Row style={{width: '100%'}} align="middle">
          <Col span={3}>
            <div className="name">高</div>
          </Col>
          <Col span={12}>
            <Slider
              min={0}
              max={1000}
              value={height}
              onChange={(val) => setHeight(val)}
            ></Slider>
          </Col>
          <Col span={6}>
            <InputNumber
              controls={false}
              min={0}
              max={1000}
              style={{ margin: '0 5%', width: '90%', padding: '0 2px' }}
              value={height}
              onChange={(val) => setHeight(val)}
            />
          </Col>
          <Col span={3}>
            <div className="name">mm</div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default App;
