import React from 'react';
import styles from './myColorPicker.module.css'
import '../../App.css'
import { CustomPicker } from 'react-color';
import { Button, Radio, InputNumber, Modal, Input } from 'antd';
import Draggable from 'react-draggable';

var { Hue, Saturation } = require('react-color/lib/components/common');
const draggleRef = { current: null };

function HSVtoRGB(h, s, v) {
    let i, f, p1, p2, p3;
    let r = 0, g = 0, b = 0;
    if (s < 0) s = 0;
    if (s > 1) s = 1;
    if (v < 0) v = 0;
    if (v > 1) v = 1;
    h %= 360;
    if (h < 0) h += 360;
    h /= 60;
    i = Math.floor(h);
    f = h - i;
    p1 = v * (1 - s);
    p2 = v * (1 - s * f);
    p3 = v * (1 - s * (1 - f));
    switch(i) {
        case 0: r = v;  g = p3; b = p1; break;
        case 1: r = p2; g = v;  b = p1; break;
        case 2: r = p1; g = v;  b = p3; break;
        case 3: r = p1; g = p2; b = v;  break;
        case 4: r = p3; g = p1; b = v;  break;
        case 5: r = v;  g = p1; b = p2; break;
        default: {}
    }
    return [Math.round(r * 255),Math.round(g * 255),Math.round(b * 255)];
}


// eslint-disable-next-line no-unused-vars
function hslToRgb(h, s, l) {
    var r, g, b;
    console.log(h,s,l)
    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = function hue2rgb(p, q, t) {
            if (t < 0) t += 1; if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

class MyColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            colorMode: 'RGB',
            color: {
                r: 255,
                g: 0,
                b: 0,
                hex: this.props.color,
                c: 0,
                m: 0,
                y: 0,
                k: 0,
                pantone: 0
            },
            hsl: {
                h: 0,
                s: 0,
                l: 0,
            },
            hsv: {
                h: 0,
                s: 0,
                v: 0,
            },
            draggable: false,
            bounds: { left: 0, top: 0, bottom: 0, right: 0 }
        };
        this.onChange_r = this.onChange_r.bind(this)
        this.onChange_g = this.onChange_g.bind(this)
        this.onChange_b = this.onChange_b.bind(this)
        this.onChange_c = this.onChange_c.bind(this)
        this.onChange_m = this.onChange_m.bind(this)
        this.onChange_y = this.onChange_y.bind(this)
        this.onChange_k = this.onChange_k.bind(this)
        this.onChange_pantone = this.onChange_pantone.bind(this)
        this.onChange_hex = this.onChange_hex.bind(this)
        this.handleHueChange = this.handleHueChange.bind(this)
        this.handleSatChange = this.handleSatChange.bind(this)
    }
    componentDidMount() {
        this.UpdateCMYK()
    }
    onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }
        this.setState({
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        })
    };

    handleSatChange(e) {
        //console.log(e)
        let newHSV = { h: e.h, s: e.s, v: e.v }
        this.setState({
            hsv: newHSV
        })
        let newRGB=HSVtoRGB(newHSV.h,newHSV.s,newHSV.v);
        //console.log(newRGB);
        this.onChange_r(newRGB[0]);
        this.onChange_g(newRGB[1]);
        this.onChange_b(newRGB[2]);
    }
    handleHueChange(e) {
        console.log(e)
        let newHSL = { h: e.h, s: e.s, l: e.l}//修改色相
        this.setState({
            hsl: newHSL
        })
    }
    handleClick = () => {
        this.setState({
            displayColorPicker: !this.state.displayColorPicker
        })
    }
    handleDrag = () => {
        this.setState({
            draggable: !this.state.draggable
        })
    }
    onModeChange(e) {
        console.log(e.target.value)
        this.setState({
            colorMode: e.target.value
        })
    }
    confirmColor(e) {
        this.setState({
            displayColorPicker: false
        });
        this.props.confirmColor(e);
    }
    toSex(e) {
        let tmp = e.toString(16)
        if (tmp.length === 1) return '0' + tmp;
        return tmp;
    }
    onChange_r(e) {
        let newColor = this.state.color;
        newColor.r = e;
        if (newColor.r !== null) {
            newColor.hex = '#' + this.toSex(newColor.r) + this.toSex(newColor.g) + this.toSex(newColor.b)
            this.setState({
                color: newColor,
                hexInput: newColor.hex
            })
            this.UpdateCMYK()
        }
    }
    onChange_g(e) {
        let newColor = this.state.color;
        newColor.g = e;
        if (newColor.g !== null) {
            newColor.hex = '#' + this.toSex(newColor.r) + this.toSex(newColor.g) + this.toSex(newColor.b)
            this.setState({
                color: newColor,
                hexInput: newColor.hex
            })
            this.UpdateCMYK()
        }
    }
    onChange_b(e) {
        let newColor = this.state.color;
        newColor.b = e;
        if (newColor.b !== null) {
            newColor.hex = '#' + this.toSex(newColor.r) + this.toSex(newColor.g) + this.toSex(newColor.b)
            this.setState({
                color: newColor,
                hexInput: newColor.hex
            })
            this.UpdateCMYK()
        }
    }
    onChange_c(e) {
        let newColor = this.state.color;
        newColor.c = e;
        newColor.r = Math.round(255 * (1 - e) * (1 - this.state.color.k));
        this.setState({
            color: newColor
        })
    }
    onChange_m(e) {
        let newColor = this.state.color;
        newColor.m = e;
        newColor.g = Math.round(255 * (1 - e) * (1 - this.state.color.k));
        this.setState({
            color: newColor
        })
    }
    onChange_y(e) {
        let newColor = this.state.color;
        newColor.y = e;
        newColor.b = Math.round(255 * (1 - e) * (1 - this.state.color.k));
        this.setState({
            color: newColor
        })
    }
    onChange_k(e) {
        let newColor = this.state.color;
        newColor.k = e;
        newColor.r = Math.round(255 * (1 - this.state.color.c) * (1 - e));
        newColor.g = Math.round(255 * (1 - this.state.color.g) * (1 - e));
        newColor.b = Math.round(255 * (1 - this.state.color.b) * (1 - e));
        this.setState({
            color: newColor
        })
    }
    onChange_pantone(e) {
        let newColor = this.state.color;
        newColor.pantone = e;
        this.setState({
            color: newColor
        })
    }
    onChange_hex(e) {
        this.setState({
            hexInput: e.target.value
        })
        let newHex = e.target.value;
        let newColor = this.state.color;
        if (newHex.toString().substring(0, 1) === '#' && newHex.length === 7) {
            if (Number.isNaN(parseInt(newHex.substring(1, 3), 16)) || Number.isNaN(parseInt(newHex.substring(3, 5), 16)) || Number.isNaN(parseInt(newHex.substring(5, 7), 16))) {
                console.log("error")
                this.setState({
                    hexInput: newColor.hex
                })
                return;
            };
            newColor.hex = newHex;
            newColor.r = parseInt(newHex.substring(1, 3), 16)
            newColor.g = parseInt(newHex.substring(3, 5), 16)
            newColor.b = parseInt(newHex.substring(5, 7), 16)
            this.setState({
                color: newColor
            })
            this.UpdateCMYK()
        }
    }
    UpdateCMYK() {
        var computedC = 0;
        var computedM = 0;
        var computedY = 0;
        var computedK = 0;
        let newColor = this.state.color;

        // BLACK
        if (newColor.r === 0 && newColor.g === 0 && newColor.b === 0) {
            computedK = 1;
            newColor.c = computedC;
            newColor.m = computedM;
            newColor.y = computedY;
            newColor.k = computedK;
            this.setState({
                color: newColor
            })
            return;
        }

        computedC = 1 - (newColor.r / 255);
        computedM = 1 - (newColor.g / 255);
        computedY = 1 - (newColor.b / 255);

        var minCMY = Math.min(computedC,
            Math.min(computedM, computedY));
        computedC = (computedC - minCMY) / (1 - minCMY);
        computedM = (computedM - minCMY) / (1 - minCMY);
        computedY = (computedY - minCMY) / (1 - minCMY);
        computedK = minCMY;

        newColor.c = computedC;
        newColor.m = computedM;
        newColor.y = computedY;
        newColor.k = computedK;
        this.setState({
            color: newColor
        })
    }
    render() {
        return (
            <div className='myColorModal'>
                <div className={styles.swatch} onClick={this.handleClick}>
                    <div className={styles.color} style={{ backgroundColor: this.props.color }} />
                </div>

                <Modal title={
                    <div onFocus={() => { }}
                        onBlur={() => { }}
                        onMouseOver={() => {
                            this.setState({
                                draggable: true
                            })
                        }}
                        onMouseOut={() => {
                            this.setState({
                                draggable: false
                            })
                        }}
                    >
                        颜色详情
                    </div>}
                    open={this.state.displayColorPicker} maskClosable={false} mask={false} footer={null} onCancel={this.handleClick} width={564}
                    modalRender={(modal) => (
                        <Draggable
                            disabled={!this.state.draggable}
                            bounds={this.state.bounds}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                >
                    < div className={styles.colorPanel}>
                        <div className={styles.satBox}>
                            <Saturation
                                hsl={this.state.hsl}
                                hsv={this.state.hsv}
                                onChange={this.handleSatChange}
                            />
                        </div>
                        <div className={styles.HueBox}>
                            <Hue
                                hsl={this.state.hsl}
                                onChange={this.handleHueChange}
                                direction={'vertical'}
                            />
                        </div>
                        <div className={styles.operatorPanel}>
                            <Radio.Group onChange={this.onModeChange.bind(this)} value={this.state.colorMode}>
                                <Radio value={'RGB'}>RGB</Radio>
                                <Radio value={'CMYK'}>CMYK</Radio>
                                <Radio value={'Pantone'}>潘通色号</Radio>
                            </Radio.Group>
                            {this.state.colorMode === 'RGB' &&
                                <div className={styles.inputBox}>
                                    <div className={styles.inputItem}>
                                        <div>R</div>
                                        <InputNumber
                                            min={0} max={255} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.r}
                                            onChange={this.onChange_r}
                                        />
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div>G</div>
                                        <InputNumber
                                            min={0} max={255} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.g}
                                            onChange={this.onChange_g}
                                        />
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div>B</div>
                                        <InputNumber
                                            min={0} max={255} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.b}
                                            onChange={this.onChange_b}
                                        />
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div>HEX</div>
                                        <Input
                                            size='small' style={{ width: 80 }}
                                            defaultValue={this.state.color.hex}
                                            value={this.state.hexInput}
                                            onChange={this.onChange_hex}
                                            maxLength={7}
                                        />
                                    </div>
                                </div>
                            }
                            {this.state.colorMode === 'CMYK' &&
                                <div className={styles.inputBox}>
                                    <div className={styles.inputItem}>
                                        <div>C</div>
                                        <InputNumber
                                            min={0} max={1} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.c}
                                            onChange={this.onChange_c}
                                        />
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div>M</div>
                                        <InputNumber
                                            min={0} max={1} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.m}
                                            onChange={this.onChange_m}
                                        />
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div>Y</div>
                                        <InputNumber
                                            min={0} max={1} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.y}
                                            onChange={this.onChange_y}
                                        />
                                    </div>
                                    <div className={styles.inputItem}>
                                        <div>K</div>
                                        <InputNumber
                                            min={0} max={255} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.k}
                                            onChange={this.onChange_k}
                                        />
                                    </div>
                                </div>
                            }
                            {this.state.colorMode === 'Pantone' &&
                                <div className={styles.inputBox}>
                                    <div className={styles.inputItem}>
                                        <div>色号</div>
                                        <InputNumber
                                            min={0} max={255} size='small' controls={false} style={{ width: 80 }}
                                            value={this.state.color.pantone}
                                            onChange={this.onChange_pantone}
                                            formatter={(value) => `${value}C`}
                                            parser={(value) => value.replace('C', '')}
                                        />
                                    </div>
                                    <div style={{ fontSize: 10, color: 'gray' }}>版权原因，暂不支持输入潘通色号直接获取颜色信息
                                        <a href='http://www.doc88.com/p-2951203304049.html' style={{ fontSize: 10, color: 'rgba(24, 144, 255, 1)' }}>点击此处</a>
                                        查阅色号并转至RGB设置颜色
                                    </div>
                                </div>
                            }
                            <div className={styles.panelFooter}>
                                <div className={styles.colorRefer}>
                                    <div className={styles.colorReferItem}>
                                        <div>当前颜色</div>
                                        <div className={styles.colorBlock} style={{ backgroundColor: this.props.color }}></div>
                                    </div>
                                    <div className={styles.colorReferItem}>
                                        <div>新的颜色</div>
                                        <div className={styles.colorBlock} style={{ backgroundColor: this.state.color.hex }}></div>
                                    </div>
                                </div>
                                <div className={styles.buttonGroup}>
                                    <Button onClick={this.handleClick}>取消</Button>
                                    <Button type='primary' onClick={this.confirmColor.bind(this, this.state.color.hex)}>确定</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >
            </div >
        )
    }
}

export default CustomPicker(MyColorPicker);
