export const cameraList = [{
        label: '超宽(15毫米)',
        value: 1,
        camera: {
            fov: 99,
            lens_data: 15
        }
    },
    {
        label: '宽角度(25毫米)',
        value: 2,
        camera: {
            fov: 70,
            lens_data: 25
        }
    },
    {
        label: '经典(36毫米)',
        value: 3,
        camera: {
            fov: 51.8,
            lens_data: 51.8
        }
    },
    {
        label: '常规镜头(50毫米)',
        value: 4,
        camera: {
            fov: 38.6,
            lens_data: 50
        }
    },
    {
        label: '肖像(80毫米)',
        value: 5,
        camera: {
            fov: 24.7,
            lens_data: 80
        }
    },
    {
        label: '电视(135毫米)',
        value: 6,
        camera: {
            fov: 14.8,
            lens_data: 135
        }
    },
    {
        label: '超远距(300毫米)',
        value: 7,
        camera: {
            fov: 6.7,
            lens_data: 300
        }
    },
]