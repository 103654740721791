import { getHashQuery } from "../../utils/common";
import { handleWechatLogin } from "../../api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";


const WcLogin = () => {
  const navigate = useNavigate();
  /**
   * 存在code
   * @type {string}
   */
  let code = getHashQuery('code')
  if (code) {
    handleWechatLogin({ code }).then(res => {
      if (res?.data.result === 0) {
        sessionStorage.setItem('__config_token', res?.data.token)
        let userinfo = JSON.stringify(res?.data)
        sessionStorage.setItem('__user_info', userinfo)
        // window.location.href = '/#/home'
        navigate('/home')
      } else {
        message.error(res.data.data.errmsg)
        // window.location.href = '/#/home'
        navigate('/home')
      }
    })
  } else {
    // window.location.href = '/#/home'
    navigate('/home')
  }
  return (
    <div></div>
  )
}

export default WcLogin;
