import React from 'react';
import { Button } from 'antd';
import '../../App.css'
import './banner.css'
import banner from '../../assets/images/banner.png'
import PubSub from "pubsub-js";
import { useNavigate } from 'react-router-dom';

const Banner = function (props) {

  // let history = useHistory();
  const navigate = useNavigate();
  let gotoDesign = () => {
    let token = sessionStorage.getItem('__config_token')
    if (!token) {
      PubSub.publish('openLoginModal')
    } else {
      navigate('/design');
    }
  }
  return (
    <div className='bannerContainer'>
      <img src={banner} className="bannerPic" alt=''></img>
      <div className='textContainer'>
        <div className='bannerTitle'>{props.title}</div>
        <div className='description'>{props.description}</div>
        <Button onClick={gotoDesign} className='button' type="primary">{props.buttonName}</Button>
      </div>
    </div>
  );
}

export default Banner;
