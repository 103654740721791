import React from 'react';
import styles from './modelCard.module.css'
import '../../App.css'




class ModelCard extends React.Component {
    constructor(props){
        super(props);
        this.state={}
        this.handleClick=this.handleClick.bind(this);
    }
    componentDidMount(){
        document.body.style.overflow='hidden';
    }
    handleClick(e){
        //console.log(e);
        this.props.onClick(e);
    }
    render() {
        return (
            <div className={styles.modelCardContainer} onClick={this.handleClick.bind(this,this.props.id)}>
                <img src={this.props.src} alt='' className={styles.listPic}></img>
                <div className={this.props.id === this.props.selectedModel ? styles.selectedName : styles.unselectedName}>{this.props.name}</div>
                {
                    this.props.selectedModel === this.props.id ?
                    <div className={styles.tip}>
                        当前模板
                    </div>
                    :
                    <div className={styles.picHover}></div>
                }

            </div>

        );
    }
}

export default ModelCard;
