import http from '../utils/http'
export function getToken(params) {
    return http.get('/api/getToken', { params })
}

/**
 * 保存
 * @param params
 */
export function saveData(params) {
    return http.post('/api/project/save', params)
}

/**
 * 获取详情
 * @param params
 */
export function getProjectData(params) {
    return http.get('/api/project/getProject', { params })
}

/**
 * 获取字体列表
 */
export function getFontList() {
    return http.get('/api/getFontList')
}

/**
 * 获取模版
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getPrototypes(params) {
    return http.get('/api/prototypes', { params })
}

/**
 * 获取模版详情
 * @param params
 */
export function getModelData(params){
  return http.get('/api/getModelInfo', {params})
}

/**
 * 获取素材
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaterials(params) {
    return http.get('/api/materials', { params })
}

/**
 * 获取矢量图
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getSvgs(params) {
    return http.get('/api/svgs', { params })
}

/**
 * 上传图片
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function onUpload(file) {
    let formData = new FormData()
    formData.append("file", file)
    return http.post('/api/upload', formData)
}


/**
 * 发送渲染请求（发送物体位置等参数）
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export function requestRender(params) {
    console.log(params)
    return http.post('/api/renderImage', params) //接口待定
}
/**
 * 获取渲染的图片
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getRendered(params) {
    return http.get('/api/render', { params }) //接口待定
}


/**
 * 素材删除
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeMaterial(params) {
    return http.post('/api/materials/delete', params)
}

/**
 * 案例列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getCases(params) {
    return http.get('/api/cases', { params })
}

/**
 * 我的作品
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMyProjectList(params) {
    return http.get('/api/users/myProject', { params })
}
/**
 * 删除作品
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeProject(params) {
    return http.post('/api/project/delete', params)
}

/**
 * 上传用户图片
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function onPicUpload(file) {
    let formData = new FormData()
    formData.append("file", file)
    return http.post('/api/uploadUserPic', formData)
}
/**
 * 上传SVG
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function onSvgUpload(file) {
    let formData = new FormData()
    formData.append("file", file)
    return http.post('/api/uploadUserSvg', formData)
}

/**
 * 获取我的上传
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function myUpload(params) {
    return http.get('/api/myUpload', { params })
}

/**
 * 上传base64图片
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function onUploadMyProject(img) {
    let formData = new FormData()
    let file = base64ToFile(img, 'projectPic')
    formData.append("file", file)
    return http.post('/api/uploadProjectPic', formData)
}

function base64ToFile(data, fileName) {
    const dataArr = data.split(",");
    const byteString = atob(dataArr[1]);
    const options = {
        type: "image/jpeg",
        endings: "native"
    };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
    }
    let fileOfBlob = new File([u8Arr], fileName + ".jpg", options); //返回文件流
    return fileOfBlob
}
/**
 * 下载url图片
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @param url
 * @param filename
 */
export function downloadImage(url, filename) {
    let link = document.createElement("a");
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // 下载完成移除元素
}

/**
 * 获取渲染状态
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getRenderStatus(id) {
    return http.get('/api/checkRenderStatus?renderId=' + id)
}


/**
 * 材质-字典
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDictMaterial() {
    return http.get('/api/getDictByType?typeCode=7')
}
/**
 * 内衬材质-字典
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDictInnerMaterial() {
    return http.get('/api/getDictByType?typeCode=9')
}

/**
 * 材质-详情
 * 糙度,法向,光泽,合并结果,透射
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaterialDetail(params) {
    return http.get('/api/getMaterialQuality',{
        params
    })
}

/**
 * 印刷后工艺-字典
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDictPostPrinting() {
    return http.get('/api/getDictByType?typeCode=6')
}
/**
 * 效果处理工艺-字典
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDictEffect() {
    return http.get('/api/getDictByType?typeCode=5')
}

/**
 * 材质的印刷后工艺, 效果处理工艺
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaterialTechnology(params) {
    return http.get('/api/getSelectItemByQuality', {
        params: params
    })
}

/**
 * 跨域图片获取 base64
 * @param url
 * @returns {Promise<unknown>}
 */
export function getImage2Blob (url) {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open('get', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (this.status == 200) {
                const blob = URL.createObjectURL(this.response);
                resolve(blob);
            } else {
                reject(false);
            }
        };
        xhr.send();
    });
}


/**
 * 材质的厚度
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaterialWeight(params) {
    return http.get('/api/getPageExtInfo', {
        params: params
    })
}

/**
 * 获取行业类别
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getHomeType = () => http.get('/api/getHomePageList?typeCode=15')
/**
 * 获取材质结构工艺
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getHomeModelType = () => http.get('/api/getHomePageList?typeCode=16')

/**
 * 首页模型
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getHomeModelList = (params) => http.get('/api/getHomePageShow', {params})

/**
 * 模型改变尺寸获取数据
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getModelSizeData = (params) => http.get('/api/getCoverInfo', {params})

/**
 * 微信登录
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const handleWechatLogin = (params) => {
    return http.get('/admin/wechat/V1/getWeiXinInfoByCode', {params})
}

/**
 * 获取验证码
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export function getVerifyCode(params) {
    return http.get('/admin/wechat/V1/getVerifyCode', { params })
}

/**
 * 手机验证码登录
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function loginByPhoneCode(params) {
    return http.get('/admin/wechat/V1/loginOrRegister', { params })
}
