import React from "react";
import {Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row} from "antd";
import wechatSvg from '../../assets/icon/wechat.svg'
import phoneSvg from '../../assets/icon/phone.svg'
import "./loginModal.scss"
import {getVerifyCode, loginByPhoneCode} from "../../api";
import PubSub from "pubsub-js";
import { WechatOutlined } from '@ant-design/icons';

let cssBase64 = ''
const qrcodeStyle = `
.impowerBox{position: relative; width: 160px;height: 160px;}
.impowerBox .qrcode {width: 160px;margin-top: 0;}
.impowerBox .title {display: none;}
.impowerBox .info {width: 100%;height:100%;position: absolute;top: 0;left: 0;}
.impowerBox .info .status{display: flex;align-items: center; justify-content: center;width: 100%;height:100%;box-sizing: border-box;}
.impowerBox .info .status.js_wx_default_tip{display: none;}
.impowerBox .info .status.js_wx_after_scan{background: rgba(0,0,0,0.8)}
.impowerBox .info .status.js_wx_after_cancel{background: rgba(0,0,0,0.8)}
.impowerBox .info .status_txt{display: none;}
.status_icon {display: none}
.impowerBox .status {text-align: center;}
`
cssBase64 = window.btoa(qrcodeStyle)
const formRef=React.createRef()
export class LoginModal extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      opening: false,
      // appId: 'wxc0861d6316584b87', // 人才网
      appId: 'wx527b8b8b594b04b2', //lb
      loginType: 'qrcode', // code扫码，phone手机号
      getCodeTime: 0,
      timer: null,
      timestamp: null
    }
  }

  componentDidMount() {
    PubSub.subscribe("openLoginModal", (functionName, arg) => {
      this.openModal()
    });
  }
  componentWillUnmount() {
    PubSub.unsubscribe("openLoginModal");
  }

  openModal(){
    this.setState({
      opening: true
    }, () => {
      this.getQrcode()
    })
  }
  getQrcode(){
    // let host = 'https://www.zjrc.com'
    // let host = 'https://chinalambin.com'
    let host = window.location.host.indexOf('www') > -1 ? 'https://www.chinalambin.com' : 'https://chinalambin.com'
    let path = '/#/wcLogin'
    let url = host + path
    url = encodeURIComponent(url)
    let params = {
      self_redirect: false,
      id: 'wxcode', // 二维码地址
      appid: this.state.appId,
      scope: 'snsapi_login',
      redirect_uri: url,
      response_type: 'code',
      state: new Date().valueOf(),
      style: 'black',
      href: 'data:text/css;base64,'+cssBase64,
      phone: '',
      code: ''
    }
    new window.WxLogin(params)

    let wxCodeDiv = document.getElementById('wxcode')
    let qrcodeFrame = wxCodeDiv.querySelector('iframe')
    qrcodeFrame.sandbox = 'allow-scripts allow-top-navigation allow-same-origin'
  }
  close(){
    this.setState({
      opening: false
    })
  }
  onFinish = (values) => {
    console.log('Success:', values);
    loginByPhoneCode({
      phone: values.phone,
      code: values.code
    }).then(res=>{
      if(res&&res.data.result === 0){
        sessionStorage.setItem('__config_token', res.data.token)
        sessionStorage.setItem('__user_info', JSON.stringify({username: values.phone}))
        this.close()
        window.location.reload()
      } else {
        message.error('登录失败')
      }
    })
  };
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  getPhoneCode = () => {
    if (this.state.getCodeTime > 0) {
      return
    }
    formRef.current.validateFields(['phone']).then((values) => {
      getVerifyCode({phone: values.phone}).then(res=>{
        if(res.data.result === 0){
          message.success('发送成功')
          let timestamp = new Date().valueOf()
          this.setState({
            getCodeTime: 60,
            timestamp
          }, () => {
            let timer = setInterval(() => {
              if(this.state.getCodeTime > 0){
                this.setState({
                  getCodeTime: this.state.getCodeTime - 1
                })
              }else{
                clearInterval(timer)
              }
            }, 1000)
          })
        } else {
          message.error('发送失败')
        }
      })
    })
  }
  changeLoginType(type){
    this.setState({
      loginType: type
    }, () => {
     if(type === 'qrcode'){
       this.getQrcode()
     }
    })
  }
  render() {
    return (
      <>
        <Button type="primary" onClick={this.openModal.bind(this)}>登录/注册</Button>
        <Modal
          centered={true}
          open={this.state.opening}
          footer={null}
          width={360}
          onCancel={this.close.bind(this)}
        >
          <div className="login-content">
            <div className="title">{this.state.loginType === 'qrcode' ? <span><WechatOutlined style={{color: '#01c975', marginRight: 10}}/>微信扫码登录/注册</span> : '验证码登陆'}</div>
            {
              this.state.loginType === 'qrcode' ?
                <div>
                  <div className="wechat-qrcode-box" id="wxcode"></div>
                  <div className="tip">关注公众号，开启你的设计人生</div>
                </div>
                :
                <div className="form-wrapper">
                  <Form
                    ref={formRef}
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed.bind(this)}
                    autoComplete="off"
                  >
                    <Form.Item
                      label=""
                      name="phone"
                      rules={[{ required: true, message: '请输入手机号!' }, {pattern: /^(?:(?:\+|00)86)?1\d{10}$/, message: '请输入正确的手机号'}]}
                    >
                      <Input addonBefore="+86" size="large" placeholder="请输入手机号"/>
                    </Form.Item>

                    <Row gutter={24}>
                      <Col span={14}>
                        <Form.Item
                          label=""
                          name="code"
                          rules={[{ required: true, message: '请输入验证码' }]}
                        >
                          <Input size="large" placeholder="请输入验证码"/>
                        </Form.Item>
                      </Col>
                      <Col span={10} >
                        <Button size="large" style={{width: '100%'}} type="primary" onClick={this.getPhoneCode.bind(this)}>{this.state.getCodeTime > 0 ? this.state.getCodeTime : '获取验证码'}</Button>
                      </Col>
                    </Row>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      rules={[{ validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('请先阅读并同意协议')), }]}
                    >
                      <Checkbox>我已阅读并同意<a className="a-link" href="/service.html" target="_blank">用户协议</a>和<a className="a-link" href="/yszc.html" target="_blank">隐私政策</a></Checkbox>
                    </Form.Item>

                    <Form.Item
                    >
                      <Button size="large" style={{width: '100%'}} type="primary" htmlType="submit">
                        登录
                      </Button>
                    </Form.Item>
                    <p className="tip">未注册过的手机号将自动为您注册</p>
                  </Form>
                </div>
            }

            <div className="login-type">
              <Divider className="login-divider">其他方式登录</Divider>
              {this.state.loginType === 'phone' ?
                <>
                  <img src={wechatSvg} alt=""/>
                  <br/>
                  <Button onClick={this.changeLoginType.bind(this, 'qrcode')} type="link">扫码登陆</Button>
                </>
                :<>
                  <img src={phoneSvg} alt=""/>
                  <br/>
                  <Button onClick={this.changeLoginType.bind(this, 'phone')} type="link">手机验证码登陆</Button>
                </>
            }
          </div>
        </div>
      </Modal>
  </>
    )
  }
}

