import state from "../../mockLibrary/state";
// import RightArrow from '../assets/icon/R-C.png'
// import LeftArrow from '../assets/icon/L-C.png'
import Left from "../../assets/icon/transform.png";
import Middle from "../../assets/icon/rotate.png";
import Rihgt from "../../assets/icon/scale.png";
import "./button.css";
export default function CameraButtons(props) {
  const changeTrans1 = () => {
    props.onClick(["changeTrans1"]);
  };
  const changeTrans2 = () => {
    props.onClick(["changeTrans2"]);
  };
  const changeTrans3 = () => {
    props.onClick(["changeTrans3"]);
  };
  return (
    <>
      <button
        style={{
          display: "flex",
          zIndex: 11,
          position: "absolute",
          bottom: "29px",
          left: "264px",
          height: "33px",
          width: "37px",
          textAlign: "center",
          backgroundColor: "white",
          borderRadius: "5px",
          fontSize: 18,
          fontWeight: "bold",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={changeTrans1}
      >
        <img className="buttonImg" src={Left}></img>
      </button>
      <button
        style={{
          display: "flex",
          zIndex: 11,
          position: "absolute",
          bottom: "29px",
          left: "316px",
          height: "33px",
          width: "37px",
          textAlign: "center",
          backgroundColor: "white",
          borderRadius: "5px",
          fontSize: 18,
          fontWeight: "bold",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={changeTrans2}
      >
        <img className="buttonImg" src={Middle}></img>
      </button>
      {/* <button
            style={{
                display:"flex",
                zIndex:1,
                position:'absolute',
                bottom:'29px',
                left:'368px',
                height:'33px',
                width:'37px',
                textAlign:'center',
                backgroundColor:'white',
                borderRadius:'5px',
                fontSize:18,
                fontWeight:'bold',
                cursor:'pointer',
                justifyContent:'center',
                alignItems:'center',
            }}
            onClick={changeTrans3}
            >
              <img  className='buttonImg' src={Rihgt} ></img>
            </button> */}
    </>
  );
}
