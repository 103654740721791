import React from 'react';
import './navigateBar.css'
import '../../App.css'
import { Link } from "react-router-dom";
import { Button} from 'antd';
import UserInfo from '../userInfo/userInfo';
import logo from '../../assets/icon/logo.svg'
import logoText from '../../assets/icon/logoText.svg'
import homeIcon from '../../assets/icon/home.png';
import communityIcon from '../../assets/icon/community.png';
import undoIcon from '../../assets/icon/undo.png';
import redoIcon from '../../assets/icon/redo.png';
import backIcon from '../../assets/icon/back.png';
import saveIcon from '../../assets/icon/save.png';
import {LoginModal} from "./LoginModal";
import PubSub from "pubsub-js";
/* unloggedIn/loggedIn/loggedIn_2D/loggedIn_3D */



class NavigateBar extends React.Component {
    constructor(props) {
        super(props);
        let token = sessionStorage.getItem('__config_token')
        this.state = {
            loggedIn: token ? true : false
        }
        this.handleClick=this.handleClick.bind(this)
    }
    handleClick(e){
        if(this.props.onClick){
            this.props.onClick(e);
        } else {
            this.gotoPage(e[1])
        }
    }
    gotoHome = () => {
        window.location = '/'
    }
    gotoPage= (url) => {
        if(this.state.loggedIn){
            window.location = '/#'+url
        } else {
            if(url === '/home'){
                window.location = '/#'+url
            } else {
                PubSub.publish('openLoginModal')
            }
        }
    }
    loginSuccess = () => {
        let token = sessionStorage.getItem('__config_token')
        this.setState({
            loggedIn: !!token
        })
    }
    render() {
        return (
            <div className='headContainer'>
                <div className='TagContainer'>
                    <img alt='' src={logo} className='logo' onClick={this.handleClick.bind(this, ['back', '/home'])}/>
                    <img alt='' src={logoText} className='logo' onClick={this.handleClick.bind(this, ['back', '/home'])}/>
                    <div></div>
                    <div className='header-btn' onClick={this.handleClick.bind(this, ['back', '/myProject'])}>我的项目
                    </div>
                    <div className='header-btn' onClick={this.handleClick.bind(this, ['back', '/community'])}>案例分享
                    </div>
                    {
                      this.props.state === 'loggedIn_2D' && (
                        <>
                            <div className='line'></div>
                            <img alt='' src={saveIcon} className='icon'
                                 onClick={this.handleClick.bind(this, ['save'])}></img>
                            <img alt='' src={undoIcon} className={this.props.undo ? 'icon' : 'iconForbid'}
                                 onClick={this.handleClick.bind(this, ['undo'])}></img>
                            <img alt='' src={redoIcon} className={this.props.redo ? 'icon' : 'iconForbid'}
                                 onClick={this.handleClick.bind(this, ['redo'])}></img>
                            <div className='designTitle'>
                                {this.props.title}
                            </div>
                        </>
                      )
                    }

                </div>

                {this.state.loggedIn ?
                  <UserInfo></UserInfo> :
                  <LoginModal onSuccess={this.loginSuccess.bind(this)}/>
                }
            </div>
        )
    }
}

export default NavigateBar;




