// 基本封装axios
import axios from 'axios'
import {message} from "antd";
let baseUrl = process.env
const BASE_URL = process.env.REACT_APP_BASE_URL
// const BASE_URL = process.env.NODE_ENV === 'development' ? '/api' : 'https://chinalambin.com/design/api/V1'
const TIMEOUT = 60000 * 5 // 2分钟

const http = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT
})

// 请求拦截器 在发起http请求之前的一些操作
// 某些请求需要携带token，如果说没有没有携带，直接跳转到登录页面
http.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('__config_token')
  config.headers['Authorization'] = token
  if(config.url.indexOf('json') > 0){
    config.baseURL = ''
  }
  return config
}, err => {
  return err
})

// 响应拦截器
http.interceptors.response.use((res) => {
  if(res.data.result === 501){
    message.warning('登录已过期')
    sessionStorage.clear()
    window.location.href = '/#/home'
    return Promise.reject(res)
  } else {
    return res
  }
}, err => {
  if (err && err.response) {
    switch(err.response.status) {
      case 400:
        console.log('请求错误')
        break
      case 401:
        console.log('未认证')
        break
      case 403:
        console.log('无权限访问')
        break
      case 404:
        console.log('资源未找到')
        break
      case 500:
        console.log('服务器错误')
        break
      default:
        console.log('其他信息错误')
    }
  }
})

export default http
