import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import PubSub from "pubsub-js";
import {
  Environment,
  OrbitControls,
  TransformControls,
  CameraControls,
} from "@react-three/drei";
// import CameraControls from
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./DisplayContainer.css";
import Model1 from "../../mockLibrary/Model1";
import Model2 from "../../mockLibrary/Model2";
import Model3 from "../../mockLibrary/Model3";
import Model4 from "../../mockLibrary/Model4";
import Model5 from "../../mockLibrary/Model5";
import Model6 from "../../mockLibrary/Model6";
import Model7 from "../../mockLibrary/Model7";
import Model8 from "../../mockLibrary/Model8";
import Model9 from "../../mockLibrary/Model9";
import Model10 from "../../mockLibrary/Model10";
import Model11 from "../../mockLibrary/Model11";
import Model12 from "../../mockLibrary/Model12";
import Model13 from "../../mockLibrary/Model13";
// import Model3 from "../../mockLibrary/Model2";
import HDRI from "../../assets/hdr/royal_esplanade_1k.hdr";
import sceneData from "../../assets/json/sceneData.json";
import CameraButtons from "./CameraButtons";
import Generate from "../../assets/icon/generate.png";
import { Button, Select } from "antd";
// 视距字典
import { cameraList } from "../../utils/dictionary";
import {baseUrl, isDev} from '../../utils/config';
import CameraMask from "./CameraMask";
// import {TransformControls} from 'three/examples/jsm/controls/TransformControls.js'
/* ============ 3D展示 ================= */
let poslist = [0, 0, 0 ];
let rotlist = [0, 0, 0];
let scalist = [1, 1, 1];
let camplist = [0.803162, -0.003795, -1.16228];
// let camrlist = [-0.029737, 0.549, 0.01552];
let camrlist = [1.617, 0.655, 0];
export default function Canvas3D(props) {
  const orbit = useRef();
  const cameraControlsRef = useRef();
  const [isRender, setIsRender] = useState();
  const transform = useRef();
  const Trans = props.transList;
  const fov = props.info3d.camera.fov;
  const [isTransforming, setIsTransforming] = useState(false);
  // const isRender=props.isRender
  // const
  console.log(props.modelId)
  function Orbit() {
    const { camera, gl } = useThree();
    return (
      <OrbitControls
        ref={orbit}
        args={[camera, gl.domElement]}
        enableZoom={false}
        rotateSpeed={0.1}
        enablePan={true}
      />
    );
  }

  function Environment1() {
    useFrame((state) => {
      // console.log(state.camera.fov)
      state.camera.fov = fov;
      // state.camera.lookAt(4, 13, 1.6);
      // state.camera.aspect = window.innerWidth / window.innerHeight
      state.camera.updateProjectionMatrix();
      camplist = [
        state.camera.position.x,
        state.camera.position.y,
        state.camera.position.z,
      ];

      camrlist = [
        state.camera.rotation.x,
        state.camera.rotation.y,
        state.camera.rotation.z,
      ];
      // console.log(state.camera.fov)
    });
    return null;
  }
  const changeCameraFov = (e, option) => {
    props.onClick(["changeCameraFov", option, props.index]);
    console.log(cameraList[e], fov);
  };
  const change3D = (a, b, c, d, e, f, g, h, i) => {
    props.onClick(["change3D", a, b, c, d, e, f, g, h, i]);
  };
  const changeModelInfo = (a, b, c, d, e, f, g, h, i) => {
    props.onClick(["changeModelInfo", a, b, c, d, e, f, g, h, i]);
  };
  const changeCamPos = (a, b, c, d, e, f) => {
    props.onClick(["changeCamPos", a, b, c, d, e, f]);
    setIsRender(false);
  };
  const renderPics = () => {
    props.onClick(["renderPics"]);
  };
  useEffect(() => {
    if (transform.current) {
      transform.current.updateMatrix();
    }
  }, [props]);

  //设置controls优先级
  useEffect(() => {

    if (transform.current) {
      const { current: controls } = transform;
      // const controls = transform.current
      const callback = (event) => {
        poslist = [
          event.target.object.position.x.toFixed(3),
          -event.target.object.position.z.toFixed(3),
          event.target.object.position.y.toFixed(3),
        ];
        rotlist = [
          event.target.object.rotation.x.toFixed(3),
          -event.target.object.rotation.z.toFixed(3),

          event.target.object.rotation.y.toFixed(3)
        ]
        scalist=[

          event.target.object.scale.x.toFixed(3),
          event.target.object.scale.y.toFixed(3),
          event.target.object.scale.z.toFixed(3),
        ];
        // 禁用 OrbitControls 的交互功能
        orbit.current.enabled = !event.value;
        cameraControlsRef.current.enabled = !event.value;
        PubSub.publish("changeTransCtrl", event.target.object.position);
        PubSub.publish("changeTransCtrlr", event.target.object.rotation);
        PubSub.publish("changeTransCtrls", event.target.object.scale);
        console.log(event.target.object.position);
        console.log(event.target.object.rotation);
      };
      controls.addEventListener("dragging-changed", callback);
      return () => controls.removeEventListener("dragging-changed", callback);
    }

    if (orbit.current) {
      const { current: controls } = orbit;
      const callback = (event) => {
        transform.current.enabled = !event.value;
        cameraControlsRef.current.enabled = !event.value;
        console.log(event.target.object.position);
        console.log(event.target.object.rotation);
      };
      controls.addEventListener("end", callback);
      return () => controls.removeEventListener("end", callback);
    }
  });



  useEffect(() => {
    changeModelInfo(
      scalist[0],
      scalist[1],
      scalist[2],
      rotlist[0],
      rotlist[1],
      rotlist[2],
      poslist[0],
      poslist[1],
      poslist[2]
    );
    //yz对调，ry不加负号试一下
    changeCamPos(
      camplist[0],
      camplist[2] * -1,
      camplist[1],
      camrlist[0] + 1.57,
      0,
      camrlist[1]
    );

  }, [poslist, rotlist, scalist, isRender]);

  useEffect(() => {
    if (props.viewMode == "2D") cameraControlsRef.current?.reset(true);
  }, [props.viewMode]);

  const Model = ({ data, index }) => {
    const gltf = useLoader(
      GLTFLoader, `${baseUrl}/glb/${ data.model }`
    );

    return (
      <>
        <primitive
          key={index}
          object={gltf.scene}
          scale={1}
          castShadow
          receiveShadow
        />
      </>
    );
  };

  return (
    <div
      className={
        props.viewMode === "2D" ? "previewCanvas" : "container-block canvas3D"
      }
    >
      {props.viewMode === "3D" && <CameraMask></CameraMask>}
      <CameraButtons onClick={props.onClick} />
      <Button
        onClick={() => {
          renderPics();
          setIsRender(true);
        }}
        loading={props.renderLoading}
        style={{
          display: "flex",
          zIndex: 11,
          position: "absolute",
          bottom: "24px",
          left: "520px",
          height: "38px",
          width: "150px",
          textAlign: "center",
          backgroundColor: "white",
          borderRadius: "4px",
          fontSize: 16,
          fontWeight: 500,
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          background: "#1890FF",
          color: "#FFFFFF",
          borderStyle: "none",
        }}
      >
        <img style={{ display: "flex", left: "13px" }} src={Generate} alt="" />
        生成图片
      </Button>
      <div
        className="select"
        style={{
          display: "flex",
          zIndex: 11,
          position: "absolute",
          bottom: "24px",
          left: "720px",
        }}
      >
        <Select
          value={props.info3d.camera.cameraId}
          style={{ left: "0px", width: "150px" }}
          size="large"
          onChange={changeCameraFov}
          options={cameraList}
        ></Select>
      </div>
      <Canvas
        camera={{
          fov: fov,
          near: 0.001,
          far: 1000,
          position: [0.803162, 0.003795, 1.16228],
          rotation: [1.617, 0.655, 0],
          // aspect: window.innerWidth / window.innerHeight
        }}
      >
        <Suspense fallback={null}>
          <CameraControls ref={cameraControlsRef} />
          {/* 3D状态 */}
          {props.modelId >= 0 &&
            props.viewMode === "3D" &&
            sceneData[props.modelId].map((item, index) => (
              <Model key={index} data={item} index={index} />
            ))}
          {props.viewMode === "3D" && (
            <>
              <TransformControls
                showX={true}
                showY={true}
                showZ={true}
                size={1}
                ref={transform}
                mode={Trans}
                enabled={true}
                dragging={true}
              >
                {props.modelId === 675 && (
                  <Model1
                    childData={props.img_src}
                    onClick={props.onClick}
                    isVisible={props.isVisible}
                    active={props.active}
                    inner={props.inner}
                    innerList={props.innerList}
                    viewMode={props.viewMode}
                    transList={props.transList}
                    shape={props.shape}
                    outerPrint={props.outerPrint}
                    info3d={props.info3d}
                    techImages={props.techImages}
                    modelId={props.modelId}
                    layerList={ props.layerList }
                    selectedLayer={ props.selectedLayer }
                    layerInfo={props.pack}
                  />
                )}
                {props.modelId === 676 && (
                 <Model2
                 childData={props.img_src}
                 onClick={props.onClick}
                 isVisible={props.isVisible}
                 active={props.active}
                 inner={props.inner}
                 innerList={props.innerList}
                 viewMode={props.viewMode}
                 transList={props.transList}
                 shape={props.shape}
                 outerPrint={props.outerPrint}
                 info3d={props.info3d}
                 techImages={props.techImages}
                 layerList={ props.layerList }
                 selectedLayer={ props.selectedLayer }
                 layerInfo={props.pack}
                 />
                )}
                {props.modelId === 677 && (
                 <Model3
                 childData={props.img_src}
                 onClick={props.onClick}
                 isVisible={props.isVisible}
                 active={props.active}
                 inner={props.inner}
                 innerList={props.innerList}
                 viewMode={props.viewMode}
                 transList={props.transList}
                 shape={props.shape}
                 outerPrint={props.outerPrint}
                 info3d={props.info3d}
                 techImages={props.techImages}
                 layerList={ props.layerList }
                 selectedLayer={ props.selectedLayer }
                 layerInfo={props.pack}
                 />
                )}
              {props.modelId === 678 && (
               <Model4
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 679 && (
               <Model5
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 680 && (
               <Model6
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 681 && (
               <Model7
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 682 && (
               <Model8
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 683 && (
               <Model9
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
               
              )}
               {props.modelId === 684 && (
               <Model10
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               /> 
              )}
               {props.modelId === 685 && (
               <Model11
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               /> 
              )}
              {props.modelId === 686 && (
               <Model12
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               /> 
              )}
              {props.modelId === 687 && (
                  <Model13
                    childData={props.img_src}
                    onClick={props.onClick}
                    isVisible={props.isVisible}
                    active={props.active}
                    inner={props.inner}
                    innerList={props.innerList}
                    viewMode={props.viewMode}
                    transList={props.transList}
                    shape={props.shape}
                    outerPrint={props.outerPrint}
                    info3d={props.info3d}
                    techImages={props.techImages}
                    modelId={props.modelId}
                    layerList={ props.layerList }
                    selectedLayer={ props.selectedLayer }
                    layerInfo={props.pack}
                  />
                )}
              </TransformControls>

              <Orbit />

              <ambientLight intensity={0.1} castShadow />
              <Environment
                files={
                  (process.env.REACT_APP_IS_TEST ? '/lb' :'') + "/cyclorama_hard_light_2k.hdr"
                }
                ground={{ height: 5, radius: 40, scale: 2000 }}
              />

              <Environment1 />
            </>
          )}
          {/* 2D状态下无操作轴 */}
          {props.viewMode === "2D" && (
            <>
              {props.modelId === 675 && (
                <Model1
                  childData={props.img_src}
                  onClick={props.onClick}
                  isVisible={props.isVisible}
                  active={props.active}
                  inner={props.inner}
                  innerList={props.innerList}
                  viewMode={props.viewMode}
                  transList={props.transList}
                  shape={props.shape}
                  outerPrint={props.outerPrint}
                  info3d={props.info3d}
                  techImages={props.techImages}
                  layerList={ props.layerList }
                  selectedLayer={ props.selectedLayer }
                  layerInfo={props.pack}
                />
              )}
              {props.modelId === 676 && (
               <Model2
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 677 && (
               <Model3
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 678 && (
               <Model4
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 679 && (
               <Model5
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 680 && (
               <Model6
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 681 && (
               <Model7
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 682 && (
               <Model8
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 683 && (
               <Model9
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 684 && (
               <Model10
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}
              {props.modelId === 685 && (
               <Model11
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )}       
              {props.modelId === 686 && (
               <Model12
               childData={props.img_src}
               onClick={props.onClick}
               isVisible={props.isVisible}
               active={props.active}
               inner={props.inner}
               innerList={props.innerList}
               viewMode={props.viewMode}
               transList={props.transList}
               shape={props.shape}
               outerPrint={props.outerPrint}
               info3d={props.info3d}
               techImages={props.techImages}
               layerList={ props.layerList }
               selectedLayer={ props.selectedLayer }
               layerInfo={props.pack}
               />
              )} 
                {props.modelId === 687 && (
                  <Model13
                    childData={props.img_src}
                    onClick={props.onClick}
                    isVisible={props.isVisible}
                    active={props.active}
                    inner={props.inner}
                    innerList={props.innerList}
                    viewMode={props.viewMode}
                    transList={props.transList}
                    shape={props.shape}
                    outerPrint={props.outerPrint}
                    info3d={props.info3d}
                    techImages={props.techImages}
                    modelId={props.modelId}
                    layerList={ props.layerList }
                    selectedLayer={ props.selectedLayer }
                    layerInfo={props.pack}
                  />
                )}                    
              <OrbitControls enableZoom={false} rotateSpeed={0.1} />

              <ambientLight intensity={0.2} />
              <Environment
                files={
                  (process.env.REACT_APP_IS_TEST ? '/lb' :'') + "/cyclorama_hard_light_2k.hdr"
                }
                ground={{ height: 5, radius: 40, scale: 2000 }}
              />
            </>
          )}
        </Suspense>
      </Canvas>
      {/* <Controls /> */}
    </div>
  );
}
